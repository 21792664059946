import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import vanklogoYellow from "@/assets/Icon/Landing/vanklogoYellow.png";
import vanklogoBlack from "@/assets/Icon/Landing/vanklogoBlack.png";
import WordLenguaje from "@/assets/Icon/WordLenguaje.svg";
import { FlagIcon } from "react-flag-kit";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import "./Header.css";

const HomeIcon = ({ isActive }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
      <path
        d="M15 18H9"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
    </g>
  </svg>
);

const CompanyIcon = ({ isActive }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M11 20H21V10C21 8.89543 20.1046 8 19 8H15M11 16H11.01M17 16H17.01M7 16H7.01M11 12H11.01M17 12H17.01M7 12H7.01M11 8H11.01M7 8H7.01M15 20V6C15 4.89543 14.1046 4 13 4H5C3.89543 4 3 4.89543 3 6V20H15Z"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

const PersonIcon = ({ isActive }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 18C3 15.3945 4.66081 13.1768 6.98156 12.348C7.61232 12.1227 8.29183 12 9 12C9.70817 12 10.3877 12.1227 11.0184 12.348C11.3611 12.4703 11.6893 12.623 12 12.8027C12.3107 12.623 12.6389 12.4703 12.9816 12.348C13.6123 12.1227 14.2918 12 15 12C15.7082 12 16.3877 12.1227 17.0184 12.348C19.3392 13.1768 21 15.3945 21 18V21H15.75V19.5H19.5V18C19.5 15.5147 17.4853 13.5 15 13.5C14.4029 13.5 13.833 13.6163 13.3116 13.8275C14.3568 14.9073 15 16.3785 15 18V21H3V18ZM9 11.25C8.31104 11.25 7.66548 11.0642 7.11068 10.74C5.9977 10.0896 5.25 8.88211 5.25 7.5C5.25 5.42893 6.92893 3.75 9 3.75C10.2267 3.75 11.3158 4.33901 12 5.24963C12.6842 4.33901 13.7733 3.75 15 3.75C17.0711 3.75 18.75 5.42893 18.75 7.5C18.75 8.88211 18.0023 10.0896 16.8893 10.74C16.3345 11.0642 15.689 11.25 15 11.25C14.311 11.25 13.6655 11.0642 13.1107 10.74C12.6776 10.4869 12.2999 10.1495 12 9.75036C11.7001 10.1496 11.3224 10.4869 10.8893 10.74C10.3345 11.0642 9.68896 11.25 9 11.25ZM13.5 18V19.5H4.5V18C4.5 15.5147 6.51472 13.5 9 13.5C11.4853 13.5 13.5 15.5147 13.5 18ZM11.25 7.5C11.25 8.74264 10.2426 9.75 9 9.75C7.75736 9.75 6.75 8.74264 6.75 7.5C6.75 6.25736 7.75736 5.25 9 5.25C10.2426 5.25 11.25 6.25736 11.25 7.5ZM15 5.25C13.7574 5.25 12.75 6.25736 12.75 7.5C12.75 8.74264 13.7574 9.75 15 9.75C16.2426 9.75 17.25 8.74264 17.25 7.5C17.25 6.25736 16.2426 5.25 15 5.25Z"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "fill-[#FFF133]" : "fill-[#FFFFFF]"
        }`}
      ></path>{" "}
    </g>
  </svg>
);

const CardIcon = ({ isActive }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M2 12.6101H19"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
      <path
        d="M19 10.28V17.43C18.97 20.28 18.19 21 15.22 21H5.78003C2.76003 21 2 20.2501 2 17.2701V10.28C2 7.58005 2.63 6.71005 5 6.57005C5.24 6.56005 5.50003 6.55005 5.78003 6.55005H15.22C18.24 6.55005 19 7.30005 19 10.28Z"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
      <path
        d="M22 6.73V13.72C22 16.42 21.37 17.29 19 17.43V10.28C19 7.3 18.24 6.55 15.22 6.55H5.78003C5.50003 6.55 5.24 6.56 5 6.57C5.03 3.72 5.81003 3 8.78003 3H18.22C21.24 3 22 3.75 22 6.73Z"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
      <path
        d="M5.25 17.8101H6.96997"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
      <path
        d="M9.10986 17.8101H12.5499"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

const VersityIcon = ({ isActive }) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M21 10L12 5L3 10L6 11.6667M21 10L18 11.6667M21 10V10C21.6129 10.3064 22 10.9328 22 11.618V16.9998M6 11.6667L12 15L18 11.6667M6 11.6667V17.6667L12 21L18 17.6667L18 11.6667"
        className={`w-6 h-6 transition-colors duration-300 ${
          isActive ? "stroke-[#FFF133]" : "stroke-[#FFFFFF]"
        }`}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{" "}
    </g>
  </svg>
);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [t, i18n] = useTranslation("global");
  const location = useLocation();

  const menuRef = useRef<any>(null);

  const handleMenuToggler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const legalPaths = ["/Legal/Terms", "/Legal/DataTreatment"];
  const isLegalPage = legalPaths.includes(location.pathname);

  const navItems = [
    {
      path: "/",
      title: t("Landing.Header.home"),
      Icon: HomeIcon,
    },
    {
      path: "/Companies",
      title: t("Landing.Header.companies"),
      Icon: CompanyIcon,
    },
    {
      path: "/People",
      title: t("Landing.Header.people"),
      Icon: PersonIcon,
    },
    {
      path: "/VankCards",
      title: t("Landing.Header.vankCards"),
      Icon: CardIcon,
    },
    {
      path: "/VankVersity",
      title: t("Landing.Header.vankVersity"),
      Icon: VersityIcon,
    },
  ];

  useEffect(() => {
    function handleClickOutside(event?: any) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenu(false);
        return;
      }
    }

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.addEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <header
        className={`fixed top-0 left-0 w-full h-[82px] flex justify-center items-center ${
          isLegalPage ? "header-light" : "header-dark"
        } z-50`}
      >
        <nav className="w-full flex 2xl:justify-around xl:justify-around lg:justify-around tablet:justify-between mobile:justify-between 2xl:px-0 xl:px-0 lg:px-0 tablet:px-4 mobile:px-4 items-center py-[19px] bg-transparent">
          <div className="w-[117px] h-[29px] flex justify-start items-center">
            <a href="/" className="w-[84px] h-[29px]">
              <img
                src={isLegalPage ? vanklogoBlack : vanklogoYellow}
                className="w-full h-full object-cover"
              />
            </a>
          </div>

          {/* nav items for large devices */}
          <ul className="w-[515px] 2xl:flex xl:flex lg:flex tablet:hidden mobile:hidden 2xl:gap-[40px] xl:gap-[40px] lg:gap-[30px] tablet:gap-[20px] mobile:gap-[20px] items-center justify-center">
            {navItems.map(({ path, title }, index) => (
              <li
                key={index}
                className="text-base text-[var(--text-body)] relative group"
              >
                <NavLink to={path}>
                  {({ isActive, isPending, isTransitioning }) => (
                    <div className="relative">
                      <span
                        className={`${
                          isActive
                            ? "text-[#FFF133]"
                            : isLegalPage
                            ? "text-[#161616]"
                            : "text-[#FFFFFF]"
                        }
                         text-[15px] opacity-100 font-[500] transition-colors duration-300 leading-[24px]`}
                      >
                        {title}
                      </span>
                      <span
                        className={`absolute left-0 bottom-0 h-[2px] bg-[#FFF133] transition-all duration-300
                      ${isActive ? "w-full" : "w-0 group-hover:w-[70%]"}`}
                      ></span>
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="relative max-w-max flex items-center gap-2">
            <Link
              to="/Auth/SingIn"
              className={`absolute lg:relative tablet:relative mobile:relative 2xl:-left-[14px] xl:-left-[14px] lg:-left-0 tablet:-left-0 mobile:-left-0 w-[134px] h-[44px] 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden justify-center items-center space-x-2 py-3 px-4 border ${
                isLegalPage
                  ? "border-[#161616] text-[#161616]"
                  : "border-[#FFF133] text-[#FFF133]"
              } rounded-[40px] text-[15px] leading-[24px] font-[700]`}
            >
              {t("Landing.Header.signIn")}
            </Link>
            <Link
              to="/Auth/SingUp"
              className={`w-[117px] h-[44px] 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden justify-center items-center space-x-2 py-3 px-4 ${
                isLegalPage
                  ? "bg-[#161616] text-[#FFF133] hover:text-[#FFFFFF]"
                  : "bg-[#FFF133] text-[#161616]"
              } rounded-[40px] text-[15px] leading-[24px] font-[700]`}
            >
              {t("Landing.Header.signUp")}
            </Link>
            <div className="2xl:hidden xl:hidden lg:hidden tablet:flex mobile:hidden w-[35px] h-[35px] ">
              <button onClick={handleMenuToggler}>
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[35px] h-[35px]"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M4 6H20M4 12H14M4 18H9"
                      stroke="#eff0f1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </button>
            </div>
          </div>

          {/* mobile menu */}
          <div className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex w-[35px] h-[35px]">
            <button onClick={handleMenuToggler}>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-[35px] h-[35px]"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M4 6H20M4 12H14M4 18H9"
                    stroke="#eff0f1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            </button>
          </div>
        </nav>
      </header>
      <div
        className={`w-screen relative h-[82px] ${
          isLegalPage ? "bg-[#FFFFFF]" : "bg-[#161616]"
        }`}
      >
        {/* nav for mobile */}
        <div
          className={`fixed w-full h-screen flex justify-end Backdrop items-center z-50 ${
            isMenuOpen ? "2xl:hidden xl:hidden lg:hidden tablet:flex mobile:flex" : "hidden"
          }`}
        >
          <div className="relative w-[300px] h-screen bg-[--bg-dark] py-5 flex flex-col gap-8 rounded-sm">
            <div className="w-full h-[30px] flex justify-between items-center pr-4">
              <div className="w-[117px] h-[29px] flex justify-start items-start">
                <img
                  src={isLegalPage ? vanklogoBlack : vanklogoYellow}
                  className="w-full h-full object-contain"
                />
              </div>
              <button
                className="w-[36px] h-[29px] flex justify-center items-center"
                onClick={handleMenuToggler}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.9393 12L6.9696 15.9697L8.03026 17.0304L12 13.0607L15.9697 17.0304L17.0304 15.9697L13.0607 12L17.0303 8.03039L15.9696 6.96973L12 10.9393L8.03038 6.96973L6.96972 8.03039L10.9393 12Z"
                      fill="#FFFFFF"
                    ></path>{" "}
                  </g>
                </svg>
              </button>
            </div>
            <ul className="w-full px-5 flex flex-col gap-3">
              {navItems.map(({ path, title, Icon }, index) => (
                <li
                  key={index}
                  className="py-2 text-[var(--text-body)] relative group bg-"
                >
                  <NavLink to={path} onClick={() => setIsMenuOpen(false)}>
                    {({ isActive, isPending, isTransitioning }) => (
                      <div className="flex gap-2 max-w-max">
                        <div className="w-[24px] h-[24px]">
                          <Icon isActive={isActive} />
                        </div>
                        <span
                          className={`relative max-w-max ${
                            isActive
                              ? "text-[#FFF133]"
                              : isLegalPage
                              ? "text-[#161616]"
                              : "text-[#FFFFFF]"
                          }
                          text-[20px] opacity-100 font-[500] transition-colors duration-300 leading-[24px]`}
                        >
                          {title}
                          <span
                            className={`absolute left-0 bottom-0 h-[2px] bg-[#FFF133] transition-all duration-300
                       ${isActive ? "w-full" : "w-0 group-hover:w-[70%]"}`}
                          ></span>
                        </span>
                      </div>
                    )}
                  </NavLink>
                </li>
              ))}
              <li className="w-[155px] h-[35px] flex justify-center items-center rounded-[40px] bg-[#FFF133] text-[#161616] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex">
                <Link to="/Auth/SingUp">{t("Landing.Header.signUp")}</Link>
              </li>
              <li className="w-[155px] h-[35px] flex justify-center items-center rounded-[40px] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex border border-[#FFF133] text-[#FFF133]">
                <Link to="/Auth/SingIn">{t("apps.Landing.Header.Header.Login")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
