import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { ProfileUser } from "@/apps/Vank/Page/Home/state/index";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { default as toastHook } from "@/hooks/useToast";
import { servicesSetting } from "@/apps/Vank/Page/settings/services/servicesSetting";
import { IconError } from "@/apps/Shared/ToastContainer/Icons";

// Define la estructura de los estados y funciones que compartirás
interface UpdatePhotoContextProps {
  imgSrc: any;
  setImgSrc: (value: any) => void;
  isImgPreview: any;
  setIsImgPreview: (value: boolean) => void;
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteImg: () => Promise<void>;
  isLoadingImg: any;
}

// Crea el contexto
const UpdatePhotoContext = createContext<UpdatePhotoContextProps | undefined>(
  undefined
);

// Proveedor del contexto
export const UpdatePhotoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [imgSrc, setImgSrc] = useState({
    name: "",
    format: "",
    size: "",
    src: "",
  });

  const [isImgPreview, setIsImgPreview] = useState(false);
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const toast = toastHook();
  const { t } = useTranslation("global");
  const services = new servicesSetting();
  const allowedFormats = ["jpeg", "png"]; // Lista de formatos permitidos
  const [isImgOption, setIsImgOption] = useState(false); // Estado para el modal de cambio de idioma
    const [isLoadingImg, setIsLoadingImg] = useState(false);
  

  // Cargar la imagen seleccionada y verificar tamaño
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileFormat = file.type.split("/")[1];

      setIsImgPreview(true);

      if (!allowedFormats.includes(fileFormat)) {
        setError(t("Vank.Setting.Profile.text1"));
        setImgSrc({ name: "", format: "", size: "", src: "" });
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setError(
          `${t("Vank.Setting.Profile.text2")} 5 ${t("Vank.Setting.Profile.mb")}.`
        );
        setImgSrc({ name: "", format: "", size: "", src: "" });
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const result = reader.result;

        if (typeof result === "string") {
          setImgSrc({
            name: file.name,
            format: file.type,
            size: file.size.toString(),
            src: result,
          });
          setError("");
        }
      });
      reader.readAsDataURL(file);
    }
  };

  // Manejar eliminación de la imagen
  const handleDeleteImg = async () => {
    setIsImgOption(false);
    setIsDeleteImg(true);
    setIsLoadingImg(true); 
    try {
      const res = await services.deleteAvatarProfile();
      setIsImgOption(false);

      const hasUser = {
        ...users,
        AVATAR: res?.Url,
      };

      setUsers(hasUser);
      await new IndexedDBLocalDropdownRepository().save({
        key: "user",
        data: hasUser,
      });
    } catch (error) {
      if (error?.data?.statusCode === 404) {
        toast.error(t("Vank.Setting.Profile.text20"), {
          position: "top-right", // Obligatorio
          autoClose: 5000, // Opcional
          iconError: <IconError />, // Opcional
          hideProgressBar: true, // Opcional
          isHovered: true,
        });
        return;
      }
      toast.error(t("Vank.Share.text15"), {
        position: "top-right", // Obligatorio
        autoClose: 5000, // Opcional
        iconError: <IconError />, // Opcional
        hideProgressBar: true, // Opcional
        isHovered: true,
      });
    } finally {
      setIsDeleteImg(false);
      setIsLoadingImg(false); 
    }
  };

  return (
    <UpdatePhotoContext.Provider
      value={{
        imgSrc,
        setImgSrc,
        isImgPreview,
        setIsImgPreview,
        onSelectFile,
        handleDeleteImg,
        isLoadingImg,
      }}
    >
      {children}
    </UpdatePhotoContext.Provider>
  );
};

// Hook para consumir el contexto
export const useUpdatePhoto = (): UpdatePhotoContextProps => {
  const context = useContext(UpdatePhotoContext);
  if (!context) {
    throw new Error("useUpdatePhoto debe usarse dentro de un UpdatePhotoProvider");
  }
  return context;
};
