import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { formatBalance } from "../../../Home/Home";
import { SelectTransaction } from "../state";
import { counterSelector, ProfileUser } from "../../../Home/state";
import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import {
  IconArrowLeft,
  IconClose,
  IconNoMovement,
  IconVankPayTransactions,
} from "@/assets/IconV2/IconsSvg";

// import { serviceOrchestrator } from "@/apps/services/ServicesOrchestrator";
import { ServiceOrchestrator } from "../../../Transactions/VankPay/service/ServicesOrchestrator";
import { ServicesHome } from "../../../Home/Services/ServicesHome";
import { formatAmountVankpay, formtDate } from "../../../utils/Utilfunctions";
import ModalApprovedShipping from "../../../Transactions/Fiat/components/ModalApprovedShipping/ModalApprovedShipping";
import ModalTableMovementsMobil from "../modals/ModalTableMovementsMobil";
import Convert from "../manners/Convert/Convert";
import ModalInfoTransaction from "../../ModalInfoTransaction/ModalInfoTransaction";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import { AnimatePresence, motion } from "framer-motion";
import ModalFeeTransaction from "../../../Transactions/Fiat/components/ModalFeeTransaction/ModalFeeTransaction";
import { formatItemAmount } from "../../../Transactions/Fiat/Tables/modals/ModalTableMovementsMobilFiat";

// Función para capitalizar nombres
const capitalizeName = (name) => {
  if (!name) return name; // Si es un correo, lo devolvemos tal cual
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const Income = ({
  item,
  handleSelect,
}: {
  item?: any;
  handleSelect?: any;
}) => {
  const [t] = useTranslation("global");
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [visibleModalInfo, setVisibleModalInfo] = useState<boolean>(false);

  return (
    <div
      onClick={handleSelect}
      className="group cursor-pointer border-t-[1px] dark:border-t-[#212222] relative w-full min-h-[56px] mobile:px-1 mobile-360:px-3 flex justify-between items-center gap-2 overflow-hidden"
    >
      <span>
        <CustomCircle
          moreStyle={`${
            item.ACTION == "OUTPUT"
              ? "bg-[var(--error-bg-light)]"
              : "bg-[var(--success-backgound-light)]"
          } mobile-375:w-9 mobile:w-8 mobile-375:h-9 mobile:h-8 rotate-180 `}
          icon={
            <IconArrowLeft
              className={` ${
                item.ACTION == "OUTPUT" ? "rotate-[136deg]" : "-rotate-[50deg]"
              } w-[15px] h-[15px]`}
              stroke="var(--content-main-black-light)"
            />
          }
        />
      </span>
      <div className="w-full cursor-pointer">
        <div className="flex items-center justify-between">
          <div className="flex flex-col mobile-375:w-full mobile:w-[9rem] ">
            <span
              className={` mobile-375:text-[12px] mobile:text-[10px] ${
                item.STATUSORDER === 3 ||
                item.STATUSORDER === 4 ||
                item.STATUSORDER === 5
                  ? "text-[#C0C0C1]"
                  : "text-[#2D2E2F] dark:text-[#FFFFFF]"
              }`}
            >
              {item.USUARIOVANK === "EXTERNO"
                ? t("Home.LatestTransactions.LatestTransactions.ExternalUser")
                : item.ACTION == "OUTPUT"
                ? capitalizeName(
                    `${item?.COMPLETENAMERECEPTOR?.split(" ")[0]} ${
                      item?.SURNAMERECEPTOR?.split(" ")[0] || ""
                    }`
                  )
                : capitalizeName(
                    `${item?.NAMEREMISOR?.split(" ")[0]} ${
                      item?.SURNAMEEMISOR?.split(" ")[0] || ""
                    }`
                  )}
            </span>

            <div className="flex justify-start items-center gap-[4px]">
              <span
                className={`min-w-[69px] mobile-375:text-[10px] mobile:text-[10px] break-words ${
                  item.STATUSORDER === 3 ||
                  item.STATUSORDER === 4 ||
                  item.STATUSORDER === 5
                    ? " text-[#818282]"
                    : "text-[#2D2E2F] dark:text-[#FFFFFF]"
                } leading-[12.1px]`}
              >
                {item.ACTION === "OUTPUT"
                  ? t("Vank.Share.LatestTransactions.SendVankpayComplete")
                  : t("Vank.Share.LatestTransactions.VankpayIncomeComplete")}
              </span>

              <span
                className={`min-w-[38px] h-[12px] flex justify-center items-center font-semibold rounded-[3px] px-[6px] py-1 text-[8px] ${
                  item.STATUSORDER == 1
                  ? "bg-[#E1FDE4] text-[#1F5928]"
                  : item.STATUSORDER == 2
                  ? "bg-[#FFF3DB] text-[#824215]"
                  : item.STATUSORDER == 3
                  ? "bg-[#EAEAEA] text-[#2D2E2F]"
                  : "bg-[#FDE1E1] text-[#731C1C]"
                } 
                              
                              `}
              >
                {item.STATUSORDER == 1 &&
                  t("Vank.Share.LatestTransactions.Header.Done")}
                {item.STATUSORDER == 2 &&
                  t("Vank.Share.LatestTransactions.Header.Pending")}
                {item.STATUSORDER == 3 &&
                  t("Vank.Share.LatestTransactions.Header.canceled")}
                {(item.STATUSORDER === 4 || item.STATUSORDER === 5) &&
                  t("Vank.Share.LatestTransactions.Header.Failed")}
              </span>
            </div>
          </div>
          <div
            className={`text-end w-full text-[12px] ${
              item.STATUSORDER === 3 ||
              item.STATUSORDER === 4 ||
              item.STATUSORDER === 5
                ? "line-through text-[--content-disabled-input]"
                : "text-[#2D2E2F] dark:text-[#FFFFFF]"
            } font-semibold`}
          >
            {formatAmountVankpay(
              item.AMOUNT.toString(),
              item.ACTION,
              item.ASSET,
              'VankPayMobil'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Expense = ({ item, handleSelect }) => {
  const servicesHome = new ServicesHome();
  const [isAction, setIsAction] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  return (
    <div
      onClick={handleSelect}
      className="group cursor-pointer border-t-[1px] dark:border-t-[#212222] relative w-full min-h-[56px] mobile:px-1 mobile-360:px-3 flex justify-between items-center gap-2 overflow-hidden"
    >
      <span>
        <CustomCircle
          moreStyle={`${
            item.ACTION == "OUTPUT"
              ? "bg-[var(--error-bg-light)]"
              : "bg-[var(--success-backgound-light)]"
          } mobile-375:w-9 mobile:w-8 mobile-375:h-9 mobile:h-8 rotate-180 `}
          icon={
            <IconArrowLeft
              className={` ${
                item.ACTION == "OUTPUT" ? "rotate-[136deg]" : "-rotate-[50deg]"
              } w-[15px] h-[15px]`}
              stroke="var(--content-main-black-light)"
            />
          }
        />
      </span>
      <div className="w-full cursor-pointer">
        <div className="flex items-center justify-between">
          <div className="flex flex-col mobile-375:w-full mobile:w-[9rem] gap-[6px]">
            <span
              className={` mobile-375:text-[12px] mobile:text-[10px] ${
                item.STATUSORDER === 3 ||
                item.STATUSORDER === 4 ||
                item.STATUSORDER === 5
                  ? "text-[#C0C0C1]"
                  : "text-[#2D2E2F] dark:text-[#FFFFFF]"
              } leading-[14.52px]`}
            >
              {item.USUARIOVANK === "EXTERNO"
                ? t("Home.LatestTransactions.LatestTransactions.ExternalUser")
                : item.ACTION == "OUTPUT"
                ? capitalizeName(
                    `${item?.COMPLETENAMERECEPTOR?.split(" ")[0]} ${
                      item?.SURNAMERECEPTOR?.split(" ")[0] || ""
                    }`
                  )
                : capitalizeName(
                    `${item?.NAMEREMISOR?.split(" ")[0]} ${
                      item?.SURNAMEEMISOR?.split(" ")[0] || ""
                    }`
                  )}
            </span>

            <div className="flex justify-start items-center gap-[4px] leading-[12.1px]">
              <span
                className={`min-w-[69px] mobile-375:text-[10px] mobile:text-[10px] break-words ${
                  item.STATUSORDER === 3 ||
                  item.STATUSORDER === 4 ||
                  item.STATUSORDER === 5
                    ? " text-[#818282] dark:text-[#C0C0C1]"
                    : "text-[#2D2E2F] dark:text-[#FFFFFF]"
                }`}
              >
                {item.ACTION === "OUTPUT"
                  ? t("Vank.Share.LatestTransactions.SendVankpayComplete")
                  : t("Vank.Share.LatestTransactions.VankpayIncomeComplete")}
              </span>

              <span
                className={`min-w-[38px] h-[12px] flex justify-center items-center font-semibold rounded-[3px] px-[6px] py-1 text-[8px] ${
                  item.STATUSORDER == 1
                    ? "bg-[#E1FDE4] text-[#1F5928]"
                    : item.STATUSORDER == 2
                    ? "bg-[#FFF3DB] text-[#824215]"
                    : item.STATUSORDER == 3
                    ? "bg-[#EAEAEA] text-[#2D2E2F] "
                    : "bg-[#FDE1E1] text-[#731C1C] "
                } 
                              
                              `}
              >
                {item.STATUSORDER == 1 &&
                  t("Vank.Share.LatestTransactions.Header.Done")}
                {item.STATUSORDER == 2 &&
                  t("Vank.Share.LatestTransactions.Header.Pending")}
                {item.STATUSORDER == 3 &&
                  t("Vank.Share.LatestTransactions.Header.canceled")}
                {(item.STATUSORDER === 4 || item.STATUSORDER === 5) &&
                  t("Vank.Share.LatestTransactions.Header.Failed")}
              </span>
            </div>
          </div>
          <div
            className={`text-end w-full text-[12px] ${
              item.STATUSORDER === 3 ||
              item.STATUSORDER === 4 ||
              item.STATUSORDER === 5
                ? "line-through text-[--content-disabled-input]"
                : "text-[#2D2E2F] dark:text-[#FFFFFF]"
            } font-semibold`}
          >
            {formatAmountVankpay(
              item.AMOUNT.toString(),
              item.ACTION,
              item.ASSET,
              'VankPayMobil'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionsFiat = ({
  item,
  key,
  handleSelect,
}: {
  item?: any;
  key: number;
  handleSelect: any;
}) => {
  const [t, i18n] = useTranslation("global");
  const [modalInfo, setModalInfo] = useState(false);
  const [infoItem, setInfoItem] = useState<null>(null);

  // no sirve
  const handleItemClick = (item) => {
    setInfoItem(item);
    setModalInfo(true);
  };

  return (
    <>
      <div
        className="w-full h-[56px] 2xl:px-[16px] xl:px-[16px] lg:px-[16px] tablet:px-[16px] mobile-360:px-[12px] mobile:px-[4px] py-[10px] flex justify-between items-center group hover:scale-[0.99] border-t-[1px] border-t-[#F1F1F1] dark:border-t-[#212222] transition-all duration-300"
        onClick={() => handleSelect(item, "money")}
      >
        <div className="flex items-center gap-[8px]">
          <CustomCircle
            moreStyle={`${
              item.NAME.split(" ")[0] === "Fee"
                ? "bg-[var(--error-bg-light)]"
                : "bg-[var(--error-bg-light)]"
            } min-w-9 h-9 rotate-180`}
            icon={
              item.NAME.split(" ")[0] === "Fee" ? (
                <span
                  style={{
                    color: "var(--content-inner-inner-black-light)",
                  }}
                >
                  {" "}
                  %{" "}
                </span>
              ) : (
                <IconArrowLeft
                  className={` ${
                    item.ACTION === "OUTPUT"
                      ? "-rotate-[50deg]"
                      : "rotate-[136deg]"
                  } w-[15px] h-[15px]`}
                  stroke="var(--content-inner-inner-black-light)"
                />
              )
            }
          />
          <div className="relative w-full h-[32px] flex flex-col justify-start items-start gap-[6px]">
            <span
              className={`text-[12px] text-ellipsis overflow-visible whitespace-nowrap
                                      ${
                                        item.STATUSORDER === 3 ||
                                        item.STATUSORDER === 4
                                          ? "text-[var(--content-disabled-input)]"
                                          : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                                      } leading-none`}
            >
              {item.NAME.split(" ")[0] === "Fee"
                ? t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                  ) + ` ${item.FEE * 100}%`
                : capitalizeName(
                    ` ${item.NAME.split(" ")[0]} ${
                      item.SURNAME ? item.SURNAME.split(" ")[0] : ""
                    }`
                  )}
            </span>
            <div className="flex items-center gap-[4px]">
              <span
                className={`whitespace-nowrap text-[10px] w-full flex items-center ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                } leading-none`}
              >
                {t(
                  "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiatComplete"
                )}
              </span>
              <span
                className={`max-w-max h-[12px] flex justify-center items-center font-semibold rounded-[3px] px-[6px] text-[8px] leading-[9.68px] ${
                  item.STATUSORDER == 1
                    ? "bg-[#E9FFD9] text-[#1F5928]"
                    : item.STATUSORDER == 2
                    ? "bg-[#FFF3DB] text-[#824215]"
                    : item.STATUSORDER == 3
                    ? "bg-[#FDE1E1] text-[#731C1C]"
                    : "bg-[#FDE1E1] text-[#731C1C]"
                }`}
              >
                {item.STATUSORDER == 1 &&
                  t("Vank.Share.LatestTransactions.Header.Done")}
                {item.STATUSORDER == 2 &&
                  t("Vank.Share.LatestTransactions.Header.Pending")}
                {item.STATUSORDER == 3 &&
                  t("Vank.Share.LatestTransactions.Header.Failed")}
                {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
                  t("Vank.Share.LatestTransactions.Header.Failed")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[8px]">
          <div
            className={`xl:flex 2xl:flex-shrink-0 xl:flex-shrink-0 text-[12px]  font-semibold whitespace-nowrap ${
              item.STATUSORDER === 3 || item.STATUSORDER === 4
                ? "line-through text-[var(--content-disabled-input)]"
                : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
            } leading-[14.52px]`}
          >
            {formatItemAmount(
              item.AMOUNT,
              item.DISACOUNT,
              item.ASSETNAME,
              item.NAME
            )}
          </div>
        </div>
      </div>
      {item.STATUSORDER === 1 && ( <div className="w-full h-px bg-[#F1F1F1] dark:bg-[#212222]" />)}
      <div
        className={`${
          item.STATUSORDER != 1
          ? "hidden"
          : "w-full h-[56px] 2xl:px-[16px] xl:px-[16px] lg:px-[16px] tablet:px-[16px] mobile-360:px-[12px] mobile:px-[4px] py-[10px] flex justify-between items-center group hover:scale-[0.99] transition-all duration-300"
        }`}
        onClick={() => handleSelect(item, "moneyFee")}
      >
        <div className="flex items-center gap-[8px]">
          <span>
            <svg
              className=" stroke-[#2D2E2F] dark:stroke-[#fff]"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <rect
                x="1"
                y="1"
                width="34"
                height="34"
                rx="17"
                stroke="#FDE1E1"
                strokeWidth="2"
              />
              <path
                d="M22.3197 13.6797L13.6797 22.3197M20.8797 22.3197C20.0844 22.3197 19.4397 21.675 19.4397 20.8797C19.4397 20.0844 20.0844 19.4397 20.8797 19.4397C21.675 19.4397 22.3197 20.0844 22.3197 20.8797C22.3197 21.675 21.675 22.3197 20.8797 22.3197ZM15.1197 16.5597C14.3244 16.5597 13.6797 15.915 13.6797 15.1197C13.6797 14.3244 14.3244 13.6797 15.1197 13.6797C15.915 13.6797 16.5597 14.3244 16.5597 15.1197C16.5597 15.915 15.915 16.5597 15.1197 16.5597Z"
                stroke=""
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={`text-sm text-ellipsis w-full  overflow-visible whitespace-nowrap ${
              item.STATUSORDER === 3 || item.STATUSORDER === 4
                ? "text-[var(--content-disabled-input)]"
                : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] "
            } leading-none`}
          >
            {t("Vank.Transaction.Money.Tables.TableTransactionFiat.text4")}
          </span>
        </div>
        <p
          className={`flex gap-2 text-sm  item-table-amount whitespace-nowrap font-semibold ${
            item.STATUSORDER === 3 || item.STATUSORDER === 4
              ? "line-through text-[var(--content-disabled-input)]"
              : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
          }`}
        >
          {formatBalance(item.DISACOUNT)} {item.ASSETNAME}{" "}
          <span className="text-[#818282] dark:text-[#C0C0C1]"> ({item.FEE}%)</span>
        </p>
      </div>
    </>
  );
};

const Conversion = ({
  item,
  handleSelect,
}: {
  item: any;
  handleSelect: any;
}) => {
  const [isAction, setIsAction] = useState(false);
  const [t] = useTranslation("global");
  // const [select, setSelect] = useRecoilState<any>(SelectTransaction)

  return (
    <div
      className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden"
      onClick={() => handleSelect(item)}
    >
      <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-[36px] min-h-[36px] justify-center items-center flex bg-[#FFE6C0] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 14.9143L10 18V6"
              stroke="#2D2E2F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 9.08571L14 6V18"
              stroke="#2D2E2F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[--color-darkest-grey] text-base font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Conversion")}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[--color-darkest-grey] text-xs font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Ordermade")}
            </div>
            {/* <div className="text-[#818282] text-xs font-normal ">
              {formtDate(item?.GENERATEDDATE)}
            </div> */}
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
        <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[--color-lightest-white] text-xs font-bold">
            Pen{t("Home.LatestTransactions.LatestTransactions.earring")}diente
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="#2D2E2F"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
        } `}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{formatBalance(1000)} {item?.ASSET}</span> */}
        <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
        } `}
      >
        <span className="text-[--color-darkest-grey] text-base font-semibold">
          {formatBalance(item?.AMOUNT)} {item?.ASSET}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Cancel")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[#FFF] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[--color-darkest-grey]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200" />
      </div>
    </div>
  );
};

const TransactionBatch = ({ item }) => {
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");

  return (
    <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
      <div className="w-[175px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-full min-[1440px]:min-w-[36px] min-h-[36px] justify-center items-center flex bg-[#FDE1E1] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M13.0367 1.34631L3.05675 11.3463M13.0367 1.34631H9.05675M13.0367 1.34631V5.34631M13.0567 10.218L11.5589 8.71226M13.0567 10.218L12.0592 12.2115M13.0567 10.218C13.0567 10.218 10.5984 10.6021 9.05674 11.218C7.1909 11.9635 5.55696 13.6537 5.55696 13.6537M5.24619 1.55638L6.5632 3.22259M5.24619 1.55638L3.14821 2.30983M5.24619 1.55638C5.24619 1.55638 4.57262 3.95159 3.77771 5.40911C2.81567 7.17308 0.943207 8.59447 0.943207 8.59447"
              stroke="#2D2E2F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-base font-normal ">
              {item?.name}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[#818282] dark:text-[#C0C0C1] text-xs font-normal ">
              {item?.date}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.status !== 0 && "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <span className="text-[--color-darkest-grey] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[--color-lightest-white] text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.earring")}
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="#2D2E2F"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-center items-center gap-4 ${
          item?.status === 0 && "hidden"
        } `}
      >
        <span className="text-[--color-darkest-grey] dark:text-[#C0C0C1] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[#FFF] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[--color-darkest-grey]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[#818282] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[#818282] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200 dark:bg-[#2D2E2F]" />
      </div>
    </div>
  );
};

const LatestTransactions = ({
  listTransactions,
  isLoadingHistory,
  className,
  Onclose,
}: {
  listTransactions: any;
  isLoadingHistory?: any;
  className?: string;
  Onclose: any;
}) => {
  const orchestratorService = new ServiceOrchestrator();
  const [t, i18n] = useTranslation("global");
  const [isConversion, setIsConversion] = useState(false);
  const [isOpenModalMobile, setIsOpenModalMobile] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const [fill, setFill] = useState<string>("#818282");

  // const servicesOrchestractor = new ServiceOrchestrator();

  // summary modal states
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);
  const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
  const [isMoneyModalOpen, setIsMoneyModalOpen] = useState(false);
  const [isMoneyFeeModalOpen, setIsMoneyFeeModalOpen] = useState(false);

  const [expenseInfo, setExpenseInfo] = useState(null); // Estado para información de gastos
  const [incomeInfo, setIncomeInfo] = useState(null); // Estado para información de gastos
  const [moneyInfo, setMoneyInfo] = useState(null); // Estado para información de dinero
  const [moneyFeeInfo, setMoneyFeeInfo] = useState(null); // Estado para información de dinero
  // ------------------

  const OnboardingData = [
    {
      id: 1,
      change: "+1.02%",
      rate: "$1.12",
      name: "1200 USDT ⭢ 1380 USDT",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 2,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 2,
    },
    {
      id: 3,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    //  -------------
    {
      id: 4,
      change: "-0.56%",
      rate: "$1.12",
      name: "Katlin Rogers",
      price: "+ $1,420.72 USDT ",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 5,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción Crypto",
      price: "- $1,420.72 USD",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 6,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción Crypto",
      price: "- $234,000 COP",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
  ];

  // const _className = className ? className : 'relative w-full min-[1440px]:min-w-[530px] h-[398px] bg-white rounded-2xl flex-col justify-start items-start inline-flex'
  const _className = `${
    className ? className : ""
  } 2xl:relative   2xl:w-[580px] xl:2xl:w-[580px] dark:bg-[--background-component-module-dark] lg:2xl:w-[580px] md:2xl:w-[580px] mobile:w-full xl:relative lg:relative md:relative sm:absolute mobile:absolute  bottom-0 bg-white flex-col justify-start items-start inline-flex pb-1 rounded-t-[16px]  ${
    counter === 6 && "z-50"
  }`;

  const handleSelect = (item, type) => {
    // Eliminar valores anteriores
    setExpenseInfo(null);
    setMoneyInfo(null);

    // Mostrar la modal correspondiente según el tipo de transacción
    switch (type) {
      case "expense":
        setExpenseInfo(item); // Asigna el valor de item para "expense"
        setIsExpenseModalOpen(true);
        break;
      case "income":
        setIncomeInfo(item); // Asigna el valor de item para "expense"
        setIsIncomeModalOpen(true);
        break;
      case "money":
        setMoneyInfo(item); // Asigna el valor de item para "money"
        // setMoneyInfo({ ...item, STATUSORDER: 4 });
        setIsMoneyModalOpen(true);
        break;
      case "moneyFee":
        setMoneyFeeInfo(item); // Asigna el valor de item para "money"
        // setMoneyInfo({ ...item, STATUSORDER: 4 });
        setIsMoneyFeeModalOpen(true);
        break;
      // Agrega más casos para otros tipos de transacciones si es necesario
      default:
        // console.log("Tipo de transacción no reconocido");
        break;
    }
  };

  useEffect(() => {}, [listTransactions?.lengt > 0]);

  // extra functions of each item
  const handItemTransExpense = async (ite: any) => {
    try {
      const data = await orchestratorService.updateOrderVankPayUsers(ite);
      let response = data;

      if (response.status == 200) {
      }
    } catch (error) {
      // console.log(`${error} error`);
    }
  };
  // ------------------

  const newF = () => {};

  return (
    <>
      {/* {!expenseInfo && ( */}
      <motion.div
        className={_className}
        style={{
          pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
        }}
        initial={
          !isExpenseModalOpen || !isIncomeModalOpen || !isMoneyModalOpen || !isMoneyFeeModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Solo en móvil comienza fuera de la pantalla
        animate={
          isExpenseModalOpen || isIncomeModalOpen || isMoneyModalOpen || isMoneyFeeModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Siempre termina en su posición original
        exit={
          !isExpenseModalOpen || !isIncomeModalOpen || !isMoneyModalOpen || !isMoneyFeeModalOpen
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Sale hacia abajo solo en móvil
        transition={{ duration: 0.5 }} // Duración y suavizado de la transición
      >
        <div className="w-full h-[60px] p-4  flex justify-between items-center gap-6 ">
          <div className="flex flex-col items-start  w-full">
            <div className="flex justify-between px-[4px] w-full items-center">
              <p className="text-[#2D2E2F] dark:text-[#FFFFFF] text-sm font-medium">
                {t("Vank.Share.LatestTransactions.Header.Title")}
              </p>
              <div className="flex items-center justify-center gap-4">
                <div className="btn-view-all 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[10px] dark:text-[#C0C0C1]">
                  {t("Vank.Share.LatestTransactions.Header.ViewMore")}
                </div>
                <div
                  onMouseEnter={() => setFill("white")}
                  onMouseLeave={() => setFill("#818282")}
                  onClick={Onclose}
                  className={`h-8 w-8 cursor-pointer flex items-center p-0 border border-[#F1F1F1] dark:border-[#212222] rounded-full hover:bg-[--color-darkest-grey]`}
                >
                  <IconClose fill={fill} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-full h-[90%] flex-col justify-start items-center ${
            users?.STATUSINCODE === 2 ? "overflow-hidden" : "overflow-y-auto"
          }`}
        >
          <div className="pl-1 w-full h-full">
            {users?.STATUSINCODE === 2 ? (
              OnboardingData.map((item, index) => (
                <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
                  <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
                    <div
                      className={`w-[36px] min-h-[36px] justify-center items-center flex ${
                        item.type === "convert"
                          ? "bg-[#FFE3B9]"
                          : "bg-[#E2F7D2]"
                      } rounded-[50px]`}
                    >
                      {item.type === "convert" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.40015 11.0982L7.56015 13.3199V4.67993M12.6001 6.90165L10.4401 4.67993V13.3199"
                            stroke="#2D2E2F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.94727 12.0527L12.0529 5.94714M5.94727 12.0527H9.61207M5.94727 12.0527V8.38794"
                            stroke="#2D2E2F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>

                    <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
                      <div className="h-3 justify-start items-center inline-flex">
                        <div className="text-[--color-darkest-grey] text-base font-normal ">
                          {item.name}
                        </div>
                      </div>
                      <div className="justify-start items-start gap-2 inline-flex">
                        {index === 1 && (
                          <span className="text-[--color-darkest-grey] text-xs font-normal ">
                            {t(
                              "Home.LatestTransactions.LatestTransactions.Ordermade"
                            )}
                            :
                          </span>
                        )}
                        <div className="text-[#818282] text-xs font-normal ">
                          {item.date}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
                      item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
                    } translate-x-0 transition-all duration-300`}
                  >
                    {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
                    <div className="w-[76px] h-[24px] p-2 bg-[#FFB94F] rounded-3xl justify-center items-center flex">
                      <div className="text-center text-[--color-lightest-white] text-xs font-bold">
                        {t(
                          "Home.LatestTransactions.LatestTransactions.earring"
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                      item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
                    } `}
                  >
                    <span className="text-[--color-darkest-grey] text-base font-semibold">
                      {item?.price}
                    </span>
                    <div className="w-[55px] h-6 p-2 bg-[#6AD67B] rounded-[24px] justify-center items-center inline-flex">
                      <div className="text-center text-white text-xs font-bold">
                        {t("Home.LatestTransactions.LatestTransactions.Made")}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                      item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
                    } `}
                  >
                    <span className="text-[--color-darkest-grey] text-base font-semibold">
                      + $1,420.72 USD
                    </span>
                    <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
                      <div className="text-center text-white text-xs font-bold">
                        {t("Home.LatestTransactions.LatestTransactions.Cancel")}
                      </div>
                    </div>
                  </div>

                  {/* w-[514px] */}
                  <div className="absolute bottom-0 -right-2 w-full h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
                    <div className="w-full h-px bg-[#F1F1F1]" />
                  </div>
                </div>
              ))
            ) : !isLoadingHistory ? (
              <>
                {listTransactions?.length > 0 ? (
                  listTransactions.map((transaction, index) => {
                    // console.log(transaction, " hola evc");

                    switch (transaction?.ACTION) {
                      case "INPUT":
                        return (
                          <Income
                            key={index}
                            item={transaction}
                            handleSelect={() =>
                              handleSelect(transaction, "income")
                            }
                          />
                        );
                      case "OUTPUT":
                        return (
                          <Expense
                            key={index}
                            item={transaction}
                            handleSelect={() =>
                              handleSelect(transaction, "expense")
                            }
                          />
                        );
                      case "PAYMENTS":
                        return (
                          <TransactionsFiat
                            key={index}
                            item={transaction}
                            handleSelect={handleSelect}
                          />
                        );
                      case "CONVERT":
                      // return <Conversion key={index} item={transaction} handleSelect={handleSelect} />;
                      case "batch":
                        return (
                          <TransactionBatch key={index} item={transaction} />
                        );
                      default:
                        return null;
                    }
                  })
                ) : (
                  <div className="w-full min-h-[224px] h-full flex flex-col justify-center items-center border-t-[1px] border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F]">
                    <div className="w-[80%] h-full flex flex-col justify-center items-center rounded-2xl text-center text-[#818282] dark:text-[#C0C0C1] leading-none">
                      <IconNoMovement />
                      {t("Mobile.TransactionHistory.IconNoMovement")}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="w-full min-h-[224px] h-full flex justify-center items-center border-t-[1px] py-1 border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F]">
                <CustomDataLoading />
              </div>
            )}
          </div>
        </div>

        {counter === 6 && (
          <div className="absolute min-w-[354px] min-h-[76px] rounded-[16px] top-0 right-[102%] flex justify-start items-start bg-white py-4 pl-4 pr-8 z-50">
            <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
              <span className="text-[14px] text-[#000] font-normal">
                {counter}
              </span>
            </div>
            <p className="text-[18px] text-left text-[#000] font-normal leading-[21.78px]">
              {t("Vank.Home.Onboarding.Six")}
            </p>
          </div>
        )}

        <CustomModal isOpen={isConversion}>
          <Convert setIsConversion={setIsConversion} />
        </CustomModal>
      </motion.div>

      <AnimatePresence>
        {isExpenseModalOpen && (
          <ModalInfoTransaction
            onclickCancel={() => handItemTransExpense(expenseInfo)}
            isExpenseModalOpen={isExpenseModalOpen}
            onClick={() => setIsExpenseModalOpen(false)}
            data={expenseInfo}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isIncomeModalOpen && (
          <ModalInfoTransaction
            isExpenseModalOpen={isIncomeModalOpen}
            onClick={() => setIsIncomeModalOpen(false)}
            data={incomeInfo}
          />
        )}
      </AnimatePresence>

      <CustomModal isOpen={isMoneyFeeModalOpen}>
        <ModalFeeTransaction
          data={moneyFeeInfo}
          onClose={() => setIsMoneyFeeModalOpen(false)}
          isMoneyModalOpen={isMoneyFeeModalOpen}
        />
      </CustomModal>

      <AnimatePresence>
        {isMoneyModalOpen && (
          <ModalApprovedShipping
            data={moneyInfo}
            isMoneyModalOpen={isMoneyModalOpen}
            onClose={() => setIsMoneyModalOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default LatestTransactions;
