import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";

export class servicesSetting extends AxiosClientRepository {
  async updateResidence(data: any) {
    try {
      

      const url = `${environment.url_servers.url_users}/secure/v1/updateInfoProfile`;
      const response = await this.put({ url, data });
    
      return response.body;
    } catch (error) {
      
      throw error;
    }
  }

  async searchTownById(quey: any) {
    try {
      const url = `${environment.url_servers.url_library}/libraryName/MATERIALIZED_VALIDTOWN_VIEW?query={"COUNTRYID":"${quey}"}`;
      const response = await this.get({ url });
   
      return response.body;
    } catch (error) {
      
      throw error;
    }
  }
  
  
  async updateAvatarProfile(data: any) {
    try {
      const url = `${environment.url_servers.url_prints}/prints/UploadIMG`;
     
      const response = await this.putFile({ url, data });
       
      return response.body;
    } catch (error) {
      
      throw error;
    }
  }

  async deleteAvatarProfile() {
    try {
     
      const url = `${environment.url_servers.url_prints}/prints/ResetImgProfile`;
      const response = await this.putFile({ url });
      return response.body;
    } catch (error) {
     
      throw error;
    }
  }
}
