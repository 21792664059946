import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatBalance } from "../../Home";
import { ServicesHome } from "../../Services/ServicesHome";
import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import CustomLoading from "@/apps/Shared/CustomLoading/CustomLoading";
import { IconTooltip, IconNike } from "@/assets/IconV2/IconsSvg";
import { capitalizeName } from "../../../utils/Utilfunctions";
import { useRecoilState } from "recoil";
import { dataAccounts } from "../../state";
const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};

const BalanceAdjustment = ({
  updateStatuses,
  onClickClose,
  currencyData,
  setCurrencyData,
  setIsBalanceAdjustment,
  dataAssetCurrencies,
  dataAssetCriptos,
  onClickAction,
  stateData

}: {
  currencyData?: any;
  setCurrencyData?: any;
  setIsBalanceAdjustment?: any;
  dataAssetCurrencies: any;
  dataAssetCriptos;
  onClickClose: () => void;
  updateStatuses: () => void;
  onClickAction: () => void,
  stateData: any
}) => {
  const hoveService = new ServicesHome();

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false); // Estado para Agregar Cuenta
  const [showMessage, setShowMessage] = useState(false);
  // const [data, setData] = useState(currencyData)
  const [data, setData] = useState(stateData);
  const [dataCripto, setDataCripto] = useState(dataAssetCriptos);
  const [t, i18n] = useTranslation("global");
  const [sendData, setSendData] = useState(null);

  const [assignVisibility, setAssignVisibility] = useState<string>("");
  const [assign, setAssign] = useState<boolean>(false);
  const [indexObject, setindexObject] = useState<number>();
  const [dataAccount, setDataAccount] = useRecoilState(dataAccounts)
  const [sendRequest, setsendRequest] = useState([])

  const toggleState = (index: any) => {


    setData((prevData) => {

      const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy

      newData.map((item: any) => {

        item?.accounts?.map((item2, index2: number) => {

          item2?.currencies?.map((item3, index3: number) => {

            if (index.ASSET_ID == item3.ASSET_ID) {

              item3.state_view = item3.state_view === 1 ? 2 : 1;

              const dat = {
                STATEVIEW: item3.state_view,
                ACTION: "CURRENCY",
                CODEASSET: item3.currency_code,
                ASSET_ID: item3.ASSET_ID
              }

              setsendRequest((request) => {

                if (request.length != 0) {
                                    
                  request.map((item,index) => {

                    if (item.ASSET_ID == dat.ASSET_ID) {
                      console.log(" index n", index);
                      console.log(request[0]);
                      
                      
                      request.splice(index)
                     
                    }else{
                     
                      request.push(dat)
                    }
                  })
                } else {
                  request.push(dat)
                }



                return request
              })

              setSendData({
                STATEVIEW: item3.state_view,
                ACTION: "CURRENCY",
                CODEASSET: item3.currency_code,
                ASSET_ID: item3.ASSET_ID
              });

            }
          })
        })

      });




      return newData;
    });

    setDataAccount((prevData) => {

      const newData = JSON.parse(JSON.stringify(prevData));


      newData.map((item: any) => {
        item?.accounts?.map((item2, index2: number) => {

          item2?.currencies?.map((item3, index3: number) => {

            if (index.ASSET_ID == item3.ASSET_ID) {

              item3.state_view = item3.state_view === 1 ? 2 : 1;
              const dat = {
                STATEVIEW: item3.state_view,
                ACTION: "CURRENCY",
                CODEASSET: item3.currency_code,
                ASSET_ID: item3.ASSET_ID
              }


              setSendData({
                STATEVIEW: item3.state_view,
                ACTION: "CURRENCY",
                CODEASSET: item3.currency_code,
                ASSET_ID: item3.ASSET_ID
              });
            }
          })
        })

      });




      return newData;
    });
  };

  const toggleStateCripto = (index: any) => {
    setDataCripto((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy
      const entries = Object.entries(
        newData.RateAmountHistoryCrypto.RateAmountHistoryBalance
      );

      entries.forEach(([key, value]: any) => {
        if (index === key) {
          value.STATEVIEW = value.STATEVIEW === 1 ? 2 : 1;
          setSendData({
            STATEVIEW: value.STATEVIEW,
            ACTION: "CRYPTO",
            CODEASSET: value.CODEASSET,
          });
        }
      });

      return newData;
    });
  };

  const handleSave = () => {
    setCurrencyData(data);
    localStorage.setItem("balance", JSON.stringify(data));
    setIsBalanceAdjustment(false);
    document.dispatchEvent(new Event("updateBalance"));
  };

  const updataCurrencie = async () => {

    try {
      const response = await hoveService.updateVisibilityAsset(sendData);

      setSendData(null);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // Función para verificar si el dispositivo es móvil
    const checkMobile = () => {

      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    // Verifica el tamaño de la ventana al cargar el componente
    checkMobile();

    // Añade un listener para manejar el cambio de tamaño de la ventana
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile); // Limpia el listener
    };
  }, []);

  useEffect(() => {

    if (sendData != null) {
      console.log();

      // sendRequest.map((item) => {

      //   console.log(item, " kk");
      // })

      updataCurrencie();
    }
  }, [sendData]);

  // useEffect(() => {

  //     if (sendData != null) {

  //         updataCurrencie()
  //     }

  // }, [dataAssetCriptos])

  // useEffect(() => {
  //   Object.entries(data.RateAmountHistoryCurrency.RateAmountHistoryBalance).map(
  //     (i: any, index) => {

  //     }
  //   );
  // }, []);

  const SaveChanges = () => {
    setIsLoading(true); // Mostrar el componente de carga
    setShowMessage(false); // Ocultar mensaje mientras carga

  // console.log(sendRequest?.length);
  

    sendRequest.map((item)=>{
      console.log(item);
    })

       setIsLoading(false); // Ocultar componente de carga
      setShowMessage(true); // Mostrar mensaje después de 2 segundos

    setTimeout(() => {
      setIsLoading(false); // Ocultar componente de carga
      setShowMessage(true); // Mostrar mensaje después de 2 segundos
    }, 100);
  }

  const handleAddAccount = async () => {
    setIsLoadingAdd(true); // Mostrar el componente de carga para Agregar Cuenta
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simular retraso de 1 segundo
    setIsLoadingAdd(false); // Ocultar el componente de carga
    onClickAction(); // Continuar con el flujo original de agregar cuenta
  };


  return (
    <motion.div
      className="2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute bottom-0 2xl:w-[530px] xl:w-[530px] lg:w-[530px] md:w-[530px] sm:w-[530px] mobile:w-full 2xl:h-auto xl:h-auto lg:h-auto md:h-auto sm:h-auto mobile:h-auto  py-4 flex flex-col justify-center items-start  bg-[var(--bacground-component-module-light)] dark:bg-[--background-component-module-dark] 2xl:rounded-2xl xl:rounded-2xl lg:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl"
      initial={{ translateY: isMobile ? "100%" : "0%" }}
      animate={{ translateY: isMobile ? "0%" : "0%" }}
      exit={{ translateY: isMobile ? "100%" : "0%" }}
      transition={{ duration: 0.5 }}
    >
      <div className="2xl:w-[530px] xl:w-[530px] lg:w-[530px] md:w-[530px] sm:w-[530px] mobile:w-full h-[45px] flex-col justify-center items-start inline-flex">

        <div className="w-full px-4 justify-between items-center inline-flex">
          <div className="min-w-[263px] h-7 justify-start items-center gap-1 flex">
            <div className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] 2xl:text-xl xl:text-xl lg:text-xl tablet:text-xl mobile-375:text-lg mobile:text-md font-semibold">
              {t("Vank.Home.Balances.Setting.Title")}
            </div>
          </div>
          <CustomButton
            className={`w-7 h-7 rounded-[500px] p-1 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#F1F1F1] justify-center items-center gap-2.5 flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#212222]  ${theme === "dark" ? "dark-theme-back" : "light-theme-back"
              } transition-all duration-300`}
            onclick={onClickClose}
            type="submit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              className="w-[18px] h-[18px] icon-svg"
            >
              <path
                d="M5 5.5L13 13.5"
                // stroke="var(--content-main-gray-light)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 5.5L5 13.5"
                // stroke="var(--content-main-gray-light)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CustomButton>
        </div>

        <div className="w-full px-4 justify-start items-center gap-2.5 inline-flex">
          <div className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-sm font-normal ">
            {t("Vank.Home.Balances.Setting.Message")}
          </div>
        </div>

      </div>



      <div className="w-full text-center pt-[32px] pb-[24px] ">
        {isLoadingAdd ? (
          <div className="flex justify-center items-center  ">
            <div className="h-6  flex items-center">
              <CustomLoading />
            </div>
          </div>
        ) : (
          <CustomButton
            onclick={handleAddAccount}
            label={
              <span className="flex gap-2 justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="dark:text-[var(--content-main-black-dark)]"
                >
                  <path
                    d="M17 9H14M18.1 5H5.9C4.29837 5 3 6.29837 3 7.9V16.1C3 17.7016 4.29837 19 5.9 19H18.1C19.7016 19 21 17.7016 21 16.1V7.9C21 6.29837 19.7016 5 18.1 5ZM6.75 9.75H3V14.25H6.75C7.34674 14.25 7.91903 14.0129 8.34099 13.591C8.76295 13.169 9 12.5967 9 12C9 11.4033 8.76295 10.831 8.34099 10.409C7.91903 9.98705 7.34674 9.75 6.75 9.75Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="dark:text-[--content-main-black-dark]">
                  {t("Vank.Home.Balances.Setting.addAccount")}
                </p>
              </span>
            }
            className="rounded-xl tablet:w-[498px] lg:w-[498px] lg:h-[80px] mobile:h-full mobile:w-[22rem] py-4 dark:bg-[var(--background-hover-dark)] bg-[var(--background-layaout-light)] border border-[#EAEAEA]"
          />
        )}
      </div>


      <div className="relative pb-[32px] w-full 2xl:h-auto xl:h-auto lg:h-auto md:h-auto sm:h-auto mobile:min-h-auto flex-col justify-start items-start inline-flex overflow-auto">
        <div className="w-full min-h-px bg-[var(--strokes-network-light)] dark:bg-[--strokes-separator-disabled-dark] absolute top-0" />

        {/* {Object.entries(
          data.RateAmountHistoryCurrency.RateAmountHistoryBalance
        ).map((i: any, index) => (
          <div
            key={`currency-${index}`}
            className={`relative border-b-[1px] dark:border-b-[var(--strokes-separator-disabled-dark)] w-full min-h-[56px] px-4 justify-start items-center gap-2 inline-flex`}
          >
            <div className="w-[36px] h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
              <img
                src={i[1].IMGURL}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>

            <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
              <div className="w-full justify-between items-center inline-flex">
                <div className="max-w-[108px]  flex flex-col ">
                  <span className=" text-start text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold">
                    {i[0]}
                  </span>

                  <div className="flex gap-2">
                    <span className="text-xs text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]">
                      {formatBalance(i[1].difference)}
                    </span>

                    <span
                      className={`text-xs ${parseFloat(i[1].percentage) >= 0
                        ? "text-[var(--success-chip-light)]"
                        : "text-[var(--error-chip-cancelado-light)]"
                        }`}
                    >
                      {i[1].percentage}
                    </span>
                  </div>
                </div>

                <div
                  className={`relative w-[50px] h-[30px] pl-px pr-[21px] py-px ${i[1].STATEVIEW == 1
                    ? "bg-[var(--branding-brand-yellow-light)] "
                    : "bg-[var(--fill-colors-light-secondary)] bg-opacity-[15%]"
                    } rounded-[99px] justify-end items-center gap-2.5 inline-flex cursor-pointer`}
                  onClick={() => toggleState(i[0])}
                >
                  <motion.div
                    className={`absolute ${i[1].STATEVIEW == 1 ? "right-0" : "left-0"
                      } w-[28px] h-[28px] rounded-full bg-[var(--system-colors-sc-l-white)] `}
                    layout
                    transition={spring}
                  />
                </div>
              </div>
              <hr />
            </div>
          </div>
        ))} */}

        {dataAccount?.map((item: any, index) => (

          item?.accounts?.map((item2, index2: number) => (

            item2?.currencies?.map((item3, index3: number) => (
              <div
                key={`currency-${index}`}
                className={`relative border-b-[1px] dark:border-b-[var(--strokes-separator-disabled-dark)] w-full min-h-[56px] px-4 justify-start items-center gap-2 inline-flex`}
              >
                <div className="w-[36px] h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
                  <img
                    src={item3?.img_url}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                </div>

                <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
                  <div className="w-full justify-between items-center inline-flex">
                    <div className=" ] flex flex-col ">
                      <span className="capitalize text-start text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold">
                        {item3?.type_money} / {item2?.ACCOUNT_NAME}
                      </span>

                      <div className="flex gap-2">
                        <span className="text-xs text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]">
                          {item3.differenceMoney}
                        </span>

                        <span
                          className={`text-xs ${item3.level == "HIGH"
                            ? "text-[var(--success-chip-light)]"
                            : item3.level == "EQUAL" ? "text-[var(--error-chip-cancelado-light)]" : ""
                            }`}
                        >
                          {item3.rateHistory}
                        </span>
                      </div>
                    </div>

                    {/* <div
                      className={`relative w-[50px] h-[30px] pl-px pr-[21px] py-px ${i[1].STATEVIEW == 1
                        ? "bg-[var(--branding-brand-yellow-light)] "
                        : "bg-[var(--fill-colors-light-secondary)] bg-opacity-[15%]"
                        } rounded-[99px] justify-end items-center gap-2.5 inline-flex cursor-pointer`}
                      onClick={() => toggleState(i[0])}
                    >
                      <motion.div
                        className={`absolute ${i[1].STATEVIEW == 1 ? "right-0" : "left-0"
                          } w-[28px] h-[28px] rounded-full bg-[var(--system-colors-sc-l-white)] `}
                        layout
                        transition={spring}
                      />
                    </div> */}

                    <div
                      className={`relative w-[50px] h-[30px] pl-px pr-[21px] py-px ${item3.state_view == 1 ? "bg-[var(--branding-brand-yellow-light)] "
                        : "bg-[var(--fill-colors-light-secondary)] bg-opacity-[15%]"
                        } rounded-[99px] justify-end items-center gap-2.5 inline-flex cursor-pointer`}
                      onClick={() => toggleState(item3)}
                    >
                      <motion.div
                        className={`absolute ${item3.state_view == 1 ? "right-0" : "left-0"
                          } w-[28px] h-[28px] rounded-full bg-[var(--system-colors-sc-l-white)] `}
                        layout
                        transition={spring}
                      />
                    </div>

                  </div>
                  <hr />
                </div>
              </div>

            ))
          ))
        ))}



        {Object.entries(
          dataCripto.RateAmountHistoryCrypto.RateAmountHistoryBalance
        ).map((i: any, index: number) => (
          <div
            key={`crypto-${index}`}
            className={`relative  border-b-[1px]  w-full min-h-[56px] px-4 justify-start items-center gap-2 inline-flex `}
          >
            <div className="w-[36px] h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
              <img
                src={i[1].IMGURL}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>

            <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
              <div className="w-full justify-between items-center inline-flex">
                <div className="max-w-[108px]  flex flex-col ">
                  <div className="flex items-center justify-center gap-1">
                    <span className=" text-start text-[var(--content-main-black-light)] text-base font-semibold">
                      {i[0]}
                    </span>

                    <span className="network">
                      {JSON.parse(JSON.parse(i[1].NETWORKS))[0].NETWORKNAME}
                    </span>
                  </div>

                  <div className="flex gap-2">
                    <span className="text-xs text-[var(--content-main-gray-light)]">
                      {formatBalance(i[1].difference)}
                    </span>

                    <span
                      className={`text-xs ${parseFloat(i[1].percentage) >= 0
                        ? "text-[var(--success-chip-light)]"
                        : "text-[var(--error-chip-cancelado-light)]"
                        }`}
                    >
                      {i[1].percentage}
                    </span>
                  </div>
                </div>

                <div
                  className={`relative w-[50px] h-[30px] pl-px pr-[21px] py-px ${i[1].STATEVIEW == 1
                    ? "bg-[var(--branding-brand-yellow-light)]"
                    : "bg-[var(--fill-colors-light-secondary)] bg-opacity-[15%]"
                    } rounded-[99px] justify-end items-center gap-2.5 inline-flex`}
                  onClick={() => toggleStateCripto(i[0])}
                >
                  <motion.div
                    className={`absolute ${i[1].STATEVIEW == 1 ? "right-0" : "left-0"
                      } w-[28px] h-[28px] rounded-full bg-[var(--system-colors-sc-l-white)]`}
                    layout
                    transition={spring}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* {data.map((item, index) => (
                    <>
                        <div className="w-full min-h-14 px-4 justify-start items-center gap-2 inline-flex">
                            <div className="min-w-9 min-h-9 w-9 h-9 rounded-full justify-center items-center gap-2.5 flex">
                                <img src={item.IMGURL} alt="" className="w-full h-full" />
                            </div>

                            <div className="w-full h-8 flex-col justify-center items-start gap-[2px] inline-flex">

                                <div className="w-full h-[17px] justify-between items-center inline-flex">
                                    <div className="text-zinc-500 text-base font-semibold ">{item.ASSET}</div>
                                </div>

                                <div className="w-full h-[10px] justify-start items-start gap-1 flex">
                                    <div className="text-zinc-500 text-xs font-normal ">{item.value}</div>
                                    <div className="w-11 text-right text-rose-400 text-xs font-bold ">{item.change}</div>
                                </div>
                            </div>

                            <div className={`relative w-[50px] h-[30px] pl-px pr-[21px] py-px ${!item.state ? 'bg-[var(--main-brand-yellow)]' : 'bg-[#78788029] bg-opacity-[15%]'} rounded-[99px] justify-end items-center gap-2.5 inline-flex`}
                                onClick={() => toggleState(index)}
                            >
                                <motion.div className={`absolute ${!item.state ? 'right-0' : 'left-0'} w-[28px] h-[28px] rounded-full bg-[--system-Colors-SC-L-White]`} layout transition={spring} />
                            </div>

                        </div>
                        <div className="w-full flex justify-end pr-4">
                            <div className="w-[452px] min-h-px bg-[var(--strokes-network-light)]" />
                        </div>
                    </>
                ))} */}
      </div>

      <div className="2xl:w-[530px] xl:w-[530px] lg:w-[530px] md:w-[530px] sm:w-[530px] mobile:w-full h-auto px-4  flex-col justify-center items-center gap-2.5 inline-flex">
        {!showMessage && (
          <div className="w-full h-[40px] flex justify-center items-center">
            {isLoading ? (
              <div className="h-6 flex items-center">
                {/* Componente de carga personalizado */}
                <CustomLoading />
              </div>
            ) : (
              <button
                className="w-full h-[40px] px-9 bg-[var(--branding-brand-yellow-light)] hover:bg-[var(--content-inner-inner-black-light)] dark:hover:bg-[#FFFFFF] text-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] dark:hover:text-[#2D2E2F] rounded-[999px] justify-center items-center gap-2 inline-flex cursor-pointer transition-all ease-out duration-300"
                onClick={()=>SaveChanges()}
              >
                <div className="w-[147px] justify-center items-center gap-2 flex">
                  <div className="text-[16px] font-medium">
                    {t("Vank.Home.Balances.Setting.BtnSave")}
                  </div>
                </div>
              </button>
            )}
          </div>
        )}
        {showMessage && (
          <div
            className="w-[498px]  h-[68px] px-4 mb-4 bg-[#FFF3DB] text-[var(--content-inner-inner-black-light)] flex items-center justify-between justify-center rounded-lg"
          >
            <span className="flex items-center">
              <IconTooltip
                className="w-[24px] h-[24px]" />
            </span>
            <span className="text-[14px] font-medium flex-1 ml-[8px] text-[--content-inner-inner-black-light]">
              Resumen de balances actualizado
            </span>
            <span className="rounded-full h-[24px] w-[24px] bg-[#B7EB8F] flex justify-center items-center ml-4">
              <IconNike className="w-[12px] h-[12px]" />
            </span>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default BalanceAdjustment;
