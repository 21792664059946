import React, { useEffect, useRef, useState } from "react";
import imgProfile from "@assets/imgProfile.png";
import Banner1 from "@assets/img/imgsBanner2/banner1.png";
import Banner2 from "@assets/img/imgsBanner2/banner2.png";
import Banner3 from "@assets/img/imgsBanner2/banner3.png";
import Banner4 from "@assets/img/imgsBanner2/banner4.png";
import Banner5 from "@assets/img/imgsBanner2/banner5.png";
import { useTranslation } from "react-i18next";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { ProfileUser } from "@/apps/Vank/Page/Home/state";
import { useRecoilState } from "recoil";
import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { capitalizeName } from "@/apps/Vank/Page/utils/Utilfunctions";
import { ButtonCopy } from "@/apps/Vank/Page/Transactions/shared/ButtonCopy/ButtonCopy";
import CustomInput from "@/apps/Shared/CustomInput/CustomInput";
import { IconPenci, IconPenciFiatUpdate, IconPencilUpdate, IconLogout } from "@/assets/IconV2/IconsSvg";
import { Link, useNavigate } from "react-router-dom";
import ServiceSidebar from "../../../Sildebar/ServiceSidebar";
import { clear } from "idb-keyval";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import CustomLenguaje from "@/apps/Auth/Shared/CustomLenguaje/CustomLenguaje";
import { default as toastHook } from "@/hooks/useToast";
import { IconError } from "@/apps/Shared/ToastContainer/Icons";
import { servicesSetting } from "@/apps/Vank/Page/settings/services/servicesSetting";
import CustomLoading from "@/apps/Shared/CustomLoading/CustomLoading";
import { ServicesHome } from "@/apps/Vank/Page/Home/Services/ServicesHome";
import UpdatePhoto from "@/apps/Vank/Page/settings/Profile/UpdatePhoto/UpdatePhoto";
import { useUpdatePhoto } from "@/apps/Vank/Page/settings/Profile/UpdatePhoto/UpdatePhotoContext";
import { useSocket } from "@/apps/Context/WebSocketContext";

const SidebarProfile = ({ setProfileModalOpen }: { setProfileModalOpen: any }) => {
  const { imgSrc, setImgSrc, isImgPreview, setIsImgPreview, onSelectFile, handleDeleteImg, isLoadingImg } = useUpdatePhoto();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false); // Detectar si es móvil
  const serviceSidebar = new ServiceSidebar();
  const serviceOperations = useSocket("operations");
  const [t, i18n] = useTranslation("global");
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto
  const [statusEditName, setStatusEditName] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [viewModalExit, setviewModalExit] = useState<boolean>(false);
  const [modoDark, setModoDark] = useState<number>(1);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const toast = toastHook();
  const allowedFormats = ["jpeg", "png"]; // Lista de formatos permitidos
  //const [isImgPreview, setIsImgPreview] = useState(false);
  const [isImgOption, setIsImgOption] = useState(false); // Estado para el modal de cambio de idioma
  const [isDeleteImg, setIsDeleteImg] = useState(false); // Estado para el modal de cambio de idioma
  const [error, setError] = useState("");
  const services = new servicesSetting();
  // const [imgSrc, setImgSrc] = useState({
  //   name: "",
  //   format: "",
  //   size: "",
  //   src: "",
  // });

  const slides = [
    { url: Banner1 },
    { url: Banner2 },
    { url: Banner3 },
    { url: Banner4 },
    { url: Banner5 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpenSend, setIsOpenSend] = useState(false);
  const hideTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const dropdownRef = useRef(null);
  const spanRef = useRef(null); // Ref de VankId
  const [copied, setCopied] = useState(false);
  const [aliasName, setAliasName] = useState(""); // Estado para guardar el nuevo alias
  const [successMessage, setSuccessMessage] = useState(""); // Mensaje de éxito o error
  const servicesHome = new ServicesHome();
  const [stateName, setStateName] = useState("Alias"); // Estado para el nombre
  const inputRef = useRef(null); // Referencia al input
  const prevSlide = () => {
    setCurrentIndex((curr) => (curr === 0 ? slides?.length - 1 : curr - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((curr) => (curr === slides?.length - 1 ? 0 : curr + 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 4000);

    return () => clearInterval(slideInterval);
  }, [currentIndex]);

  // useEffect(() => {
  //   new IndexedDBLocalDropdownRepository()
  //     .get("user")
  //     .then((userStored) => {
  //       const user = userStored[0];

  //       if (user) {
  //         setUsers((u: any) => ({
  //           ...u,
  //           AVATAR: user?.AVATAR,
  //           NAME:
  //             user?.COMPLETENAME?.split(" ")[0] +
  //             " " +
  //             user?.SURNAME?.split(" ")[0],
  //           EMAIL: user?.EMAILUSERS,
  //           VANKID: user?.VANKID,
  //         }));
  //       }

  //       setStateName(user?.COMPLETENAME?.split(" ")[0] +
  //         " " +
  //         user?.SURNAME?.split(" ")[0],)
  //     })

  //     .catch((error) => {
  //       console.error(error);
  //     });

  //   document.addEventListener("SliderProfile", () => setIsOpenSend(true));
  // }, []);

  // useEffect(() => {
  //   new IndexedDBLocalDropdownRepository()
  //     .get("user")
  //     .then((userStored) => {
  //       const user = userStored[0];

  //       if (user) {
  //         // Determinar el nombre a mostrar basado en ALIASNAME o COMPLETENAME
  //         const displayName = user?.ALIASNAME 
  //           ? user?.ALIASNAME 
  //           : user?.COMPLETENAME?.split(" ")[0] + " " + user?.SURNAME?.split(" ")[0];

  //         setUsers((u: any) => ({
  //           ...u,
  //           AVATAR: user?.AVATAR,
  //           NAME: displayName, // Usar el nombre determinado
  //           EMAIL: user?.EMAILUSERS,
  //           VANKID: user?.VANKID,
  //         }));

  //         setStateName(displayName); // Configurar también el estado con el nombre
  //       }
  //     });
  // }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userStored = await new IndexedDBLocalDropdownRepository().get("user");
        const user = userStored[0];

        if (user) {
          // Determinar el nombre a mostrar basado en ALIASNAME o COMPLETENAME
          const displayName = user?.ALIASNAME
            ? user?.ALIASNAME
            : `${user?.COMPLETENAME?.split(" ")[0]} ${user?.SURNAME?.split(" ")[0]}`;

          setUsers((prevState: any) => ({
            ...prevState,
            AVATAR: user?.AVATAR,
            NAME: displayName, // Usar el nombre determinado
            EMAIL: user?.EMAILUSERS,
            VANKID: user?.VANKID,
          }));

          setStateName(displayName); // Configurar también el estado con el nombre
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();

    const handleSliderProfile = () => setIsOpenSend(true);
    document.addEventListener("SliderProfile", handleSliderProfile);

    return () => {
      document.removeEventListener("SliderProfile", handleSliderProfile); // Limpiar el evento
    };


  }, []);


  // Función para manejar el envío al backend
  const handleUpdateName = async () => {
    try {
      if (!stateName.trim()) return; // Evitar enviar datos vacíos

      const payload = { ALIASNAME: stateName.trim() };
      await servicesHome.updateOnboarding(payload);
      console.log("Nombre actualizado correctamente:", payload);
    } catch (error) {
      console.error("Error al actualizar el nombre:", error);
    }


  };

  // Manejador del evento onBlur
  const handleBlur = () => {
    setStatusEditName(true); // Deshabilitar edición
    handleUpdateName(); // Enviar al backend
  };


  // Manejador del evento onKeyDown para detectar Enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Evitar comportamiento por defecto del Enter
      setStatusEditName(true); // Deshabilitar edición
      handleUpdateName(); // Enviar al backend
      inputRef.current?.blur(); // Quitar el foco del input
    }
  };

  useEffect(() => {

    if (localStorage.getItem("mode") == "dark") {
      setModoDark(2)
    } else {
      setModoDark(1)
    }

    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, []);





  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  const handleCopy = () => {
    if (spanRef.current) {
      const textToCopy = spanRef.current.textContent; // Obtiene el texto del span
      setCopied(false);
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopied(true);
          if (copied) {
            setTimeout(() => setCopied(false), 2000); // Ocultar después de 2 segundos
          }
        })
        .catch((err) => console.log("err"));
    }
  };

  // Define el evento para manejar el clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifica si el clic ocurrió fuera del contenedor de las opciones
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        
      }
    };

    // Escucha el evento 'mousedown' en el documento
    document.addEventListener("mousedown", handleClickOutside);

    // Limpia el evento al desmontar el componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);



  const handleOptionClick = (e) => {
    e.stopPropagation(); // Detener propagación del evento al contenedor
  };


  useEffect(() => {
    if (imgSrc || error) {
      setIsImgOption(false);
      return;
    }
  }, [imgSrc, error]);

  const listLinksFooter = [

    {
      id: 1,
      path: "/settings",
      className:
        "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]",
      text: t("Vank.Share.Sidebar.Link.Settings"),
      link: `${location.pathname}`,
    },
    {
      id: 2,
      path: "/Help",
      className:
        "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]",
      text: t("Vank.Share.Sidebar.Link.Help"),
      link: `${location.pathname}`,
    },

  ];


  const navigate = useNavigate();

  /** logut*/
  const handleLogOut = async () => {

    serviceOperations.method.closeAllWebSockets()

    setLoading(true); // Activa el estado de carga
    serviceSidebar
      .LogOutUser()
      .then(() => {
        clear();
        StorageService.delete("token");
        StorageService.delete("verify");
        //localStorage.clear();
        localStorage.removeItem("ip");
        localStorage.removeItem("ipTimestamp");
        localStorage.removeItem("id");
        sessionStorage.clear();
        StorageService.clear();
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      })
      .finally(() => {
        setLoading(false); // Desactiva el estado de carga, tanto si la operación es exitosa como si falla
      });
  };

  const toggleState = () => {
    if (theme === "light") {
      toggleDarkMode();
      setModoDark(2)
    } else {
      toggleDarkMode();
      setModoDark(1)
    }

  }

  // Detectar dispositivo móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // const handleDeleteImg = async () => {
  //   setIsImgOption(false);
  //   setIsDeleteImg(true);

  //   try {
  //     const res = await services.deleteAvatarProfile();
  //     setIsImgOption(false);

  //     const hasUser = {
  //       ...users,
  //       AVATAR: res?.Url,
  //     };

  //     setUsers(hasUser);
  //     await new IndexedDBLocalDropdownRepository().save({
  //       key: "user",
  //       data: hasUser,
  //     });
  //   } catch (error) {
  //     if (error?.data?.statusCode === 404) {
  //       toast.error(t("Vank.Setting.Profile.text20"), {
  //         position: "top-right", // Obligatorio
  //         autoClose: 5000, // Opcional
  //         iconError: <IconError />, // Opcional
  //         hideProgressBar: true, // Opcional
  //         isHovered: true,
  //       });
  //       return;
  //     }
  //     toast.error(t("Vank.Share.text15"), {
  //       position: "top-right", // Obligatorio
  //       autoClose: 5000, // Opcional
  //       iconError: <IconError />, // Opcional
  //       hideProgressBar: true, // Opcional
  //       isHovered: true,
  //     });
  //   } finally {
  //     setIsDeleteImg(false);
  //   }
  // };

  // // Cargar la imagen seleccionada y verificar tamaño
  // const onSelectFile = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const fileFormat = file.type.split("/")[1]; // Extraer el formato del archivo


  //     if (!allowedFormats.includes(fileFormat)) {
  //       setError(t("Vank.Setting.Profile.text1"));
  //       setImgSrc({
  //         name: "",
  //         format: "",
  //         size: "",
  //         src: "",
  //       });
  //       return;
  //     }

  //     if (file.size > 5 * 1024 * 1024) {
  //       setError(
  //         `${t("Vank.Setting.Profile.text2")} 5 ${t(
  //           "Vank.Setting.Profile.mb"
  //         )}.`
  //       );
  //       setImgSrc({
  //         name: "",
  //         format: "",
  //         size: "",
  //         src: "",
  //       });
  //       return;
  //     }
  //     // setErrorMessage("");
  //     // setOriginalSizeMB((file.size / 1024 / 1024).toFixed(2)); // Tamaño original

  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (reader.result && typeof reader.result === "string") {
  //         setImgSrc({
  //           name: file.name,
  //           format: file.type,
  //           size: file.size,
  //           src: reader.result,
  //         });
  //         setIsImgPreview(true); // Muestra el componente de previsualización
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };


  const handleLogOutWithLoading = async () => {
    setIsLoading(true); // Activa el estado de carga
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await handleLogOut(); // Llama la función de cierre de sesión
    } catch (error) {
      console.error("Error al cerrar sesión", error);
    } finally {
      setIsLoading(false); // Desactiva el estado de carga
      setviewModalExit(false); // Cierra el modal
    }
  };

  useEffect(() => {
    // Ajustar dinámicamente el ancho del input según el texto
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Arial"; // Ajusta esta fuente según la usada en el input
    const textWidth = context.measureText(stateName).width;
    inputRef.current.style.width = `${textWidth + 20}px`; // Añadir un padding extra
  }, [stateName]); // Se ejecuta cada vez que cambia `stateName`

  return (
    <>
      <div
        className={`absolute z-50 top-0 right-0 w-[388px] min-h-screen flex flex-col justify-between items-center py-[48px] bg-[--color-lightest-white] dark:bg-[#2D2E2F] 2xl:flex xl:flex lg:flex md:flex tablet:flex mobile:hidden shadow z-40 ${isOpenSend ? "translate-x-0 " : "translate-x-full"
          } transition-all duration-700`}
      // ref={divRef}
      >

        <div className="w-full h-[335px] xl:h-[500px] 2xl:h-[635px]">
          <div className="w-full h-[44px] flex justify-between py-2 px-4">
            <span className="text-xl font-semibold text-[--color-darkest-grey] dark:text-[#FFFFFF]">
              {t("Vank.Share.ProfileModal.Profile")}
            </span>
            <CustomButton
              className={`w-7 h-7 rounded-[500px] 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#818282] justify-center items-center flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#212222] ${theme === "dark" ? "dark-theme-back" : "light-theme-back"
                } transition-all duration-300`}
              onclick={() => {
                setIsOpenSend(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                className="w-[18px] h-[18px] icon-svg"
              >
                <path
                  d="M5 5.5L13 13.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 5.5L5 13.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </CustomButton>
          </div>
          <div className="w-full h-[285px] xl:h-[385px] 2xl:h-[485px] ">
            <div className="w-full h-[281px] 2xl:h-[281px] bg-[--color-lighter-white] dark:bg-[#1A1A1B] flex flex-col justify-center items-center">
              <div className="w-full h-[200px] flex flex-col items-center gap-[20px] 2xl:gap-[24px]">
                <div 
                className="w-[122px] h-[122px] rounded-full cursor-pointer relative group "
                
                >
                  {!isDeleteImg && (
                    <img
                      src={users?.AVATAR}
                      alt="AVATAR"
                      className="w-full h-full rounded-full object-cover cursor-pointer"
                    />
                  )}
                  {isLoadingImg && (
                    <div className="absolute top-0 left-0 bg-[#131313]/60 w-full h-full rounded-full flex items-center justify-center">
                      <div className="w-[40px] h-[40px] border-4 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-[var(--branding-brand-yellow-light)] rounded-full" />
                    </div>
                  )
                  }  
                    <button
                    ref={dropdownRef}
                    disabled={isDeleteImg}
                    className={`absolute bottom-2 right-2 min-w-[24px] min-h-[24px] rounded-full ${isImgOption ? "flex" : "hidden"
                      } group-hover:flex bg-[#FFFFFF] dark:bg-[#2D2E2F] justify-center items-center transition-all duration-500`}
                    onClick={() => setIsImgOption(!isImgOption)} 
                    onMouseLeave={() => {
                      hideTimeout.current = setTimeout(() => setIsImgOption(false), 100);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4167 2.25H4.58333C3.29467 2.25 2.25 3.29467 2.25 4.58333V10.4167C2.25 11.7053 3.29467 12.75 4.58333 12.75H10.4167C11.7053 12.75 12.75 11.7053 12.75 10.4167V4.58333C12.75 3.29467 11.7053 2.25 10.4167 2.25Z"
                        stroke={theme === "dark" ? "#FFFFFF" : "#2D2E2F"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.25 10.4167L4.34417 8.3225C4.56276 8.10521 4.85845 7.98324 5.16667 7.98324C5.47488 7.98324 5.77058 8.10521 5.98917 8.3225L7.26083 9.59417C7.47942 9.81146 7.77512 9.93342 8.08333 9.93342C8.39155 9.93342 8.68724 9.81146 8.90583 9.59417L12.75 5.75"
                        stroke={theme === "dark" ? "#FFFFFF" : "#2D2E2F"}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>             
                  {isImgOption && (
                    <>
                      <div
                            onMouseEnter={() => {
                              if (hideTimeout.current) clearTimeout(hideTimeout.current);
                              setIsImgOption(true);
                            }}
                            onMouseLeave={() => {
                              hideTimeout.current = setTimeout(() => setIsImgOption(false), 100);
                            }}
                        className="absolute bottom-0 -left-2 min-w-[87px] rounded-[4px] flex flex-col bg-[#FFFFFF] dark:bg-[#2D2E2F] justify-center items-center transition-all duration-500 shadow"
                        onClick={handleOptionClick} // Detener propagación

                      >
                        <label
                          htmlFor="imageUpload"
                          className="w-full py-1 px-2 flex justify-center items-center text-[#000000] dark:text-[#FFFFFF] text-[10px]"
                        // onClick={() => handleDeleteImg}
                        >
                          {t("Vank.Setting.Profile.text14")}
                        </label>
                        <button

                          disabled={isDeleteImg}
                          className="w-full py-1 px-2 flex justify-center items-center text-[#000000] dark:text-[#FFFFFF] text-[10px]"
                          onClick={(e) => {
                            e.stopPropagation(); // Detener propagación
                            handleDeleteImg();
                          }}
                        >
                          {t("Vank.Setting.Profile.text15")}
                        </button>
                        <input
                          id="imageUpload"
                          type="file"
                          accept="image/*"
                          onChange={onSelectFile}
                          className="hidden"
                        />
                      </div>
                    </>

                  )}


                </div>
                <div className="w-full h-[53px] flex flex-col items-center">
                  {/* ==== nombre ====== */}
                  <div className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-medium leading-none">
                    {/* {capitalizeName(
                      t("Vank.Share.ProfileModalMobile.Name", { name: stateName })
                    )} */}
                    <div className="relative  flex items-center">
                      <input
                        ref={inputRef}
                        className={`bg-transparent text-center outline-none border-none leading-none
                          ${!statusEditName ? "bg-red-700 dark:bg-[--background-hover-dark]" : ""}
                          focus:bg-[#DADBDB] dark:focus:bg-[--background-hover-dark] 
                          transition-colors duration-300`}
                        value={capitalizeName(stateName)}
                        onChange={(e) => setStateName(e.target.value)}
                        onBlur={handleBlur} // Manejar el blur
                        onKeyDown={handleKeyDown} // Detectar Enter
                        disabled={statusEditName}
                        name="editName"
                        id="editName"
                      />

                      <label onClick={() => {
                        setStatusEditName(false); // Habilitar edición
                        inputRef.current?.focus(); // Enfocar el input
                      }}
                        htmlFor="editName" className="-ml-2 cursor-pointer" >
                        <IconPenciFiatUpdate ClassName="" onClick={() => setStatusEditName(false)} />
                      </label>

                    </div>

                    {/* {capitalizeName(stateName)} */}
                  </div>
                  {/* ============== */}
                  <span className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-sm font-normal ">
                    {t("Vank.Share.ProfileModal.Email", {
                      Email: users?.EMAIL,
                    })}
                  </span>
                  <div className="min-w-[147px] h-[17px] justify-center items-center gap-1 inline-flex mt-1 ">
                    <div className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-sm font-normal ">
                      Vank ID
                    </div>
                    <div className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-sm font-medium justify-start">
                      {users?.VANKID}

                    </div>
                    <div className="w-3 h-3  flex items-center right-4">
                      <ButtonCopy textCopy={users?.VANKID} classNameBtnCopy={''} clasNameIcon="flex h-4 -ml-3"></ButtonCopy>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full min-h-[131px] rounded-xl flex-col justify-center text-center items-center overflow-hidden transition-all duration-300 group  inline-flex">

              {listLinksFooter.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="py-4 border-b hover:bg-[--background-hover-light] hover:font-medium hover:scale-x-105 px-[60px] w-full text-base text-center border-b-[#F1F1F1] dark:hover:bg-[--background-hover-dark] dark:border-[#575859] dark:text-[#FFF]  font-normal text-[var(--content-main-black-light)]">
                    <Link key={index} to={item.path}>{item.text}</Link>
                  </div>
                </React.Fragment>
              ))}
              <div className="py-4 border-b hover:bg-[--background-hover-light] hover:font-medium hover:scale-x-105 px-[60px] w-full text-base text-center border-b-[#F1F1F1] dark:hover:bg-[--background-hover-dark] dark:border-[#575859] dark:text-[#FFF]  font-normal text-[var(--content-main-black-light)]">
                <button onClick={() => setviewModalExit(true)}>
                  {t("Vank.Share.Sidebar.Link.BtnLogOut")}
                </button>

              </div>
              {/* <div className="h-[51px] flex justify-center items-center cursor-pointer">
                <span className="text-[--color-darkest-grey]  dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                  {t("Vank.Share.ProfileModal.Link.EditProfile")}
                </span>
              </div>

              <span className="w-full h-px bg-[#F1F1F1] dark:bg-[#212222]"></span>

              <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                <span className="text-[--color-darkest-grey]  dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                  {t("Vank.Share.ProfileModal.Link.SettingsAndPrivacy")}
                </span>
              </div>

              <span className="w-full h-px bg-[#F1F1F1] dark:bg-[#212222]" />

              <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                <span className="text-[--color-darkest-grey]  dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                  {t("Vank.Share.ProfileModal.Link.Support")}
                </span>
              </div>

              <span className="w-full h-px bg-[#F1F1F1] dark:bg-[#212222]" />

              <div className="h-[41px] 2xl:h-[51px] flex justify-center items-center cursor-pointer">
                <span className="text-[--color-darkest-grey]  dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                  {t("Vank.Share.ProfileModal.Link.ShareApp")}
                </span>
              </div> */}
            </div>


          </div>
        </div>

        <div className="w-full min-h-[52px] flex justify-center items-center">

          <div className="min-w-[102px] h-full flex items-center gap-2">

            <div className="relative w-[70px] h-[36px] cursor-pointer group">
              <div
                className={`relative w-[70px] h-[36px]  ${modoDark == 1
                  ? "bg-[#F8F9F9]"
                  : "bg-[#212222] "
                  } pl-px pr-[21px] py-px border border-[#EAEAEA]   rounded-[99px] justify-end items-center gap-2.5 inline-flex cursor-pointer`}
                onClick={() => toggleState()}
              >
                <motion.div
                  className={`absolute ${modoDark == 1 ? "right-0 bg-[#FFF]" : "left-0 bg-[#2D2E2F]"
                    } right-0 w-[28px] h-[28px] rounded-full bg-[#FF] flex justify-center items-center `}
                  layout
                // transition={spring}
                >

                  {modoDark == 1 ?

                    <span>

                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_2473_63616)">
                          <path d="M10.0007 14.5832C7.46935 14.5832 5.41732 12.5311 5.41732 9.99984C5.41732 7.46853 7.46935 5.4165 10.0007 5.4165C12.532 5.4165 14.584 7.46853 14.584 9.99984C14.584 12.5311 12.532 14.5832 10.0007 14.5832Z" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" />
                          <path d="M10 3.33317V1.6665" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M14.7176 5.2834L15.8926 4.1084" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M16.6673 10H18.334" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M14.7176 14.7168L15.8926 15.8918" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M10 16.6665V18.3332" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M5.2832 14.7168L4.1082 15.8918" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M3.33398 10H1.66732" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                          <path d="M5.2832 5.2834L4.1082 4.1084" stroke="#818282" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2473_63616">
                            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    :
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                        <path d="M24.8755 18.275C24.2759 19.767 23.3157 21.087 22.0808 22.1169C20.846 23.1469 19.375 23.8545 17.7996 24.1765C16.2242 24.4986 14.5935 24.4249 13.0535 23.9622C11.5136 23.4995 10.1124 22.6622 8.97534 21.5252C7.83833 20.3882 7.00099 18.9869 6.53828 17.447C6.07558 15.907 6.00194 14.2763 6.32397 12.7009C6.646 11.1255 7.35365 9.65456 8.38356 8.41969C9.41347 7.18483 10.7335 6.22462 12.2255 5.625C11.5148 7.39345 11.3394 9.33176 11.7211 11.1991C12.1028 13.0664 13.0248 14.7804 14.3724 16.1281C15.7201 17.4758 17.4341 18.3977 19.3014 18.7794C21.1687 19.1611 23.1071 18.9857 24.8755 18.275Z" stroke="#C0C0C1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </span>

                  }
                </motion.div>
              </div>
              {/* Tooltip */}

              <div className={`absolute h-[40px] text-sm rounded-lg  -top-12 left-1/2 transform -translate-x-1/2 px-3 py-1 flex items-center justify-center rounded  opacity-0 group-hover:opacity-100 ${modoDark == 1
                ? "w-[10.5rem] bg-[--bacground-component-module-light] shadow-lg inset-shadow-sm border"
                : "w-[11rem] text-white bg-[--background-component-module-dark] border "
                } ${i18n.language === "pt" ? "w-[14rem]" : ""}
                 transition-opacity duration-300`}
              >
                {modoDark == 1 ? (
                  <>
                    <span className="mr-1 font-normal">{t("Vank.Share.Sidebar.Link.ModeColor")}</span>
                    <span className="font-semibold">{t("Vank.Share.Sidebar.Link.light")}</span>
                  </>
                ) : (
                  <>
                    <span className="mr-1 font-normal">{t("Vank.Share.Sidebar.Link.ModeColor")}</span>
                    <span className="font-semibold" >{t("Vank.Share.Sidebar.Link.Dark")}</span>
                  </>
                )}
              </div>

            </div>

            <CustomLenguaje />
          </div>
        </div>
              
      </div>

      {isImgPreview && (
  <motion.div
    initial={{ scale: 0.95, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.95, opacity: 0 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
    className={`${isMobile ? "hidden" : "block"} fixed inset-0 z-50 flex items-center justify-center bg-black/50 dark:bg-black/70 backdrop-blur-md shadow-lg`}
  >
    
      <UpdatePhoto
        imgSrc={imgSrc}
        setImgSrc={setImgSrc}
        setProfileModalOpen={setProfileModalOpen}
        onSelectFile={onSelectFile}
        setIsImgPreview={setIsImgPreview}
        error={error}
        setError={setError}
      />
    
  </motion.div>
)}


      <div
        className={`2xl:hidden xl:hidden lg:hidden md:hidden tablet:hidden mobile:flex ${isImgPreview ? "mobile:hidden" : "flex"
          }`}
      >
        <CustomModal isOpen={isOpenSend}>
          <motion.div
            className="w-full rounded-t-[16px] z-50 absolute bottom-0 flex flex-col items-center bg-[--color-lightest-white]  2xl:h-[635px] xl:h-[500px] lg:h-[335px] tablet:h-[335px] mobile:h-auto mobile:max-h-[90%] dark:bg-[#1A1A1B] shadow"
            initial={{ translateY: "100%" }}
            animate={{ translateY: "0%" }}
            exit={{ translateY: "100%" }}
            transition={{ duration: 0.3 }}
          >
            <div className="w-full  h-[60px] flex justify-between items-center py-4 px-6">
              <span className="text-[16px] text-[--color-darkest-grey] dark:text-[#FFFFFF]">
                {t("Vank.Share.ProfileModal.Profile")}
              </span>

              <button
                className="min-w-[28px] min-h-[28px] border border-[#F1F1F1] dark:border-[#212222] rounded-full flex items-center justify-center"
                onClick={() => setIsOpenSend(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5 5L13 13"
                    stroke="#818282"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 5L5 13"
                    stroke="#818282"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div className="w-full h-auto overflow-auto bg-overflow">
              <div className="w-full h-auto">
                <div className="w-full  bg-[--color-lighter-white] dark:bg-[#2D2E2F] flex flex-col justify-center items-center">
                  <div className="w-full  flex flex-col items-center  py-[1.5rem] gap-[1.5rem]">
                    <div className="w-[122px] h-[122px] rounded-full cursor-pointer relative group ">
                      {!isDeleteImg && (
                        <img
                          src={users?.AVATAR}
                          alt="AVATAR"
                          className="w-full h-full rounded-full object-cover cursor-pointer"
                        />
                      )}
                      {isLoadingImg && (
                        <div className="absolute top-0 left-0 bg-[#131313]/60 w-full h-full rounded-full flex items-center justify-center">
                          <div className="w-[40px] h-[40px] border-4 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-[var(--branding-brand-yellow-light)] rounded-full" />
                        </div>
                      )
                      }
                      <button
                        disabled={isDeleteImg}
                        className={`absolute bottom-2 right-2 min-w-[24px] min-h-[24px] rounded-full ${isImgOption ? "flex" : "hidden"
                          } group-hover:flex bg-[#FFFFFF] dark:bg-[#2D2E2F] justify-center items-center transition-all duration-500`}
                        onClick={() => setIsImgOption(!isImgOption)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10.4167 2.25H4.58333C3.29467 2.25 2.25 3.29467 2.25 4.58333V10.4167C2.25 11.7053 3.29467 12.75 4.58333 12.75H10.4167C11.7053 12.75 12.75 11.7053 12.75 10.4167V4.58333C12.75 3.29467 11.7053 2.25 10.4167 2.25Z"
                            stroke={theme === "dark" ? "#FFFFFF" : "#2D2E2F"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.25 10.4167L4.34417 8.3225C4.56276 8.10521 4.85845 7.98324 5.16667 7.98324C5.47488 7.98324 5.77058 8.10521 5.98917 8.3225L7.26083 9.59417C7.47942 9.81146 7.77512 9.93342 8.08333 9.93342C8.39155 9.93342 8.68724 9.81146 8.90583 9.59417L12.75 5.75"
                            stroke={theme === "dark" ? "#FFFFFF" : "#2D2E2F"}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      {isImgOption && (
                        <>
                          <div
                            ref={dropdownRef}
                            className="absolute bottom-0 -left-2 min-w-[87px] rounded-[4px] flex flex-col bg-[#FFFFFF] dark:bg-[#2D2E2F] justify-center items-center transition-all duration-500 shadow"
                            onClick={handleOptionClick} // Detener propagación

                          >
                            <label
                              htmlFor="imageUpload"
                              className="w-full py-1 px-2 flex justify-center items-center text-[#000000] dark:text-[#FFFFFF] text-[10px]"
                            // onClick={() => handleDeleteImg}
                            >
                              {t("Vank.Setting.Profile.text14")}
                            </label>
                            <button
                              disabled={isDeleteImg}
                              className="w-full py-1 px-2 flex justify-center items-center text-[#000000] dark:text-[#FFFFFF] text-[10px]"
                              onClick={(e) => {
                                e.stopPropagation(); // Detener propagación
                                handleDeleteImg();
                              }}
                            >
                              {t("Vank.Setting.Profile.text15")}
                            </button>
                            <input
                              id="imageUpload"
                              type="file"
                              accept="image/*"
                              onChange={(e) => onSelectFile(e)}
                              className="hidden"
                            />
                          </div>
                        </>

                      )}
                    </div>
                    <div className="w-full gap-[0.2rem] flex flex-col items-center">
                      <span className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-medium leading-none">
                        {capitalizeName(
                          t("Vank.Share.ProfileModalMobile.Name", {
                            name: users?.NAME,
                          })
                        )}
                      </span>
                      <span className="text-[--content-main-grey-light] dark:text-[#C0C0C1] text-sm font-normal leading-tight">
                        {t("Vank.Share.ProfileModalMobile.Email", {
                          Email: users?.EMAIL,
                        })}
                      </span>
                      <div className="min-w-[147px] h-[17px] justify-center items-center gap-1 inline-flex">
                        <div className="text-[#818282] dark:text-[#C0C0C1] text-sm font-normal">
                          Vank ID
                        </div>
                        <div
                          className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-sm font-normal"
                          ref={spanRef}
                        >
                          {users?.VANKID}
                        </div>
                        {users?.VANKID && (
                          <div className="w-[16px] h-[16px] flex justify-center items-center relative">
                            <div
                              className="w-[16x] h-[16px]"
                              onClick={handleCopy}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M4 7.83398C4 5.94836 4 5.00556 4.58579 4.41977C5.17157 3.83398 6.11438 3.83398 8 3.83398H10C11.8856 3.83398 12.8284 3.83398 13.4142 4.41977C14 5.00556 14 5.94836 14 7.83398V11.1673C14 13.0529 14 13.9957 13.4142 14.5815C12.8284 15.1673 11.8856 15.1673 10 15.1673H8C6.11438 15.1673 5.17157 15.1673 4.58579 14.5815C4 13.9957 4 13.0529 4 11.1673V7.83398Z"
                                  stroke="#818282"
                                  strokeWidth="1.5"
                                />
                                <path
                                  opacity="0.5"
                                  d="M4 13.1673C2.89543 13.1673 2 12.2719 2 11.1673V7.16732C2 4.65316 2 3.39608 2.78105 2.61503C3.5621 1.83398 4.81917 1.83398 7.33333 1.83398H10C11.1046 1.83398 12 2.72942 12 3.83398"
                                  stroke="#818282"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                            {copied && (
                              <motion.div
                                initial={{ opacity: 0, x: -10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -10 }}
                                className="absolute -top-1 2xl:left-6 xl:left-6 lg:left-6 tablet:left-6 mobile-375:left-6 min-[360px]:left-6 mobile:left-[1.3rem] bg-[#FFFFFF] border border-[#F1F1F1] text-[#2D2E2F] text-sm rounded-[4px] 2xl:p-[4px] xl:p-[4px] lg:p-[4px] tablet:p-[4px] mobile-375:p-[4px] min-[360px]:p-[4px] mobile:p-[1px] whitespace-nowrap shadow-sm"
                              >
                                {t("Vank.Setting.Profile.text19")}
                              </motion.div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full rounded-xl flex-col justify-center items-center gap-2 inline-flex">
                  <div className="min-h-[51px] flex justify-center items-center cursor-pointer">
                    <span className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                      {t("Vank.Share.ProfileModalMobile.Link.EditProfile")}
                    </span>
                  </div>
                  <span className="w-full h-px bg-[#F1F1F1] dark:bg-[#2D2E2F]"></span>
                  <div className="min-h-[51px] flex justify-center items-center cursor-pointer">
                    <span className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                      {t(
                        "Vank.Share.ProfileModalMobile.Link.SettingsAndPrivacy"
                      )}
                    </span>
                  </div>
                  <span className="w-full h-px bg-[#F1F1F1] dark:bg-[#2D2E2F]"></span>
                  <div className="min-h-[51px] flex justify-center items-center cursor-pointer">
                    <span className="text-[--color-darkest-grey] dark:text-[#FFFFFF] text-base font-normal hover:font-semibold ">
                      {t("Vank.Share.ProfileModalMobile.Link.ShareApp")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full h-[70px] flex justify-center items-center">
                <div className="flex mobile-375:py-[10px] mobile:py-[5px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] border border-[#2D2E2F] dark:border-[#FFFFFF] dark:text-[#FFFFFF] text-[#2D2E2F]">
                  {t("Vank.Share.ProfileModalMobile.text1")}
                </div>
              </div>
            </div>
          </motion.div>
        </CustomModal>
     

   
      </div>


      {isImgPreview && (
        <motion.div
          initial={isMobile ? { y: "100%" } : { scale: 0.9, opacity: 0 }}
          animate={isMobile ? { y: "0%" } : { scale: 1, opacity: 1 }}
          exit={isMobile ? { y: "100%" } : { scale: 0.9, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`${isMobile ? "fixed z-50 rounded-t-[16px] block" : "fixed z-50 bg-transparent rounded-t-lg hidden"}   inset-0 bg-transaparent dark:bg-[#1A1A1B]  flex flex-col items-center justify-center  shadow-lg`}
        >
          <CustomModal isOpen={isImgPreview} >
            <UpdatePhoto
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              setProfileModalOpen={setProfileModalOpen}
              onSelectFile={onSelectFile}
              setIsImgPreview={setIsImgPreview}
              error={error}
              setError={setError}
            />
          </CustomModal>

        </motion.div>
      )}


      <CustomModal isOpen={viewModalExit}>
        <div className="bg-[var(--bacground-component-module-light)] w-[401px] h-[190px] text-lg font-medium  dark:bg-[var(--background-component-module-dark)] p-4 rounded-2xl flex flex-col items-center gap-6">

          <div className="flex space-x-2 ">
            <h5 className="dark:text-[var(--content-main-black-dark)]">{t("modalLogout.title")}</h5>
            <IconLogout
              className=" transform scale-x-[-1] cursor-pointer text-[var(--content-main-black-light)] dark:text-[--content-main-black-dark] "
              onClick={() => handleLogOutWithLoading()}
            />
          </div>

          <div>
            <p className="w-[340px] text-center dark:text-[var(--content-main-gray-dark)]">{t("modalLogout.text")}</p>
          </div>

          <div className="flex gap-2 w-full justify-center items-center">
            {isLoading ?
              (
                <div className="flex justify-center items-center ">
                  <div className="h-6 flex  items-center">
                    <CustomLoading />
                  </div>
                </div>)
              : (
                <>
                  <CustomButton onclick={() => setviewModalExit(false)} className="bg-[#FFF133] w-full h-[28px] p-[6 6] rounded-full text-sm font-medium hover:bg-[--content-main-black-light] hover:text-[#FFF133] dark:hover:bg-[var(--content-main-black-dark)] dark:hover:text-[var(--content-main-white-dark)]" label={t("modalLogout.btnCancel")} />
                  <CustomButton onclick={() => handleLogOutWithLoading()} className="bg-[transparent] w-full h-[28px] p-[6 6] text-sm border font-medium border-[#2D2E2F] dark:border-[--content-main-black-dark] dark:text-[var(--content-main-gray-dark)] rounded-full  " label={t("modalLogout.btnExit")} />
                </>
              )}

          </div>

        </div>
      </CustomModal>

    

    </>
  );
};

export default SidebarProfile;
