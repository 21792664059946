import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import errorImg from "@assets/errorImg.png";
import { ProfileUser } from "../../../Home/state";
import { useRecoilState } from "recoil";
import { servicesSetting } from "../../services/servicesSetting";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { default as toastHook } from "@/hooks/useToast";
import { IconError } from "@/apps/Shared/ToastContainer/Icons";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 300;

const UpdatePhoto = ({
  imgSrc,
  setImgSrc,
  setProfileModalOpen,
  onSelectFile,
  setIsImgPreview,
  error,
  setError,
}: {
  imgSrc: any;
  setImgSrc: any;
  setProfileModalOpen: any;
  onSelectFile: any;
  setIsImgPreview: any;
  error: any;
  setError: any;
}) => {
  const [t, i18n] = useTranslation("global");
  const [users, setUsers] = useRecoilState<any>(ProfileUser);

  const [isPreview, setIsPreview] = useState(true); // Estado para el modal de cambio de idioma
  const [isUpdateImg, setIsUpdateImg] = useState(false); // Estado para el modal de cambio de idioma
  const [crop, setCrop] = useState(null);
  const [isExistImg, setIsExistImg] = useState(null);

  // pruebas
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedSizeMB, setCroppedSizeMB] = useState(null);
  const maxSizeInMB = 5; // Tamaño máximo permitido en MB

  // ---

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );

  const [isMobileExit, setIsMobileExit] = useState(false);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const services = new servicesSetting();

  const toast = toastHook();

  const onImageLoad = (e) => {
    setIsPreview(true);
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
    setIsPreview(false);
  };

  // Actualizar vista previa
  const updatePreviewCanvas = (image, crop) => {
    const canvas = previewCanvasRef.current;
    // console.log(canvas);

    if (!canvas || !crop || !crop.width || !crop.height) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    // Ajustar la escala del canvas para mantener la calidad de la imagen
    const pixelRatio = window.devicePixelRatio || 1;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high"; // Mejorar la calidad del suavizado

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Calcular el tamaño del recorte en MB
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const sizeInMB = blob.size / 1024 / 1024; // Tamaño en MB como número
          // console.log(sizeInMB);

          // Comparamos sizeInMB directamente como un número
          if (sizeInMB > maxSizeInMB) {
            setError(
              `${t("Vank.Setting.Profile.text2")} 5 ${t(
                "Vank.Setting.Profile.mb"
              )}.`
            );
            setImgSrc({
              name: "",
              format: "",
              size: "",
              src: "",
            });
            return;
          }
          setCroppedSizeMB(sizeInMB.toFixed(2)); // Si necesitas guardar en formato string
          // console.log("Tamaño del recorte: ", sizeInMB.toFixed(2), "MB");
        }
      },
      "image/jpeg",
      0.9
    ); // Tipo de archivo y calidad
  };

  useEffect(() => {
    if (completedCrop && imgRef.current) {
      updatePreviewCanvas(imgRef.current, completedCrop);
    } else if (crop && imgRef.current) {
      updatePreviewCanvas(imgRef.current, crop);
      // alert("hdasdals");
    }
  }, [completedCrop, imgSrc, crop]);

  useEffect(() => {
    // Función para verificar si el dispositivo es móvil
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    // Verifica el tamaño de la ventana al cargar el componente
    checkMobile();

    // Añade un listener para manejar el cambio de tamaño de la ventana
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile); // Limpia el listener
    };
  }, []);

  const handleUpdateImg = async () => {
    setIsUpdateImg(true);
    const canvas = previewCanvasRef.current;
    if (!canvas) {
      toast.error(t("Vank.Setting.Profile.text22"), {
        position: "top-right", // Obligatorio
        autoClose: 5000, // Opcional
        iconError: <IconError />, // Opcional
        hideProgressBar: true, // Opcional
        isHovered: true,
      });
      setIsUpdateImg(false);
      setIsImgPreview(false);
      setImgSrc({
        name: "",
        format: "",
        size: "",
        src: "",
        file: null,
      });
      setError("");
      return;
    }
    // Crear un blob a partir del canvas con alta calidad de compresión sin aumentar el tamaño
    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          toast.error(t("Vank.Setting.Profile.text22"), {
            position: "top-right", // Obligatorio
            autoClose: 5000, // Opcional
            iconError: <IconError />, // Opcional
            hideProgressBar: true, // Opcional
            isHovered: true,
          });
          setIsUpdateImg(false);
          setIsImgPreview(false);
          setImgSrc({
            name: "",
            format: "",
            size: "",
            src: "",
            file: null,
          });
          setError("");
          return;
        }

        try {
          const formData = new FormData();
          // Generar cuatro números aleatorios
          const randomNumbers = Math.floor(1000 + Math.random() * 9000); // Esto generará un número entre 1000 y 9999
          const newName = `${imgSrc.name}_${randomNumbers}`; // Concatenar los números al nombre
          formData.append("AVATARIMAGE", blob, newName);
          const res = await services.updateAvatarProfile(formData);
          const hasUser = {
            ...users,
            AVATAR: res.Url,
          };

          await new IndexedDBLocalDropdownRepository().save({
            key: "user",
            data: hasUser,
          });
          setUsers(hasUser);
          setIsUpdateImg(false);
          setIsExistImg(true);
          if (isMobile) {
            setTimeout(() => {
              setIsMobileExit(true);
              setTimeout(() => {
                setIsImgPreview(false);
                setImgSrc({
                  name: "",
                  format: "",
                  size: "",
                  src: "",
                  file: null,
                });
                setError("");
                setIsExistImg(false);
                setIsMobileExit(false);
              }, 300);
            }, 300);
            return;
          }
          setTimeout(() => {
            setIsImgPreview(false);
            setImgSrc({
              name: "",
              format: "",
              size: "",
              src: "",
              file: null,
            });
            setError("");
            setIsExistImg(false);
          }, 500);
        } catch (error) {
          setIsUpdateImg(false);
          if (error?.data?.statusCode === 404) {
          
            toast.error(t("Vank.Setting.Profile.text20"), {
              position: "top-right", // Obligatorio
              autoClose: 5000, // Opcional
              iconError: <IconError />, // Opcional
              hideProgressBar: true, // Opcional
              isHovered: true,
            });
            return;
          }
          if (error?.data?.statusCode === 422) {
            // alert(error.data.body.response.error);
           

            toast.error(t("Vank.Setting.Profile.text21"), {
              position: "top-right", // Obligatorio
              autoClose: 5000, // Opcional
              iconError: <IconError />, // Opcional
              hideProgressBar: true, // Opcional
              isHovered: true,
            });
            return;
          }
          toast.error(t("Vank.Share.text15"), {
            position: "top-right", // Obligatorio
            autoClose: 5000, // Opcional
            iconError: <IconError />, // Opcional
            hideProgressBar: true, // Opcional
            isHovered: true,
          });
        }
      },
      "image/jpeg", // Tipo de archivo (usar 'image/jpeg' o 'image/png')
      0.9 // Ajustar la calidad de compresión entre 0.8 y 1.0
    );
  };

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }} // Solo en móvil comienza fuera de la pantalla
      animate={
        isMobile && isMobileExit
          ? { y: "100%", opacity: 1 }
          : { y: 0, opacity: 1 }
      } // Siempre termina en su posición original
      exit={
        isMobile && isMobileExit
          ? { y: "100%", opacity: 0 }
          : { y: 0, opacity: 0 }
      } // Sale hacia abajo solo en móvil
      transition={{ duration: 0.3 }} // Duración y suavizado de la transición
      className=" flex flex-col justify-start items-center bg-[#FFF] dark:bg-[#1A1A1B] 
                2xl:w-[560px] xl:w-[560px] lg:w-[560px] tablet:w-[560px] mobile:w-full
                2xl:relative xl:relative lg:relative tablet:relative mobile:absolute bottom-0 
                2xl:rounded-[16px]  xl:rounded-[16px]  lg:rounded-[16px]  tablet:rounded-[16px]  mobile:rounded-t-[16px] 
                2xl:min-h-[608px] xl:min-h-[608px] lg:min-h-[608px] tablet:min-h-[608px] mobile:h-auto 

    "
    >
      <div className="w-full flex justify-between items-center px-4 2xl:h-[80px]  xl:h-[80px]  lg:h-[80px]  tablet:h-[80px]  mobile:h-auto   2xl:py-6 xl:py-6 lg:py-6 tablet:py-6 mobile:py-2">
        <span className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] tablet:text-[18px] mobile:text-[16px] text-[#2D2E2F] dark:text-[#FFFFFF] leading-[21.78px] font-semibold">
          {t("Vank.Setting.Profile.text3")}
        </span>
        <button
          className="w-[32px] h-[32px] rounded-[500px] justify-center items-center gap-2.5 flex hover:bg-[--color-darkest-grey] transition-all duration-300"
          onClick={(event) => {
            setIsImgPreview(false)
            setProfileModalOpen(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
          >
            <path
              d="M0.83546 0.83546C1.05032 0.620665 1.34169 0.5 1.6455 0.5C1.94932 0.5 2.24069 0.620665 2.45555 0.83546L6.50692 4.88683L10.5583 0.83546C10.7744 0.626752 11.0638 0.511267 11.3642 0.513878C11.6646 0.516488 11.952 0.636985 12.1644 0.849417C12.3768 1.06185 12.4973 1.34922 12.5 1.64963C12.5026 1.95004 12.3871 2.23946 12.1784 2.45555L8.12701 6.50692L12.1784 10.5583C12.3871 10.7744 12.5026 11.0638 12.5 11.3642C12.4973 11.6646 12.3768 11.952 12.1644 12.1644C11.952 12.3768 11.6646 12.4973 11.3642 12.5C11.0638 12.5026 10.7744 12.3871 10.5583 12.1784L6.50692 8.12701L2.45555 12.1784C2.23946 12.3871 1.95004 12.5026 1.64963 12.5C1.34922 12.4973 1.06185 12.3768 0.849417 12.1644C0.636985 11.952 0.516488 11.6646 0.513878 11.3642C0.511267 11.0638 0.626752 10.7744 0.83546 10.5583L4.88683 6.50692L0.83546 2.45555C0.620665 2.24069 0.5 1.94932 0.5 1.6455C0.5 1.34169 0.620665 1.05032 0.83546 0.83546Z"
              fill="#858585"
            />
          </svg>
        </button>
      </div>
      <div className="w-full h-full flex flex-col justify-center items-start gap-6 px-4 pb-6">
        {error ? (
          <div className="relative w-full 2xl:h-[440px] xl:h-[440px] lg:h-[440px] tablet:h-[440px] mobile-375:h-[350px] min-[360px]:h-[350px] mobile:h-[250px] p-4 flex justify-center items-center bg-[#F8F9F9] dark:bg-[#2D2E2F] rounded-[16px]">
            <div className="w-full flex flex-col justify-center items-center gap-4 ">
              <img
                src={errorImg}
                alt=""
                className="w-[92px] 2xl:h-[92px] xl:h-[92px] lg:h-[92px] tablet:h-[92px] mobile:h-auto object-cover"
              />
              <div className="flex flex-col items-center justify-center gap-4">
                <h2 className="2xl:w-[360px] xl:w-[360px] lg:w-[360px] tablet:w-[360px] mobile:w-full 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px] mobile:text-[14px] text-[#2D2E2F] text-center font-[600] leading-[19.36px]">
                  {t("Vank.Setting.Profile.text4")}
                </h2>
                <div className="h-[42px] flex flex-col justify-center items-center gap-2">
                  <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] text-[#818282] font-normal leading-[19.36px]">
                    {t("Vank.Setting.Profile.text5")}{" "}
                    <span className="font-[600]">
                      {t("Vank.Setting.Profile.text6")}
                    </span>
                  </p>
                  <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] text-[#818282] font-normal leading-[19.36px]">
                    {t("Vank.Setting.Profile.text7")}{" "}
                    <span className="font-[600]">
                      5{t("Vank.Setting.Profile.mb")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative w-full 2xl:h-[440px] xl:h-[440px] lg:h-[440px] tablet:h-[440px] mobile-375:h-[350px] min-[360px]:h-[350px] mobile:h-[250px] p-4 flex justify-center items-center bg-[#F8F9F9] dark:bg-[#2D2E2F] rounded-[16px] overflow-hidden">
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(newCrop) => setCompletedCrop(newCrop)}
              circularCrop
              keepSelection
              aspect={ASPECT_RATIO}
              minWidth={100}
              maxWidth={MIN_DIMENSION}
            >
              <img
                ref={imgRef}
                src={imgSrc.src}
                alt="Upload"
                className="max-w-[300px] 2xl:h-[440px] xl:h-[440px] lg:h-[440px] tablet:h-[440px] mobile-375:h-[350px] min-[360px]:h-[350px] mobile:h-[250px] object-cover overflow-hidden z-50"
                onLoad={onImageLoad}
              />
            </ReactCrop>
         
            {isPreview && (
              <div className="absolute top-0 left-0 bg-[#F8F9F9] dark:bg-[#2D2E2F] rounded-[16px] w-full h-full flex justify-center items-center transition-all duration-300 z-50">
                <div className="w-full flex justify-center items-center flex-row gap-2 ">
                  <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce"></div>
                  <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce [animation-delay:-.5s]"></div>
                  <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce [animation-delay:-.5s]"></div>
                </div>
              </div>
            )}
          </div>
        )}

        {crop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              maxWidth: "150px",
              borderRadius: "50%",
              display: "none",
            }}
          />
        )}

        {!error && (
          <span className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] text-[#818282] dark:text-[#FFFFFF] font-normal">
            {t("Vank.Setting.Profile.text8")} {imgSrc.name} , {croppedSizeMB}{" "}
            {t("Vank.Setting.Profile.mb")}
          </span>
        )}

        <div className="w-full min-h-full flex justify-center items-center gap-4">
          {isUpdateImg ? (
            <div className="max-w-[93px] h-[28px] rounded-[500px] flex justify-center items-center transition-all duration-300 bg-transparent">
              <div className="w-full flex justify-center items-center flex-row gap-2 ">
                <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce"></div>
                <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce [animation-delay:-.5s]"></div>
                <div className="w-[10px] h-[10px] rounded-full bg-[#FFED00] animate-bounce [animation-delay:-.5s]"></div>
              </div>
            </div>
          ) : isExistImg ? (
            <div className="w-full h-[52px] flex items-center gap-2 py-3 px-4 bg-[#FFF3DB] rounded-[12px]">
              <div className="min-w-[24px] min-h-[24px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M12 8.00098V14.301M12.001 17.001H12.0001M21 12.501C21 17.4715 16.9706 21.501 12 21.501C7.02944 21.501 3 17.4715 3 12.501C3 7.53041 7.02944 3.50098 12 3.50098C16.9706 3.50098 21 7.53041 21 12.501Z"
                    stroke="#2D2E2F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="w-full text-[#2D2E2F] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal leading-none text-left">
                {t("Vank.Setting.Profile.text9")}
              </span>
              <div className="min-w-[24px] min-h-[24px] flex justify-center items-center bg-[#B7EB8F] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M6 12.8709L9.76 16.6209L18 8.38086"
                    stroke="#2D2E2F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <>
              <button
                className="w-full min-h-[40px] px-6 py-[6px] flex items-center justify-center bg-transparent border border-[#2D2E2F] text-[#2D2E2F] dark:border-[#FFFFFF] dark:text-[#FFFFFF]  disabled:hover:border disabled:text-opacity-[0.5] disabled:border-opacity-[0.5] rounded-[999px] cursor-pointer transition-all duration-200"
                onClick={() => {
                  if (isMobile) {
                    setTimeout(() => {
                      setIsMobileExit(true);
                      setTimeout(() => {
                        setIsImgPreview(false);
                        setImgSrc({
                          name: "",
                          format: "",
                          size: "",
                          src: "",
                          file: null,
                        });
                        setError("");
                        setIsMobileExit(false);
                      }, 300);
                    }, 50);
                    return;
                  }
                  setIsImgPreview(false);
                  setError("");
                  setImgSrc({
                    name: "",
                    format: "",
                    size: "",
                    src: "",
                    file: "",
                  });
                }}
              >
                <span className="text-base leading-[16.94px] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px]">
                  {t("Vank.Setting.Profile.text10")}
                </span>
              </button>

              {!error ? (
                <button
                  type="button"
                  // disabled={isLoadingImg}
                  className="w-full min-h-[40px] px-6 py-[6px] rounded-[500px] bg-[--color-bright-yellow] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F]  disabled:bg-[#EAEAEA] text-[--color-darkest-grey] disabled:text-[#818282] hover:text-[--color-dark-yellow] disabled:border-none border-[#C6C8CA] justify-center items-center gap-2.5 inline-flex disabled:cursor-default hover:bg-[#14181F] transition-all duration-300"
                  onClick={handleUpdateImg}
                >
                  <span className="text-base leading-[16.94px] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px]">
                    {t("Vank.Setting.Profile.text11")}
                  </span>
                </button>
              ) : (
                <div className="w-full">
                  <label
                    // aria-disabled={isLoadingImg}
                    htmlFor="imageUpload"
                    className="2xl:flex  xl:flex lg:flex tablet:flex mobile:hidden w-full min-h-[40px] px-6 py-[6px] rounded-[500px] bg-[--color-bright-yellow] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F]  disabled:bg-[#EAEAEA] text-[--color-darkest-grey] disabled:text-[#818282] text-base leading-[16.94px] hover:text-[--color-dark-yellow] disabled:border-none border-[#C6C8CA] justify-center items-center gap-2.5 inline-flex disabled:cursor-default hover:bg-[#14181F] transition-all duration-300"
                  >
                    {t("Vank.Setting.Profile.text12")}
                  </label>

                  {/* mobile */}
                  <label
                    // aria-disabled={isLoadingImg}
                    htmlFor="imageUpload"
                    className="2xl:hidden 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] xl:hidden lg:hidden tablet:hidden mobile:flex w-full min-h-[40px] px-6 py-[6px] rounded-[500px] bg-[--color-bright-yellow] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F]  disabled:bg-[#EAEAEA] text-[--color-darkest-grey] disabled:text-[#818282] text-base leading-[16.94px] hover:text-[--color-dark-yellow] disabled:border-none border-[#C6C8CA] justify-center items-center gap-2.5 inline-flex disabled:cursor-default hover:bg-[#14181F] transition-all duration-300"
                  >
                    {t("Vank.Setting.Profile.text13")}
                  </label>
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    className="hidden"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default UpdatePhoto;
