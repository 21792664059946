import React, {
    createContext,
    useContext,
    useEffect,
    ReactNode,
    useState,
    useRef,
  } from "react";
  import { SocketConfig } from "./types";
  import webSocketManager from "./SocketManager";
  
  // Define el tipo del contexto
  interface SocketContextType {
    webSocketManager: typeof webSocketManager;
    connectionDetails: { [key: string]: { connected: boolean; error?: string } }; // Estado con detalles de las conexiones
  }
  // Inicializa el contexto con un valor por defecto
  const SocketContext = createContext<SocketContextType | undefined>(undefined);
  // Hook para consumir el contexto
  export const useSocket = (serviceName: string) => {
    const context = useContext(SocketContext);
    if (!context) {
      throw new Error("useSocket must be used within a SocketProvider");
    }
    const { webSocketManager, connectionDetails } = context;
    const service = connectionDetails[serviceName];
    // Verifica si el servicio existe en el estado de las conexiones
    if (!service) return;
    webSocketManager.services = serviceName;
    return {
      socket: webSocketManager.connections[serviceName] || null, // Devuelve el socket correspondiente
      method: webSocketManager, // Devuelve el manager completo
      connected: connectionDetails[serviceName],
    };
  };
  // Define el tipo de las props del proveedor
  interface SocketProviderProps {
    children: ReactNode;
    socketConfigs: SocketConfig[];
  }
  export const SocketProvider: React.FC<SocketProviderProps> = ({
    children,
    socketConfigs,
  }) => {
    const [connectionDetails, setConnectionDetails] = useState<{
      [key: string]: { connected: boolean; error?: string };
    }>({});
    const handleWebSocketMessage = (id: string, event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        const { keyPublic, clientId } = data.body;
        if (
          ["connectionsStatus", "connectionsStatusVankOrchestrator"].includes(
            data?.event
          )
        ) {
          webSocketManager.saveConnectionData(id, { keyPublic, clientId });
          setConnectionDetails((prev) => ({
            ...prev,
            [id]: { connected: true },
          }));
        }
      } catch (error) {
        console.error(`[${id}] Error processing WebSocket message:`, error);
      }
    };
    useEffect(() => {
      // Crear las conexiones
      socketConfigs
        .filter((item) => item.visible === true)
        .forEach(({ id, url }) => {
          if (!connectionDetails[id]?.connected) {
            webSocketManager
              .createWebSocket(id, url)
              .then(() => {
                const connection = webSocketManager.connections[id];
                connection.onmessage = (event) =>
                  handleWebSocketMessage(id, event);
              })
              .catch((error) =>
                setConnectionDetails((prev) => ({
                  ...prev,
                  [id]: { connected: false, error: error?.message },
                }))
              );
          }
        });
      // Limpiar las conexiones al desmontar
      //  return () => {
      //   // Cerrar todas las conexiones WebSocket cuando el componente se desmonte
      //   webSocketManager.closeAllWebSockets();
      // };
    }, [socketConfigs]);
    // Verificar si todos los servicios visibles están conectados
    const allServicesConnected = socketConfigs
      .filter((item) => item.visible === true)
      .every(({ id }) => connectionDetails[id]?.connected === true);
    if (!allServicesConnected) {
      return ;
    }
    return (
      <SocketContext.Provider value={{ webSocketManager, connectionDetails }}>
        {children}
      </SocketContext.Provider>
    );
  };