import { motion } from "framer-motion";

const IconLoaderDots = ({ className }: { className?: string }) => {
  const dotVariants = {
    initial: { scale: 1 },
    animate: { 
        scale: [1, 1.5, 1], // Crece a 1.5x y regresa
      opacity: [0.5, 1, 0.5], // Cambia la opacidad para dar dinamismo
    },
  };

  return (
    <svg
      className={`${className || "text-[--content-main-black-light]"}`}
      width="20"
      height="8"
      viewBox="0 0 20 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Loader-dots">
        <motion.rect
          id="Rectangle 15"
          y="2"
          width="4"
          height="4"
          rx="2"
          fill="currentColor"
          variants={dotVariants}
          initial="initial"
          animate="animate"
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: 0, // Sin retraso para el primer punto
          }}
        />
        <motion.rect
          id="Rectangle 16"
          x="8"
          y="2"
          width="4"
          height="4"
          rx="2"
          fill="currentColor"
          variants={dotVariants}
          initial="initial"
          animate="animate"
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: 0.2, // Retraso para el segundo punto
          }}
        />
        <motion.rect
          id="Rectangle 17"
          x="16"
          y="2"
          width="4"
          height="4"
          rx="2"
          fill="currentColor"
          variants={dotVariants}
          initial="initial"
          animate="animate"
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: 0.4, // Retraso para el tercer punto
          }}
        />
      </g>
    </svg>
  );
};

export default IconLoaderDots;
