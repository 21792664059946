import { backStyle } from "@/apps/Shared/CustomModal/CustomModal";
import { IconFlagCol, IconFlagPt, IconFlagUsd } from "@/assets/IconV2/IconsSvg";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CustomLenguaje = () => {
  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const languageMap = [
    { id: 1, name: t("Vank.Setting.Languages.Modal.Spanish"), lng: "es" },
    { id: 2, name: t("Vank.Setting.Languages.Modal.English"), lng: "en" },
    { id: 3, name: t("Vank.Setting.Languages.Modal.Portuguese"), lng: "pt" },
  ];

  return (
    <div
      className="relative min-w-[62px] h-[40px] flex items-center justify-center gap-[2px] p-2 border border-transparent hover:border-[--background-disabled-button] dark:hover:border-[--content-main-gray-light] bg-transparent hover:dark:bg-[--bacground-sidebar-dark] hover:bg-[#F8F9F9] rounded-[12px] cursor-pointer  transition-all duration-300"
      onClick={() => setIsOpen(true)}
      onMouseLeave={() => {
        isOpen && setIsOpen(false);
      }}
    >
      <div className="min-w-[20px] min-h-[20px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          className="w-full h-full"
        >
          <g clipPath="url(#clip0_2079_27364)">
            <path
              d="M10.4999 19.1668C15.5625 19.1668 19.6666 15.0628 19.6666 10.0002C19.6666 4.93755 15.5625 0.833496 10.4999 0.833496M10.4999 19.1668C5.43731 19.1668 1.33325 15.0628 1.33325 10.0002C1.33325 4.93755 5.43731 0.833496 10.4999 0.833496M10.4999 19.1668C12.9999 19.1668 13.8333 15.0002 13.8333 10.0002C13.8333 5.00016 12.9999 0.833496 10.4999 0.833496M10.4999 19.1668C7.99992 19.1668 7.16658 15.0002 7.16658 10.0002C7.16658 5.00016 7.99992 0.833496 10.4999 0.833496M2.16659 13.3335H18.8333M2.16659 6.66683H18.8333"
              // stroke="--content-main-gray-light"
              className="stroke-[--content-main-gray-light] dark:stroke-[--background-disabled-button]"
            />
          </g>
          <defs>
            <clipPath id="clip0_2079_27364">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <span className="text-[9px] text-[--content-main-gray-light] dark:text-[--content-main-gray-dark] leading-none">
        {i18n.language.toLocaleUpperCase()}
      </span>

      <div className="min-w-[10px] min-h-[10px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          className="w-full h-full"
        >
          <path
            d="M12.1667 6.1665L8.50004 9.83317L4.83337 6.1665"
            className="stroke-[--content-main-gray-light] dark:stroke-[--content-main-gray-dark]"
            // stroke="--content-main-gray-light"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="absolute left-0 bottom-full min-w-[102px] h-auto"
            initial={backStyle.initial}
            animate={backStyle.animate}
            exit={backStyle.exit}
            // onMouseEnter={() => setIsOpen(true)}
          >
            <div className="w-full h-auto border border-[--background-disabled-button] dark:border-[#575859] rounded-[12px] shadow-xl overflow-hidden">
              {languageMap?.map((item, index) => (
                  <div
                    className={`w-full h-[40px] flex items-center gap-2 p-[8px] bg-[--content-inner-inner-white-light] ${
                      item.lng === i18n.language
                        ? "dark:bg-[--background-Component-module-dark]"
                        : "dark:bg-[--bacground-sidebar-dark]"
                    } 
                    ${index !== languageMap.length - 1 ? 'border-b border-b-[#F1F1F1] dark:border-b-[#575859]' : ''}
                    dark:hover:bg-[--background-Component-module-dark] cursor-pointer transition-all duration-300`}
                    key={index}
                    onClick={() => {
                      i18n.changeLanguage(item.lng);
                      setIsOpen(false);
                    }}
                  >
                    <div className="w-[20px] h-[20px] flex justify-center items-center">
                      {item.lng === "es" && <IconFlagCol />}
                      {item.lng === "en" && <IconFlagUsd />}
                      {item.lng === "pt" && <IconFlagPt />}
                    </div>
                    <span className="text-[16px] text-[] dark:text-[--content-inner-inner-white-light]">
                      {item.name}
                    </span>
                  </div>
              ))}
            </div>
            <div className="w-full h-[12px] bg-transparent" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CustomLenguaje;
