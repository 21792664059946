import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { environment } from "@/environments/environment.dev";
import { StorageService } from "../../services/StorageServiceVanKPay";
import { jwtDecode } from "jwt-decode";

export class ServicesHome extends AxiosClientRepository {
  private user = null;

  constructor() {
    super();
  }

  public async decodeToken() {
    const token = await StorageService.get("token");
    const tokenDecode: any = jwtDecode(token);
    return tokenDecode?.user;
  }

  // Método asíncrono para inicializar datos
  private async init() {
    try {
      this.decodeToken();
      const result = await new IndexedDBLocalDropdownRepository().get("user");
      this.user = result[0] ?? null;
    } catch (error) {
      console.error("Error initializing user:", error);
      throw error;
    }
  }

  private async ensureUserInitialized() {
    if (!this.user) {
      await this.init();
    }
  }

  async getOperationsHome(param: string) {
    try {
      const url = `${environment.url_servers.url_operation}/operations/${param}`;
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  async getCurrency() {
    try {
      await this.ensureUserInitialized();
      const url = `${environment.url_servers.url_users}/getCurrencyUsers?query={"VIRTUALEMAIL":"${this.user?.VIRTUALEMAIL}"}`;
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
    
      throw error;
    }
  }

  async updateOnboarding(data: any) {
    try {
     
      await this.ensureUserInitialized();
      const url = `${environment.url_servers.url_users}/secure/v1/putProfileUser`;
      const response = await this.put({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCryptoQr(data: any) {
    try {
      await this.ensureUserInitialized();
      const url =
        `${environment.url_servers.url_orchertractor}/getDepositAddressBinance?query=` +
        JSON.stringify(data);
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  async getHistory(LIMIT: number) {
    try {
      await this.ensureUserInitialized();
      const url = `${environment.url_servers.url_orchertractor}/vankHistory?$LIMIT=${LIMIT}` ;
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  // async getHistory(LIMIT: number, lastMonth: boolean = false) {
  //   try {
  //     await this.ensureUserInitialized();
  //     let url = `${environment.url_servers.url_orchertractor}/vankHistory?$LIMIT=${LIMIT}`;
      
  //     if (lastMonth) {
  //       const thirtyDaysAgo = new Date();
  //       thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  //       url += `&query={"date":{"$gte":"${thirtyDaysAgo.toISOString()}"}}`;
  //     }
  
  //     const response = await this.get({ url });
  //     return response.body;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  

  async updateTransferCancel(data: any) {
    try {
      await this.ensureUserInitialized();
      const url = `${environment.url_servers.url_orchertractor}/url_orchertractor`;
      const response = await this.post({ url, data });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  async getLibreryCripto(param: string) {
    try {
      const url = `${environment.url_servers.url_operation}/operations/${param}`;
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  async getBalancesHome(param: string) {
    try {
      const url = `${
        environment.url_servers.url_operation
      }/balances/${param}?$LIMIT=${2}&$OFFSET=${0}`;
      const response = await this.get({ url });
      return response.body;
    } catch (error) {
      throw error;
    }
  }

  async getAssets(data: any) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/assetsListBinance?query=` +
        JSON.stringify({ ASSET: data.ASSET, GENERATEDBY: data?.GENERATEDBY });
      const response = await this.get({ url });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateVisibilityAsset(data: any) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/secure/v1/assetsStateView`;
      const response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
