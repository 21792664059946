import { RouterProvider } from "react-router-dom";
import { Context } from "@redtea/react-inversify";
import router from "./Router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CacheDropDowns } from "./apps/Shared/infrastructura/Persistence/CacheDropDowns";
import { useEffect, useState } from "react";
import ErrorInfoModal from "./apps/Auth/ErrorInfoModal/ErrorInfoModal";
import CustomModal, { backStyle } from "./apps/Shared/CustomModal/CustomModal";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import pulse1 from "@assets/img/pulse1.gif";
//import { ToastContainer } from "react-toastify";
import ModalInactAndExpi from "./apps/Root/RootVank/ModalInactAndExpi/ModalInactAndExpi";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import ToastProvider from "./Context/ToastContext/ToastContext";
import { decodeToken } from "./apps/Shared/utils/Utils";
import { checkTokenExpiration } from "./apps/Shared/utils/checkTokenExpiration/checkTokenExpiration";

function App({ container }) {
  const cacheDropdowns = new CacheDropDowns();

  const [isInactive, setIsInactive] = useState(async () => {
    const inactiveStatus = await StorageService.get("inactivity");
    return inactiveStatus === null ? false : inactiveStatus;
  });

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const [type, setType] = useState("");
  const [isOpenExpiry, setIsOpenExpiry] = useState(false);
  const [isClose, setIsClose] = useState(false);
  let timeoutId; // Declara timeoutId fuera del estado

  // Configuración del temporizador de inactividad (5 minutos)
  const INACTIVITY_TIME = 5 * 60 * 1000; // 5 minutos // UTILIZAR DEV, PROD
  const _INACTIVITY_TIME = import.meta.env.VITE_HALF_MINUTE; // 30 segundos // UTILIZAR LOCAL
  const INACT_TIME_ONE_DAY = import.meta.env.VITE_ONE_DAY || 2 * 24 * 60 * 60 * 1000; // 1 día // UTILIZAR LOCAL

  const resetTimer = async () => {
    // Limpia el temporizador anterior si existe
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Configura un nuevo temporizador
    timeoutId = setTimeout(async () => {
      const token = await StorageService.get("token");
      const decode = decodeToken(token);
      const Exp = checkTokenExpiration(token);
      if (
        token &&
        token !== "undefined" &&
        decode?.sub === "VANK_AUTHENTICATIONS" &&
        !Exp
      ) {
        setType("Inactive");
        setIsOpenExpiry(true);
        setIsClose(true);
      }
    }, INACT_TIME_ONE_DAY);
  };

  const handleUserActivity = async () => {
    const inactivity = await StorageService.get("inactivity");
    if (inactivity) {
      setType("Inactive");
      setIsOpenExpiry(true);
    } else {
      setType("Inactive");
      setIsOpenExpiry(false);
    }

    const token = await StorageService.get("token");
    const decode = decodeToken(token);
    const Exp = checkTokenExpiration(token);
    if (
      token &&
      token !== "undefined" &&
      decode?.sub === "VANK_AUTHENTICATIONS" &&
      !Exp
    ) {
      resetTimer();
    } else {
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("touchmove", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);

    if (theme === "dark") {
      document.body.style.backgroundColor = "var(--background-layaout-dark)";
    } else {
      document.body.style.backgroundColor = "var(--color-lighter-white)";
    }

    // window.addEventListener("beforeunload", handleBeforeUnload);

    handleUserActivity();
    // const INACTIVITY_TIME = parseInt(import.meta.env.VITE_INACTIVITY_TIME, 10);
    // alert(INACTIVITY_TIME);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("touchmove", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    cacheDropdowns
      .cache()
      .then(() => {
        // setIsLoading(false);
      })
      .catch((e) => {});
  }, []);

  return (
    <Context.Provider value={container}>
      <RouterProvider router={router} />
      
      {/* <ToastContainer position="top-right" /> */}
      <ToastProvider>
      
        <CustomModal isOpen={isOpenExpiry}>
        
          <ModalInactAndExpi
            // type={type}
            type="Inactive"
            isClose={isClose}
            setIsOpenExpiry={setIsOpenExpiry}
          />
        </CustomModal>
      </ToastProvider>
    </Context.Provider>
  );
}

export default App;
