import React, { useEffect, useState } from "react";
import ModelInfoCurrencie from "../../models/ModelInfoCurrencie";
import { HeaderModal } from "../../../Modales/HeaderModal/HeaderModal";
import "./styles.css";
import { ButtonCopy } from "@/apps/Vank/Page/Transactions/shared/ButtonCopy/ButtonCopy";
import { formatBalance } from "../../../Home";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import CustomButton from "@/apps/Shared/CustomButton/CustomButton";

const ModalInfoCurrencie = ({
  data,
  onclickHeader,
}: {
  data: ModelInfoCurrencie;
  onclickHeader: () => void;
}) => {
  const [t, i18n] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);



  useEffect(() => {
    // Función para verificar si el dispositivo es móvil
    const checkMobile = () => {
      // console.log(window.innerWidth < 768);

      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    // Verifica el tamaño de la ventana al cargar el componente
    checkMobile();

    // Añade un listener para manejar el cambio de tamaño de la ventana
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile); // Limpia el listener
    };
  }, []);

  return (
    <motion.div
      className="bg-[var(--bacground-component-module-light)] dark:bg-[#2D2E2F] 2xl:min-w-[422px] xl:min-w-[422px] lg:min-w-[422px] tablet:min-w-[422px] mobile:min-w-full  py-4 flex flex-col gap-4
                        2xl:relative xl:relative lg:relative tablet:relative mobile:absolute bottom-0
                        2xl:rounded-2xl xl:rounded-2xl lg:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl
        "
      initial={{ translateY: isMobile ? "100%" : "0%" }}
      animate={{ translateY: isMobile ? "0%" : "0%" }}
      exit={{ translateY: isMobile ? "100%" : "0%" }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full px-4">
        <HeaderModal
          headerHeight=""
          headerStyle="w-full "
          text={t("Vank.Home.Balances.AccountDetails.Title")}
          onClick={onclickHeader}
        />
      </div>
      {/* <hr /> */}
      <div className="w-full h-px bg-[#F1F1F1] dark:bg-[#575859]" />
      <div className="flex flex-col px-4 gap-2">
        <div className="item-content">
          <span className="item-th dark:text-[#FFFFFF]">
            {t("Vank.Home.Balances.AccountDetails.item1")}:
          </span>
          <span className="item-td dark:text-[#FFF] font-medium">{data?.acount}</span>
        </div>
        <div className="item-content">
          <span className="item-th dark:text-[#FFFFFF]">
            {t("Vank.Home.Balances.AccountDetails.item2")}
          </span>
          <span className="item-td dark:text-[#FFF] font-medium">
            {data?.acount == "COP"
              ? t("Vank.Home.Balances.AccountDetails.item6")
              : t("Vank.Home.Balances.AccountDetails.item7")}
          </span>
        </div>
        <div className="item-content">
          <span className="item-th dark:text-[#FFFFFF]">
            {t("Vank.Home.Balances.AccountDetails.item3")}
          </span>
          <span className="item-td dark:text-[#FFF] font-medium">
            {formatBalance(parseFloat(data?.balance))} {data?.acount}
          </span>
        </div>
        <div className="item-content">
          <span className="item-th dark:text-[#FFFFFF]">
            {t("Vank.Home.Balances.AccountDetails.item4")}
          </span>
          <span className="item-td dark:text-[#FFF]">
            {"Ahorros"}
          </span>
        </div>
        {/* <div className="item-content">
          <span className="item-th dark:text-[#FFFFFF]">
            {t("Vank.Home.Balances.AccountDetails.item5")}
          </span>
          <div className="flex items-center">
            <span className="item-td dark:text-[#C0C0C1]">
              {data?.acountNumer}
            </span>
            <span className="dark:text-[#C0C0C1]">
              <ButtonCopy textCopy={data?.acountNumer} />
            </span>
          </div>
        </div> */}


        <div className="flex justify-center">
          <CustomButton
            label={
              <span className="flex items-center justify-center">
                <p>{t("Recargar")}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                  <path d="M6.96875 14.0335L14.0354 6.9668M6.96875 14.0335H11.2104M6.96875 14.0335V9.7918" stroke="#2D2E2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>}
            className="bg-[#FFF133] rounded-full h-[48px] w-[193px]"
          />
        </div>


      </div>
    </motion.div>
  );
};

export default ModalInfoCurrencie;
