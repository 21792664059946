import React from "react";

export const IconNotification = ({ className }: { className?: string }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="fullInventory/Bell">
    <path id="Vector" d="M19.0003 17.5C19.0053 17.8581 18.882 18.2061 18.6528 18.4812C18.4235 18.7563 18.1034 18.9404 17.7503 19H17.5003H6.50029H6.25029C5.89718 18.9404 5.57707 18.7563 5.34781 18.4812C5.11856 18.2061 4.99529 17.8581 5.00029 17.5C4.99321 17.1414 5.11573 16.7923 5.34535 16.5167C5.57497 16.2412 5.89628 16.0577 6.25029 16V10.25C6.25029 8.72501 6.85609 7.26247 7.93443 6.18414C9.01276 5.1058 10.4753 4.5 12.0003 4.5C13.5253 4.5 14.9878 5.1058 16.0662 6.18414C17.1445 7.26247 17.7503 8.72501 17.7503 10.25V16C18.1043 16.0577 18.4256 16.2412 18.6552 16.5167C18.8849 16.7923 19.0074 17.1414 19.0003 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M10 21H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M12.82 4.57C12.9367 4.4028 12.9995 4.20391 13 4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4C11.0005 4.20391 11.0633 4.4028 11.18 4.57" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>
    
  );
};


