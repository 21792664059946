import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";
import { HttpResponseRecipeintVankPay } from "../../../Recipient/models/ModelRecipientVankPay";

export class ServiceOrchestrator extends AxiosClientRepository {
  async getAssets(data: any) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/assetsListBinance?query=` +
        JSON.stringify({ ASSET: data.ASSET, GENERATEDBY: data?.GENERATEDBY });
      const response = await this.get({ url });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getHistoryTransactionVankPay(data: any) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/getHistoryOrderVankpaySubAccouts?`+
        `&$LIMIT=${data?.LIMIT}`;
      const response = await this.get({ url });
      console.log("Respuesta raiz", response)
      return response;
    } catch (error) {
      throw error;
    }
  }
  

  public async postTranferSubCuentas(data: any) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/transferAssetsSubAccount`;
      let response = await this.post({ url, data });

      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getCryptos(data: any) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/assetsListBinance?query=` +
        JSON.stringify({ GENERATEDBY: data?.GENERATEDBY });
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getAddQR(query: string) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/getDepositAddressBinance`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getQrVankPay(data: string) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/getQrVankPay?query=` +
        JSON.stringify(data);
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAssetsDymanic(data: any) {
    try {
      const url =
        `${environment.url_servers.url_orchertractor}/assetsListBinance?query=` +
        JSON.stringify(data);
      const response = await this.get({ url });

      return response;
    } catch (error) {
      throw error;
    }
  }

  public async updateOrderVankPayUsers(data: any) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/updateOrderVankPayUsers`;
      let response = await this.put({ url, data });

      return response;
    } catch (error) {
      throw error;
    }
  }

  public async postUsersProcessVank(data: unknown) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/postUsersProcessvankpay`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getBeneficiary(query: any): Promise<HttpResponseRecipeintVankPay> {
    try {
      // const url: string = `${environment.url_servers.url_usersAWS}/BeneficiaryUsers`+query;
      const url: string =
        `${environment.url_servers.url_orchertractor}/getRecipientsUsersVankPay` +
        query;
      

      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async postaddBeneficiary(data: unknown) {
    try {
      // const url = `${environment.url_servers.url_users}/addBeneficiary`;
      const url = `${environment.url_servers.url_orchertractor}/postRecipientsUsersVankPay`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
