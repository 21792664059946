import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { environment } from "@/environments/environment.dev";

export class CryptoServices extends AxiosClientRepository {
  private user = null;

  constructor() {
    super();
  }

  // Método asíncrono para inicializar datos
  private async init() {
    try {
      const result = await new IndexedDBLocalDropdownRepository().get("user");
      this.user = result[0] ?? null;
    } catch (error) {
      console.error("Error initializing user:", error);
      throw error;
    }
  }

  private async ensureUserInitialized() {
    if (!this.user) {
      await this.init();
    }
  }

  async getCryptoByAsset(params?: any) {
    try {
      await this.ensureUserInitialized();
      const url = `${environment.url_externas.url_binance}/binanceorchestrator/assetsListBinance?query={"ASSET":"ETH","GENERATEDBY":"${this.user?.VIRTUALEMAIL}"}&$LIMIT=2&$OFFSET=0`;
      const response = await this.get({ url });
    

      return response.body.response.body;
    } catch (error) {
      throw error;
    }
  }

  async listCrypto(params?: any) {
    try {
      await this.ensureUserInitialized();
      const url = `${environment.url_externas.url_binance}/binanceorchestrator/assetsListBinance?query={"GENERATEDBY":"${this.user?.VIRTUALEMAIL}"}`;
      const response = await this.get({ url });
      console.log("listCrypto Response:", response.body.response.body); // Verifica la estructura

      return response.body.response.body;
    } catch (error) {
     
      throw error;
    }
  }

  async listCurrency() {
    try {
      const url = `${environment.url_servers.url_users}/getCurrencyUsers`;
      const response = await this.get({ url });
   

      return response.body;
    } catch (error) {
      
      throw error;
    }
  }

  async getHistoryBinance() {
    try {
      await this.ensureUserInitialized();
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = now.getUTCMonth();
      const firstDay = new Date(Date.UTC(year, month, 1))
        .toISOString()
        .replace(/T.*/, "T00:00:00Z");
      const lastDay = new Date(Date.UTC(year, month + 1, 0))
        .toISOString()
        .replace(/T.*/, "T00:00:00Z");

      const url = `${environment.url_servers.url_orchertractor}/depositHistoryBinance?query={"GENERATEDBY":"${this.user?.VIRTUALEMAIL}","START_TIME":"${firstDay}","END_TIME":"${lastDay}"}`;
      const response = await this.get({ url });
     
      return response.body;
    } catch (error) {
      
      throw error;
    }
  }

  async sendCrypto(data: any) {
    try {
      const url = `${environment.url_servers.url_orchertractor}/v1/secure/transferAccountsExternal`;
      const response = await this.post({ url, data });
      // if (response.body.statusCode !== 200)
      //   throw new Error("User has insufficient balance");
      

      return response.body;
    } catch (error) {
      
      throw error;
    }
  }

  async getRealTimeValueCoinmarket(currency: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/convert?amount=${currency.amount}&symbol=${currency.crypto}&convert=${currency.currency}`;
      let response = await this.get({ url });
     
      return response;
    } catch (error) {
      throw error;
    }
  }
}
