import { StorageService } from "../Vank/Page/services/StorageServiceVanKPay";
import { SocketConfig, WebSocketMap } from "./types";
class WebSocketManager {
  public connections: WebSocketMap = {}; // Almacena las conexiones WebSocket por ID
  public services = "";
  public listeners: Record<string, Record<string, (data: any) => void>> = {};
  // Creamos y repartimos las conexxiones de socket
  public saveConnectionData(
    id: string,
    data: { keyPublic: string; clientId: string }
  ) {
    const storedData = JSON.parse(
      localStorage.getItem("webSocketConnections") || "{}"
    );
    // Actualizar los datos de la conexión específica
    storedData[id] = data;
    // Guardar en localStorage
    localStorage.setItem("webSocketConnections", JSON.stringify(storedData));
  }
  // Creamos y repartimos las conexxiones de socket
  async createWebSocket(id: string, baseUrl: string): Promise<void> {
    if (!this.connections[id]) {
      try {
        const token = await StorageService.get("token");
        const verify = await StorageService.get("verify");
        const ipTimestamp = localStorage.getItem("ip");
        if (!token || !verify || !ipTimestamp) {
          return Promise.reject(new Error("Missing required parameters"));
        }
        // Construimos la URL con los parámetros
        const url = `${baseUrl}?Ip=${ipTimestamp}&Authorization=${token}&verify=${verify}`;
        const socket = new WebSocket(url);
        // Retornar un Promise que se resuelve al abrir la conexión
        return new Promise<void>((resolve, reject) => {
          socket.onopen = () => {
            console.log(`[${id}] WebSocket connected to ${baseUrl}`);
            this.connections[id] = socket; // Guardar conexión
            resolve(); // Conexión establecida
          };
          socket.onclose = () => console.log(`[${id}] WebSocket disconnected`);
          socket.onerror = (error) => {
            console.error(`[${id}] WebSocket error:`, error);
            reject(error); // Rechazar la promesa en caso de error
          };
        });
      } catch (error) {
        console.error(`[${id}] Failed to create WebSocket:`, error);
        return Promise.reject(error);
      }
    } else {
      return Promise.resolve(); // Si ya existe la conexión, no se hace nada
    }
  }
  // si quieren obtener la conexion/ opcional
  getWebSocket(id: string): WebSocket | null {
    return this.connections[id] || null;
  }
  handleEvent(callback: (data: any) => void) {
    const connection = this.connections[this.services];
    if (connection) {
      connection.addEventListener("message", (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        // if (eventReceived === eventName) {
        callback(data);
        // }
      });
    }
    // return this.connections[id] || null;
  }
  closeWebSocket(id: string): void {
    if (this.connections[id]) {
      this.connections[id].close();
      delete this.connections[id];
      console.log(`[${id}] WebSocket closed`);
    }
  }
  closeAllWebSockets(): void {
    Object.keys(this.connections).forEach((id) => this.closeWebSocket(id));
  }
  async getConnectionData(id: string) {
    const storedData = JSON.parse(
      localStorage.getItem("webSocketConnections") || "{}"
    );
    const token = await StorageService.get("token");
    const verify = await StorageService.get("verify");
    const ipTimestamp = localStorage.getItem("ip");
    // Asegúrate de que storedData[id] existe para devolver los datos correctamente
    if (!storedData[id]) {
      return null;
    }
    // Combina los valores en un solo objeto
    return {
      clientId: storedData[id].clientId,
      keyPublic: storedData[id].keyPublic,
      token,
      verify,
      ipTimestamp,
    };
  }
  sendToSocket(data: any, services:string): any {
    const connection = this.connections[services];
    if (!connection || connection.readyState !== WebSocket.OPEN) {
      console.error(`Connection with ID "${services}" is not open.`);
      return;
    }
    connection.send(JSON.stringify(data));
  }
}
const webSocketManager = new WebSocketManager();
export default webSocketManager;









