import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import {
  IconDowloadBlue,
  IconEmail,
  IconWhatSapp,
  IconSendEmail,
  IconClose,
} from "@/assets/IconV2/IconsSvg";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { postDataDownloadPdf } from "../../Transactions/VankPay/atoms/postDataDownloadPdf";
import { ServicesPrints } from "../../Transactions/VankPay/service/ServicesPrints";
import { ExternalServices } from "../../Transactions/VankPay/service/ExternalServices";
import { default as toastHook } from "@/hooks/useToast"; // import
import { useTranslation } from "react-i18next";
import { ServicesPrintsFiat } from "../../Transactions/Fiat/Services/ServicesPrintsFiat";
import {
  DownloadIcon,
  SendEmailIcon,
} from "@/apps/Shared/ToastContainer/Icons";
import SpinnerShared from "./SpinnerShared";
import { string } from "zod";

const SharedButtons = ({
  typeModule,
  email,
  CODERECEIPT,
  GENERATEDBY,
  URL,
  TypeAction,
}: {
  CODERECEIPT?: string;
  GENERATEDBY?: any;
  URL?: string;
  TypeAction?: string;
  email?: string;
  typeModule?: number;
}) => {
  const toast = toastHook(); // use hook
  const dataDownloadPdf = useRecoilValue(postDataDownloadPdf);
  const printServices = new ServicesPrints();
  const printFiat = new ServicesPrintsFiat();
  const externalService = new ExternalServices();
  const [visible, setVisible] = useState(false);
  const [visibleDownload, setVisibleDownload] = useState(false);
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [visibleWhatSapp, setVisibleWhatSapp] = useState(false);
  const [t] = useTranslation("global");
  const [visibleEmailInput, setVisibleEmailInput] = useState(false);
  const [emailDestinatario, setEmailDestinatario] = useState("");
  const [error, setError] = useState("");
  const [ok, setOk] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 668);

  //const {addToast} = useToastDispatch();
  const [dataUser, setDataUser] = useState<any>({
    // Usar el contexto de notificaciones
    EMAIL: "",
    CRYPTO: "",
    AMOUNT: "",
    CODECRYPTO: "",
  });

  const getBase64 = async (action: string) => {
    const dataPdf = {
      CODERECEIPT: CODERECEIPT,
      GENERATEDBY: GENERATEDBY,
      URL: URL,
      ACTION: action,
      TYPESEND: TypeAction,
      TO_EMAIL: emailDestinatario, // Para el nuevo servicio
    };

    try {
      // Identifica el tipo de transacción utilizando el atributo TYPESEND
      if (dataPdf.TYPESEND === "PAYMENTS") {
        // Transacción de dinero (Fiat)
        const response = emailDestinatario
          ? await printFiat.getPrintsSharedPdfFiat({
              ...dataPdf,
              email: emailDestinatario, // Destinatario explícito
              virtual: GENERATEDBY, // Generado por el emisor
            })
          : await printFiat.getPrintsFiat(dataPdf);
        return response.body.url === undefined
          ? response.body
          : response.body.url;
      } else {
        // Asumimos que es una transacción de VankPay si TYPE no es "PAYMENTS"
        const response = emailDestinatario
          ? await printServices.getPrintsSharedPdf({
              ...dataPdf,
              emisor: emailDestinatario, // Agrega el emisor explícito
            })
          : await printServices.getPrints(dataPdf);
        return response.body.url === undefined
          ? response.body
          : response.body.url;
      }
    } catch (error) {
      toast.error(
        t(
          "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text3"
        ),
        {
          position: isMobile ? "top-center" : "top-right", // Obligatorio
          autoClose: 5000,
          hideProgressBar: true,
          isHovered: true,
          height: isMobile ? "" : "100px",
        }
      );
    }
  };

  const redirigirAWhatsApp = async () => {
    setVisibleWhatSapp(true);

    const dataBase64 = await getBase64("WHATSSAPP");
    // Reemplaza con tu mensaje
    const mesagge = `${t(
      "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text1"
    )} ${dataBase64}`;
    const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      mesagge
    )}`;
    window.open(url, "_blank");
    setVisibleDownload(true);

    setOk(1);

    setTimeout(() => {
        setOk(0);
    }, 3300);

    setTimeout(() => {
      setVisibleWhatSapp(false);
      setVisibleDownload(false);
    }, 2000);
  };

  // Para redirigir a Email
  const fetchData = async () => {
    try {
      setVisible(true);

      const response = await getBase64("DOWNLOAD");

      fetch(response)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${CODERECEIPT}.pdf`; // Nombre sugerido para el archivo descargado
          link.click();
          window.URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.error(
            t(
              "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text6"
            ),
            error
          );
          // Toast personalizado para error
          
          toast.error(
            t(
              "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text6"
            ),
            {
              position: isMobile ? "top-center" : "top-right", // Obligatorio
              autoClose: 5000, // Opcional
              iconSuccess: <DownloadIcon />, // Opcional
              hideProgressBar: true, // Opcional
              isHovered: true,
              height: isMobile ? "" : "100px",
            }
          );
        });

      setVisibleDownload(true);

      setTimeout(() => {
        setVisible(false);
        setVisibleDownload(false);
      }, 2000);

      setOk(3);

      setTimeout(() => {
          setOk(0);
      }, 3300);

      toast.success(
        <>
          <b>
            {t(
              "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text4"
            )}
          </b>
          <br />
          {t(
            "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text4.1"
          )}
        </>,

        {
          position: isMobile ? "top-center" : "top-right", // Obligatorio
          autoClose: 5000, // Opcional
          iconSuccess: <DownloadIcon />, // Opcional
          hideProgressBar: true, // Opcional
          isHovered: true,
          height: isMobile ? "" : "100px",
        }
      );
    } catch (error) {
      setVisible(false);

      // Toast personalizado de error
      toast.error(
        t(
          "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text3"
        ),
        {
          position: isMobile ? "top-center" : "top-right", // Obligatorio
          autoClose: 5000, // Opcional
          iconSuccess: <DownloadIcon />, // Opcional
          hideProgressBar: true, // Opcional
          isHovered: true,
          height: isMobile ? "" : "100px",
        }
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const enviarEmail = async () => {
    try {
      if (!emailDestinatario) {
        setError("Por favor ingresa un correo electrónico válido.");
        return;
      }

      if (!validateEmail(emailDestinatario)) {
        return;
      }

      setVisibleEmail(true);

      const dataBase64 = await getBase64("EMAIL");

      if (!dataBase64) {
        setVisibleEmail(false);
        return;
      }

      const cuerpo = `${t(
        "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text1"
      )} ${dataBase64}`;
      console.log("Email body:", cuerpo);

      externalService.shareEmail({
        destinatario: emailDestinatario,
        asunto: "Resivo",
        cuerpo: cuerpo,
      });

      setOk(2);
      setVisibleDownload(true);
      setVisibleEmail(false);
      setVisibleDownload(false);
      setEmailDestinatario("");
      setVisibleEmailInput(false);
      
      setTimeout(() => {
          setOk(0);
      }, 3300);

      toast.success(
        <>
          <b>
            {t(
              "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text5"
            )}
          </b>
          <br />
          {t(
            "Vank.Transaction.VankPay.SendVankPay.TransactionValidation.Transaction.ApprovedTransaction.text5.1"
          )}
        </>,

        {
          position: isMobile ? "top-center" : "top-right", // Obligatorio
          autoClose: 5000, // Opcional
          iconSuccess: <SendEmailIcon />, // Opcional
          hideProgressBar: true, // Opcional
          isHovered: true,
          height: isMobile ? "" : "100px",
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setVisibleDownload(false);
    }
  };


  
  useEffect(() => {
    const manejarResize = () => {
      setIsMobile(window.innerWidth <= 668);
    };

    window.addEventListener("resize", manejarResize);

    return () => {
      window.removeEventListener("resize", manejarResize);
    };
  }, []);

  return (
    <div className="flex items-center gap-2 w-full bg-[var(--background-layaout-light)] dark:bg-[#212222] dark:text-[var(--content-main-gray-dark)] h-[40px] rounded-full relative">
      {ok === 1 || ok === 2 || ok === 3 ? (
        <>
          {ok === 1 && (
            <div className="w-full flex items-center gap-1 justify-center">
              <p className="max-w-max text-center w-full text-sm text-[#818282]">
                Conectado
                {t('Share.StatusSend.Connect')}
              </p>
              <div className="w-[24px] h-[24px] flex justify-center items-center bg-[#E1FDE4] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 12.37L9.76 16.12L18 7.88"
                    stroke="#1F5928"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
          {ok === 2 && (
            <div className="w-full flex items-center gap-1 justify-center">
              <p className="max-w-max text-center w-full text-sm text-[#818282]">
              {t('Share.StatusSend.Send')}
              </p>
              <div className="w-[24px] h-[24px] flex justify-center items-center bg-[#E1FDE4] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 12.37L9.76 16.12L18 7.88"
                    stroke="#1F5928"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
          {ok === 3 && (
            <div className="w-full flex items-center gap-1 justify-center">
              <p className="max-w-max text-center w-full text-sm text-[#818282]">
              {t('Share.StatusSend.download')}
              </p>
              <div className="w-[24px] h-[24px] flex justify-center items-center bg-[#E1FDE4] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 12.37L9.76 16.12L18 7.88"
                    stroke="#1F5928"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
        </>
      ) : !visible && !visibleEmail && !visibleWhatSapp ? (
        <>
          {!visibleEmailInput ? (
            <>
              {/* Botón de WhatsApp */}
              <CustomButton
                onclick={() => redirigirAWhatsApp()}
                label={<IconWhatSapp />}
                className="hover:bg-[#81828225] hover:h-[40px] rounded-r-full rounded-l-full h-full w-[33%] flex flex-col items-center justify-center"
              />
              <span className="border-l-[1px] border-[#F1F1F1] dark:border-[#2D2E2F] w-1 h-6"></span>

              {/* Botón para mostrar input de email */}
              <CustomButton
                onclick={() => setVisibleEmailInput(true)}
                label={<IconEmail />}
                className="hover:bg-[#81828225] hover:h-[40px] w-[33%] h-full flex items-center justify-center rounded-full"
              />
              <span className="border-l-[1px] border-[#F1F1F1] dark:border-[#2D2E2F] w-1 h-6"></span>

              {/* Botón de descarga */}
              <CustomButton
                disabled={visible}
                onclick={() => fetchData()}
                label={<IconDowloadBlue />}
                className="hover:bg-[#81828225] hover:h-[40px] rounded-r-full rounded-l-full w-[33%] h-full flex flex-col items-center justify-center"
              />
            </>
          ) : (
            // Input de correo y botones relacionados
            <div className="flex w-full h-[40px] items-center gap-0">
              <div className="flex w-full h-[40px] pl-[12px] pr-[8px] py-[8px] items-center gap-[10px] bg-[var(--background-layaout-light)] dark:bg-[#212222] dark:hover:text-[var(--content-main-black-dark)] text-[var(--content-main-gray-light)] hover:text-[var(--content-main-black-light)] rounded-l-full">
                {/* Botón para cerrar el input */}
                <CustomButton
                  onclick={() => setVisibleEmailInput(false)}
                  label={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14258 5.14453L12.8569 12.8588"
                        stroke="#818282"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8569 5.14453L5.14258 12.8588"
                        stroke="#818282"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  className="w-[18px] h-[18px] flex justify-center items-center bg-[var(--background-layaout-light)] dark:bg-[var(--Background-Inputs)]"
                />

                {/* Icono de correo */}
                <CustomButton
                  label={<IconEmail />}
                  className="w-[25px] h-[25px] flex justify-center items-center bg-[var(--background-layaout-light)] dark:bg-[var(--Background-Inputs)] text-white "
                />

                {/* Input para ingresar correo */}
                <input
                  type="email"
                  value={emailDestinatario}
                  onChange={(e) => setEmailDestinatario(e.target.value)}
                  className="w-full h-full bg-[var(--background-layaout-light)] dark:bg-[var(--Background-Inputs)] text-[#000000] dark:text-[#FFFFFF] text-[14px] outline-none"
                />
              </div>

              {/* Botón para enviar correo */}
              <CustomButton
                onclick={enviarEmail}
                label={
                  <IconSendEmail className="text-[--content-main-black-light] dark:text-[--content-main-black-dark] w-[24px] h-[24px] mr-1" />
                }
                className="min-w-[49px] h-full bg-[var(--background-disabled-button)] dark:bg-[var(--background-disabled-button-dark)] flex items-center justify-center rounded-r-full"
              />
            </div>
          )}
        </>
      ) : (
        // Spinners para acciones en progreso
        <>
          {visibleEmail && (
            <SpinnerShared
              text={t("buttonsShared.textSharedEmail")}
              fill="#F98888"
              statusAction={visibleDownload}
              textPostAction={t("buttonsShared.textSend")}
            />
          )}
          {visible && (
            <SpinnerShared
              text={t("buttonsShared.textDownloadPdf")}
              fill="#6FC8FF"
              statusAction={visibleDownload}
              textPostAction={t("buttonsShared.textDownload")}
            />
          )}
          {visibleWhatSapp && (
            <SpinnerShared
              text={t("buttonsShared.textSharedWhastApp")}
              fill="#6AD67B"
              statusAction={visibleDownload}
              textPostAction={t("buttonsShared.textconect")}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SharedButtons;
