import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatBalance } from "../Home";
import { useRecoilState, useRecoilValue } from "recoil";
import { counterSelector, dataAccounts, ProfileUser, selectCryptos } from "../state";
import "./style.css";
import { ServiceDecodiToken } from "../../Transactions/VankPay/service/ServiceDecodiToken";
import BalanceAdjustment from "./BalanceAdjustment/BalanceAdjustment";
import { IconAddCount, IconWallet } from "@/assets/IconV2/IconsSvg";
import ModalInfoCurrencie from "./components/ModalInfoCurrencie/ModalInfoCurrencie";
import ReceiveQR from "./components/ReceiveQR/ReceiveQR";
import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import ModelInfoCurrencie from "./models/ModelInfoCurrencie";
import USD from "@/assets/Icon/AccountIcons/USD.jpg";
import COP from "@/assets/Icon/AccountIcons/COP.jpg";
import CLP from "@/assets/Icon/AccountIcons/CLP.jpg";
import PEN from "@/assets/Icon/AccountIcons/PEN.jpg";
import MXN from "@/assets/Icon/AccountIcons/MXN.jpg";
import USDC from "@/assets/Icon/currency/USDCICON.jpg";
import USDT from "@/assets/Icon/currency/USDT.png";
import BTC from "@/assets/Icon/currency/BTCICON.jpg";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import { motion } from "framer-motion";
import SidebarAccount from "../../Account/components/SidebarAccount/SidebarAccount";
import { useSocket } from "@/apps/Context/WebSocketContext";

interface AccountData {
  ACCOUNT_DETAILS: "",
  ACCOUNT_ID: "",
  ACCOUNT_IDENTIFICATION: "",
  ACCOUNT_NAME: "",
  ACCOUNT_PRIMARY_CURRENCY: "",
  ACCOUNT_TYPE: "",
  ASSET_ID: "",
  amount: "",
  currency_code: "",
  currency_name: "",
  differenceMoney: "",
  img_url: "",
  iso: "",
  level: "",
  rate: "",
  rateHistory: "",
  type_money: "",
  valueRate: ""
}

const Balance = ({
  updateStatuses,
  objectBalance,
  objectBalanceCripto,
  filteredArray,
  setIsBalanceAdjustment,
  activePillar,
  setActivePillar,
  handleFilterChange,
  isLoadingBalances,
  stateData,
  event
}: {
  filteredArray: any;
  setIsBalanceAdjustment: any;
  activePillar: any;
  setActivePillar: any;
  handleFilterChange: any;
  isLoadingBalances: any;
  objectBalance: any;
  objectBalanceCripto: any;
  updateStatuses: () => void;
  stateData?:any;
  event:string
}) => {
  // const serviceHome=new ServicesHome();
  // const serviceToken=new ServiceDecodiToken();

  const [t, i18n] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useRecoilState<any>(selectCryptos);
  const [visibleAsset, setVisibleAsset] = useState<number>(0);
  //stado para manejar los datos de las networks de las cryptos
  const [dataNeworks, setDataNeworks] = useState<any>(null);
  const [assetVisibles, setAssetVisibles] = useState<boolean>(false);
  //stado para manejar la visibilidad de la modal Currencie
  const [visibleModalCurrencie, setVisibleModalCurrencie] =
    useState<boolean>(false);
  //stado para manejar los datos de las currencie
  const [dataCurrencie, setDataCurrencie] = useState<ModelInfoCurrencie>(null);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const [visibleAccount, setVisibleAccount] = useState<boolean>(false);
  const [visibleAccount2, setVisibleAccount2] = useState<boolean>(false);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto
  const step5Ref = useRef<HTMLDivElement>(null);
  const step5RefMobile = useRef<HTMLDivElement>(null);
  //====socket======================

  const serviceSoket = useSocket("operations");
  // const [stateData, setStateDate] = useState([])
  const dataAccount=useRecoilValue(dataAccounts)
  //========================================
  const pillarBalance = [
    {
      id: 0,
      name: t("Vank.Home.Balances.Header.filters.All"),
      filter: "Todos",
    },
    {
      id: 1,
      name: t("Vank.Home.Balances.Header.filters.Accounts"),
      filter: "accounts",
    },
    // { id: 3, name: t("Vank.Home.Balances.Header.filters.VankCards"), filter: "vankCards" },
    {
      id: 2,
      name: t("Vank.Home.Balances.Header.filters.Wallet"),
      filter: "wallet",
    },
  ];


  const handleSendToService1 = async () => {


    // setIsLoading(event === "NotifyTransactionVankPay" ? false : true);

    try {

      const { clientId, ipTimestamp, keyPublic, token, verify } = await serviceSoket?.method?.getConnectionData("operations");

      if (!clientId || !keyPublic) {
        console.error(`No data found for connection ID: operations`);
        return;
      }

      const data = {
        event: "getAccountsUsers",
        clientId,
        Ip: ipTimestamp,
        keyPublic,
        Authorization: token,
        verify,
      }


      serviceSoket.socket.send(JSON.stringify(data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (serviceSoket?.connected) {
      handleSendToService1()
    }
  }, [serviceSoket?.connected,["NotifyTransactionVankPay"].includes(event)])


  const handleSelect = (item: any) => {
    setSelect((selec?: any) => {
      const selePayload = {
        ...selec,
        wallet: item,
      };
      return selePayload;
    });
    setIsOpen(true);
  };

  const OnboardingData = [
    {
      id: 1,
      change: "+1.02%",
      rate: "$1.12",
      current: "USD",
      price: "$12,874.00 USD",
      subPrice: "$12,874.00 USD",
      img: USD,
    },
    {
      id: 2,
      change: "-0.56%",
      rate: "$1.12",
      current: "COP",
      price: "$16,465,600.00 COP",
      subPrice: "$4,325.52 USD",
      img: COP,
    },
    {
      id: 3,
      change: "-0.56%",
      rate: "$1.12",
      current: "CLP",
      price: "$4,811,508.00 CLP",
      subPrice: "$5,500.00 USD",
      img: CLP,
    },
    {
      id: 4,
      change: "-0.56%",
      rate: "$1.12",
      current: "PEN",
      price: "$13,424.40.00 PEN",
      subPrice: "$3,600.00 USD",
      img: PEN,
    },
    {
      id: 5,
      change: "-0.56%",
      rate: "$1.12",
      current: "MXN",
      price: "$46,503.80 MXN",
      subPrice: "$2,874.00 USD",
      img: MXN,
    },
    //  -------------
    {
      id: 6,
      change: "-0.56%",
      rate: "$1.12",
      current: "USD",
      price: "2,874.00 USD",
      subPrice: "$2,874.00 USD",
      img: USD,
    },
    {
      id: 7,
      change: "-0.56%",
      rate: "$1.12",
      current: "USD",
      price: "4,651.09 USD",
      subPrice: "$4,651.09 USD",
      img: USD,
      network: "TRC 20",
    },
    // {
    //   id: 8,
    //   change: "-0.56%",
    //   rate: "$1.12",
    //   current: "BTC",
    //   price: "0.018745 BTC",
    //   subPrice: "$24,651.09 USD",
    //   img: BTC,
    // },
  ];

  useEffect(() => {
    const scrollToStep = (stepRef: React.RefObject<HTMLElement>) => {
      if (stepRef.current) {
        stepRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Mantén 'center' si prefieres un comportamiento estándar
          inline: "nearest",
        });
      }
    };
    if (counter === 4) {
      scrollToStep(step5Ref);
      scrollToStep(step5RefMobile);
    }
  }, [counter]),
    useEffect(() => {
      if (parseInt(localStorage.getItem("id"))) {
        setActivePillar(parseInt(localStorage.getItem("id")));
      } else {
        setActivePillar(0);
        localStorage.setItem("id", "0");
      }
    }, [localStorage.getItem("id")]);

  //funcionalidad que permite setiar los datos de una currencie
  const addDataCurrencie = async (item: any) => {

    let dataToken = new ServiceDecodiToken();
    let idVank = await dataToken.getVankId();

    let data: ModelInfoCurrencie = {
      acount:item.currencies[0].type_money,
      typeMoney:"",
      balance: item.currencies[0].amount,
      acountNumer: idVank,
      typeAcount: "",
    };
    setDataCurrencie(data);
    setVisibleModalCurrencie(true);
  };


  const actionAccount = () => {
    setAssetVisibles(false)
    setVisibleAccount(true)

    setTimeout(() => {
      setVisibleAccount2(true)
    }, 300)

  }

  const closeSidebarAccount = () => {
    setAssetVisibles(true)
    setVisibleAccount2(false)

    setTimeout(() => {
      setVisibleAccount(false)
    }, 500)
  }


  return (
    <>

      <div className={`  ${!visibleAccount ? ' bg-black hidden' : 'bg-[#0000003c] fixed top-0 bottom-0 left-0 right-0 overflow-hidden z-[500]'}`}>
        <SidebarAccount isOpenSend={visibleAccount2} onClickSidebarAccount={() => closeSidebarAccount()} />
      </ div>

      <div
        className={`relative pb-4 w-full 2xl:h-[398px] xl:h-[398px] lg:h-[300px] md:h-[250px] sm:h-[250px] mobile:h-[210px] pt-4 bg-white dark:bg-[var(--background-component-module-dark)]  rounded-2xl flex-col justify-center items-start gap-4 inline-flex ${counter === 4 && "z-50"
          }`}
        style={{
          pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
        }}
      >



        <div className="w-full px-4 justify-between items-center gap-[4px] flex mobile:flex-wrap">
          {/* titulo balances */}
          <div className="  h-7 flex-col justify-start items-start inline-flex">
            <div className="w-full  h-7 justify-start items-center gap-1 flex">
              <span className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-medium w-full ">
                {t("Vank.Home.Balances.Header.title")}
              </span>
            </div>
          </div>

          <div className=" h-7 flex justify-center items-center gap-4">
            {/* pillares */}
            <div className=" h-7 justify-end items-center gap-2 inline-flex">
              {pillarBalance.map((item, index) => (
                <React.Fragment key={item.id}>
                  <button
                    className={`max-w-min 2xl:h-7 xl:h-7 lg:h-7 md:h-7 sm:h-7 mobile:h-[16px] p-2 ${(activePillar as number) === item?.id
                      ? "bg-[var(--background-pill-selected-light)] dark:bg-[#818282] text-[var(--content-main-white-light)] dark:text-[#2D2E2F]"
                      : "border border-[var(--background-pill-selected-light)] dark:border-[#EAEAEA] text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]"
                      } rounded-3xl justify-center items-center flex ${item.id === 0 || item.id === 1 || item.id === 2
                        ? "hidden"
                        : ""
                      }`}
                    key={index}
                    onClick={() => {
                      setActivePillar(parseInt(localStorage.getItem("id")));
                      handleFilterChange(item?.filter);
                      localStorage.setItem("id", JSON.stringify(index));
                      setVisibleAsset(parseInt(localStorage.getItem("id")));
                      localStorage.setItem("filterName", item?.filter);
                    }}
                  >
                    <div className="text-center text-xs font-normal ">
                      {item?.name}
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div>

            {!isLoadingBalances && (
              <motion.div
                className="flex items-center justify-center gap-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.9 }}
              >
                <CustomButton
                  className={`2xl:w-7   xl:w-7 lg:w-7 md:w-7 sm:w-7 mobile:w-7 h-7 hover-button ${theme === "dark" ? "dark-theme" : "light-theme"
                    }`}
                  onclick={() => setAssetVisibles(true)}
                  disabled={isLoadingBalances}
                >
                  <IconAddCount
                    className="text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)] " />
                </CustomButton>
              </motion.div>
            )}
          </div>
        </div>

        {/* data */}
        <div
          className={`w-[98%]  2xl:h-[337px] xl:h-[337px] lg:h-[300px] md:h-[250px] sm:h-[250px] mobile:h-[250px] flex-col justify-start items-center inline-flex border-t border-t-[var(--strokes-separator-separator-light)] dark:border-t-[--strokes-separator-disabled-dark] ${users?.STATUSINCODE === 2 ? "overflow-hidden" : "overflow-auto"
            }`}
        >
          {users?.STATUSINCODE === 2 ? (
            OnboardingData.map((item, index) => (
              <div key={item.id}>
                <div
                  className="relative   w-full min-h-[56px] px-4 justify-start items-center gap-2 inline-flex"
                >
                  <img
                    src={item?.img}
                    alt={item?.current}
                    className="w-[36px] h-[36px] rounded-full "
                  />

                  <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
                    <div className="w-full   justify-between items-center inline-flex">
                      <div className="max-w-max h-[17px]  flex justify-center items-center gap-[6px]">
                        <div className="flex items-center gap-1">
                          <span className="max-w-max text-base text-[var(--content-main-black-light)] dark:text-[#FFFFFF] font-medium">
                            {item?.current}
                          </span>
                          {item?.network && (
                            <div className="max-w-max h-[17px] rounded-[4px] border bg-[var(--background-layaout-light)] px-[6px] text-[12px] text-[var(--content-main-gray-light)] ">
                              {item?.network}
                            </div>
                          )}
                        </div>
                        {index < 6 && (
                          <CustomButton
                            label={<IconWallet />}
                          // onclick={() => addDataCurrencie(i)}
                          />
                        )}
                        {index >= 6 && (
                          <button
                            className="w-[20px] h-[20px]"
                            onClick={() => setIsOpen(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M13.5001 13.4993H13.5009M6.50008 6.49935H6.50086M13.5001 6.49935H13.5009M12.3334 4.16602H14.6667C15.3111 4.16602 15.8334 4.68835 15.8334 5.33268V7.66602C15.8334 8.31035 15.3111 8.83268 14.6667 8.83268H12.3334C11.6891 8.83268 11.1667 8.31035 11.1667 7.66602V5.33268C11.1667 4.68835 11.6891 4.16602 12.3334 4.16602ZM5.33341 11.166H7.66675C8.31108 11.166 8.83341 11.6883 8.83341 12.3327V14.666C8.83341 15.3103 8.31108 15.8327 7.66675 15.8327H5.33341C4.68908 15.8327 4.16675 15.3103 4.16675 14.666V12.3327C4.16675 11.6883 4.68908 11.166 5.33341 11.166ZM12.3334 11.166H14.6667C15.3111 11.166 15.8334 11.6883 15.8334 12.3327V14.666C15.8334 15.3103 15.3111 15.8327 14.6667 15.8327H12.3334C11.6891 15.8327 11.1667 15.3103 11.1667 14.666V12.3327C11.1667 11.6883 11.6891 11.166 12.3334 11.166ZM5.33341 4.16602H7.66675C8.31108 4.16602 8.83341 4.68835 8.83341 5.33268V7.66602C8.83341 8.31035 8.31108 8.83268 7.66675 8.83268H5.33341C4.68908 8.83268 4.16675 8.31035 4.16675 7.66602V5.33268C4.16675 4.68835 4.68908 4.16602 5.33341 4.16602Z"
                                stroke="var(--content-main-gray-light)"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                      <span className="min-w-max flex justify-start text-end text-base  text-[var(--content-main-black-light)] dark:text-[#FFFFFF] font-semibold ">
                        {item?.price}
                      </span>
                    </div>

                    <div className="w-full  justify-between items-center inline-flex">
                      <div className="w-[76px] justify-start items-start gap-1 inline-flex">
                        <span className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-xs font-normal">
                          {item?.rate}
                        </span>
                        <span className="w-11 text-right text-[var(--success-chip-light)] text-xs font-semibold">
                          {item?.change}
                        </span>
                      </div>

                      <span className="max-w-max text-[12px] text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] font-normal ">
                        {item?.subPrice}
                      </span>
                    </div>
                  </div>
                  <div className="w-[452px] h-px bg-[var(--strokes-network-light)] dark:bg-[var(--strokes-separator-disabled-dark)] absolute bottom-0 right-0" />
                </div>
              </div>
            ))
          ) : objectBalance === null && objectBalanceCripto === null ? (
            <div className="w-full h-full flex justify-center items-center">
              <CustomDataLoading />
            </div>
          ) : (
            <>
              {/* ==================00 */}
              {
                // <>
                //   {Object?.entries(
                //     objectBalance?.RateAmountHistoryCurrency
                //       ?.RateAmountHistoryBalance
                //   )?.map((i: any, _) => (

                //     <motion.div
                //       key={i[0]} // Usa i[0] como clave única
                //       className={`${i[1].STATEVIEW == 1 ? "" : "hidden"
                //         } relative border-b-[1px] dark:border-b-[var(--strokes-separator-disabled-dark)] w-full min-h-[56px] px-4 hover:px-5 justify-start items-center gap-2 inline-flex
                //         ${parseInt(localStorage.getItem("id")) == 0 ||
                //           parseInt(localStorage.getItem("id")) == 1
                //           ? ""
                //           : "hidden"
                //         } transition-all duration-150 cursor-pointer`}
                //       initial={{ opacity: 0 }}
                //       animate={{ opacity: 1 }}
                //       exit={{ opacity: 0 }}
                //       transition={{ duration: 0.9 }}
                //     >
                //       <div className="w-[36px]  h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
                //         <img
                //           src={i[1].IMGURL}
                //           alt=""
                //           className="w-full h-full object-contain"
                //         />
                //       </div>

                //       <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex ">
                //         <div className="w-full justify-between items-center inline-flex ">
                //           <div className="max-w-[108px] flex flex-col ">
                //             <div className="flex items-center flex-col mobile:flex-row  gap-2">
                //               <span className=" text-start text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-medium ">
                //                 {i[0]}
                //               </span>
                //               <span>
                //                 <CustomButton
                //                   label={
                //                     <span className="flex items-center lg:w-[200px] mobile:w-full gap-2 text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] ">
                //                       <span className="lg:text-sm mobile:text-xs">/ Nómina colombia</span>
                //                       <IconWallet />
                //                     </span>
                //                   }
                //                   onclick={() => addDataCurrencie(i)}
                //                 />
                //               </span>
                //             </div>

                //             <div className="flex gap-2">
                //               <span className="text-xs text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]">
                //                 {formatBalance(i[1].difference)}
                //               </span>

                //               <span
                //                 className={`text-xs ${i[1].percentage.split("")[0] == "-"
                //                   ? "text-[var(--error-chip-cancelado-light)]"
                //                   : "text-[var(--success-chip-light)] "
                //                   }`}
                //               >
                //                 {i[1].percentage}
                //               </span>
                //             </div>
                //           </div>

                //           <div className="tex-end">
                //             <div className="text-end">
                //               <p className="text-amount font-[600] text-[--content-main-black-light] dark:text-[#FFFFFF]">
                //                 {formatBalance(i[1].AMOUNT)}
                //               </p>
                //               <p className="text-convert text-[--content-main-gray-light] dark:text-[#FFFFFF]">
                //                 {formatBalance(i[1].CONVERT)}
                //               </p>
                //             </div>
                //           </div>
                //         </div>
                //         <hr className="dark:bg-[var(--strokes-separator-disabled-dark)]" />
                //       </div>
                //     </motion.div>
                //   ))}

                //   <>
                //     {Object?.entries(
                //       objectBalanceCripto?.RateAmountHistoryCrypto
                //         ?.RateAmountHistoryBalance
                //     )?.map((i: any, _) => (
                //       <motion.div
                //         key={i[0]} // Usa i[0] como key si es único
                //         className={` ${i[1].STATEVIEW == 1 ? "" : "hidden"
                //           } relative dark:border-b-[var(--strokes-separator-disabled-dark)] border-b-[1px] w-full min-h-[56px] px-4 hover:px-5 justify-start items-center gap-2 inline-flex  ${parseInt(localStorage.getItem("id")) == 0 ||
                //             parseInt(localStorage.getItem("id")) == 2
                //             ? ""
                //             : "hidden"
                //           } transition-all duration-150 cursor-pointer`}
                //         initial={{ opacity: 0 }}
                //         animate={{ opacity: 1 }}
                //         exit={{ opacity: 0 }}
                //         transition={{ duration: 0.9 }}
                //       >
                //         <div className="w-[36px] h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
                //           <img
                //             src={i[1].IMGURL}
                //             alt=""
                //             className="w-full h-full object-contain"
                //           />
                //         </div>

                //         <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
                //           <div className="w-full justify-between items-center inline-flex">
                //             <div className="max-w-[108px]  flex flex-col ">
                //               <div className="flex items-center justify-center gap-1">
                //                 <span className=" dark:text-[var(--content-main-black-dark)] text-start text-[var(--content-main-black-light)] text-base font-semibold">
                //                   {i[0]}
                //                 </span>

                //                 <span className="network">
                //                   {
                //                     JSON.parse(JSON.parse(i[1].NETWORKS))[0]
                //                       .NETWORKNAME
                //                   }
                //                 </span>

                //                 <span className="flex">
                //                   <button
                //                     className="w-[20px] h-[20px]"
                //                     disabled={true}
                //                     onClick={() => {
                //                       setDataNeworks({
                //                         asset: i[0],
                //                         nework: JSON.parse(
                //                           JSON.parse(i[1].NETWORKS)
                //                         )[0].NETWORKNAME,
                //                       });
                //                       setIsOpen(true);
                //                     }}
                //                   >
                //                     <svg
                //                       xmlns="http://www.w3.org/2000/svg"
                //                       width="20"
                //                       height="20"
                //                       viewBox="0 0 20 20"
                //                       fill="none"
                //                     >
                //                       <path
                //                         d="M13.5001 13.4993H13.5009M6.50008 6.49935H6.50086M13.5001 6.49935H13.5009M12.3334 4.16602H14.6667C15.3111 4.16602 15.8334 4.68835 15.8334 5.33268V7.66602C15.8334 8.31035 15.3111 8.83268 14.6667 8.83268H12.3334C11.6891 8.83268 11.1667 8.31035 11.1667 7.66602V5.33268C11.1667 4.68835 11.6891 4.16602 12.3334 4.16602ZM5.33341 11.166H7.66675C8.31108 11.166 8.83341 11.6883 8.83341 12.3327V14.666C8.83341 15.3103 8.31108 15.8327 7.66675 15.8327H5.33341C4.68908 15.8327 4.16675 15.3103 4.16675 14.666V12.3327C4.16675 11.6883 4.68908 11.166 5.33341 11.166ZM12.3334 11.166H14.6667C15.3111 11.166 15.8334 11.6883 15.8334 12.3327V14.666C15.8334 15.3103 15.3111 15.8327 14.6667 15.8327H12.3334C11.6891 15.8327 11.1667 15.3103 11.1667 14.666V12.3327C11.1667 11.6883 11.6891 11.166 12.3334 11.166ZM5.33341 4.16602H7.66675C8.31108 4.16602 8.83341 4.68835 8.83341 5.33268V7.66602C8.83341 8.31035 8.31108 8.83268 7.66675 8.83268H5.33341C4.68908 8.83268 4.16675 8.31035 4.16675 7.66602V5.33268C4.16675 4.68835 4.68908 4.16602 5.33341 4.16602Z"
                //                         stroke="var(--content-main-gray-light)"
                //                         strokeWidth="1.5"
                //                         strokeLinecap="round"
                //                         strokeLinejoin="round"
                //                       />
                //                     </svg>
                //                   </button>
                //                 </span>
                //               </div>

                //               <div className="flex gap-2">
                //                 <span className="text-xs text-[var(--content-main-gray-light)]">
                //                   {formatBalance(i[1].difference)}
                //                 </span>

                //                 <span
                //                   className={`text-xs ${i[1].percentage.split("-")[0] == "-"
                //                     ? "text-[var(--error-chip-cancelado-light)]"
                //                     : "text-[var(--success-chip-light)] "
                //                     }`}
                //                 >
                //                   {i[1].percentage}
                //                 </span>
                //               </div>
                //             </div>

                //             <div className="tex-end">
                //               <div className="text-end">
                //                 <p className="text-amount  text-[--content-main-gray-light] dark:text-[#FFFFFF] font-[600]">
                //                   {formatBalance(i[1].AMOUNT)}
                //                 </p>
                //                 <p className="text-convert  text-[--content-main-gray-light] dark:text-[#FFFFFF]">
                //                   {formatBalance(i[1].CONVERT)}
                //                 </p>
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </motion.div>
                //     ))}
                //   </>
                // </>
              }

              <>
                {dataAccount?.map((item, index) => (

                  item?.accounts?.map((item2, index2: number) => (

                    item2?.currencies?.map((item3, index3: number) => (
                    
                    <motion.div
                      key={index}
                      className={`${item3.state_view==1? '' : 'hidden'}  relative border-b-[1px] dark:border-b-[var(--strokes-separator-disabled-dark)] w-full min-h-[56px] px-4 hover:px-5 justify-start items-center gap-2 inline-flex
                       transition-all duration-150 cursor-pointer`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.9 }}
                    >
                      <div className="w-[36px]  h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
                        <img
                          src={item3?.img_url}
                          alt=""
                          className="w-full h-full object-contain"
                        />
                      </div>

                      <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex ">
                        <div className="w-full justify-between items-center inline-flex ">
                          <div className="max-w-[108px] flex flex-col ">
                            <div className="flex items-center flex-col mobile:flex-row  gap-2">
                              <span className=" text-start text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-medium ">
                              {item3?.type_money}
                              </span>
                              <span>
                                <CustomButton
                                  label={
                                    <span className="flex items-center lg:w-[200px] mobile:w-full gap-2 text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] ">
                                      <span className="lg:text-sm mobile:text-xs">/ {item2?.ACCOUNT_NAME}</span>
                                      <IconWallet />
                                    </span>
                                  }
                                  onclick={() => addDataCurrencie(item2)}
                                />
                              </span>
                            </div>

                            <div className="flex gap-2">
                              <span className="text-xs text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]">
                                {item3.differenceMoney}
                              </span>

                              <span
                                className={`text-xs ${item3.level== "HIGH" 
                                  ? "text-[var(--success-chip-light)]"
                                  : item3.level=="EQUAL"?"text-[var(--success-chip-light)]":"text-[var(--error-chip-cancelado-light)]"
                                  }`}
                              >
                                {item3.rateHistory}
                              </span>
                            </div>
                          </div>

                          <div className="tex-end">
                            <div className="text-end">
                              <p className="text-amount font-[600] text-[--content-main-black-light] dark:text-[#FFFFFF]">
                                {formatBalance(item3.amount)}
                              </p>
                              <p className="text-convert text-[--content-main-gray-light] dark:text-[#FFFFFF]">
                                {formatBalance(item3.rate)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr className="dark:bg-[var(--strokes-separator-disabled-dark)]" />
                      </div>
                    </motion.div>
                    ))
                  ))
                ))}

                <>
                  {Object?.entries(
                    objectBalanceCripto?.RateAmountHistoryCrypto
                      ?.RateAmountHistoryBalance
                  )?.map((i: any, _) => (
                    <motion.div
                      key={i[0]} // Usa i[0] como key si es único
                      className={` ${i[1].STATEVIEW == 1 ? "" : "hidden"
                        } relative dark:border-b-[var(--strokes-separator-disabled-dark)] border-b-[1px] w-full min-h-[56px] px-4 hover:px-5 justify-start items-center gap-2 inline-flex  ${parseInt(localStorage.getItem("id")) == 0 ||
                          parseInt(localStorage.getItem("id")) == 2
                          ? ""
                          : "hidden"
                        } transition-all duration-150 cursor-pointer`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.9 }}
                    >
                      <div className="w-[36px] h-[36px] rounded-[14px] justify-center items-center gap-2.5 flex">
                        <img
                          src={i[1].IMGURL}
                          alt=""
                          className="w-full h-full object-contain"
                        />
                      </div>

                      <div className="w-full min-h-[33px] flex-col justify-center items-center inline-flex">
                        <div className="w-full justify-between items-center inline-flex">
                          <div className="max-w-[108px]  flex flex-col ">
                            <div className="flex items-center justify-center gap-1">
                              <span className=" dark:text-[var(--content-main-black-dark)] text-start text-[var(--content-main-black-light)] text-base font-semibold">
                                {i[0]}
                              </span>

                              <span className="network">
                                {
                                  JSON.parse(JSON.parse(i[1].NETWORKS))[0]
                                    .NETWORKNAME
                                }
                              </span>

                              <span className="flex">
                                <button
                                  className="w-[20px] h-[20px]"
                                  disabled={true}
                                  onClick={() => {
                                    setDataNeworks({
                                      asset: i[0],
                                      nework: JSON.parse(
                                        JSON.parse(i[1].NETWORKS)
                                      )[0].NETWORKNAME,
                                    });
                                    setIsOpen(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.5001 13.4993H13.5009M6.50008 6.49935H6.50086M13.5001 6.49935H13.5009M12.3334 4.16602H14.6667C15.3111 4.16602 15.8334 4.68835 15.8334 5.33268V7.66602C15.8334 8.31035 15.3111 8.83268 14.6667 8.83268H12.3334C11.6891 8.83268 11.1667 8.31035 11.1667 7.66602V5.33268C11.1667 4.68835 11.6891 4.16602 12.3334 4.16602ZM5.33341 11.166H7.66675C8.31108 11.166 8.83341 11.6883 8.83341 12.3327V14.666C8.83341 15.3103 8.31108 15.8327 7.66675 15.8327H5.33341C4.68908 15.8327 4.16675 15.3103 4.16675 14.666V12.3327C4.16675 11.6883 4.68908 11.166 5.33341 11.166ZM12.3334 11.166H14.6667C15.3111 11.166 15.8334 11.6883 15.8334 12.3327V14.666C15.8334 15.3103 15.3111 15.8327 14.6667 15.8327H12.3334C11.6891 15.8327 11.1667 15.3103 11.1667 14.666V12.3327C11.1667 11.6883 11.6891 11.166 12.3334 11.166ZM5.33341 4.16602H7.66675C8.31108 4.16602 8.83341 4.68835 8.83341 5.33268V7.66602C8.83341 8.31035 8.31108 8.83268 7.66675 8.83268H5.33341C4.68908 8.83268 4.16675 8.31035 4.16675 7.66602V5.33268C4.16675 4.68835 4.68908 4.16602 5.33341 4.16602Z"
                                      stroke="var(--content-main-gray-light)"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </span>
                            </div>

                            <div className="flex gap-2">
                              <span className="text-xs text-[var(--content-main-gray-light)]">
                                {formatBalance(i[1].difference)}
                              </span>

                              <span
                                className={`text-xs ${i[1].percentage.split("-")[0] == "-"
                                  ? "text-[var(--error-chip-cancelado-light)]"
                                  : "text-[var(--success-chip-light)] "
                                  }`}
                              >
                                {i[1].percentage}
                              </span>
                            </div>
                          </div>

                          <div className="tex-end">
                            <div className="text-end">
                              <p className="text-amount  text-[--content-main-gray-light] dark:text-[#FFFFFF] font-[600]">
                                {formatBalance(i[1].AMOUNT)}
                              </p>
                              <p className="text-convert  text-[--content-main-gray-light] dark:text-[#FFFFFF]">
                                {formatBalance(i[1].CONVERT)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </>
              </>

              {/* ================0 */}
            </>
          )}
          {/* {filteredArray?.length > 0 && allZeroAndWallet && ( */}

          {counter <= 1 && counter >= 12 && objectBalanceCripto == null && (
            <div className="w-full h-14 pl-2 pr-4 py-2 justify-between items-center inline-flex">
              <div className="w-[296px] h-9 justify-start items-center gap-2 inline-flex">
                <div className="w-9 h-9 rounded-[580px] border border-[var(--background-pill-selected-light)] justify-center items-center gap-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.75977 16.2398L16.2398 7.75977M7.75977 16.2398H12.8498M7.75977 16.2398V11.1498"
                      stroke="var(--content-inner-inner-black-dark)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="w-[228px] text-[var(--content-main-gray-light)] text-sm font-normal leading-[16px]">
                  {t("Vank.Home.Balances.Header.Text1")}
                </div>
              </div>
              <div className="w-[154px] h-9 pl-6 pr-4 py-1.5 bg-[var(--branding-brand-yellow-light)] rounded-[999px] justify-center items-center gap-2 inline-flex">
                <span className="w-[61px] text-center text-[var(--content-main-gray-light)] text-sm font-semibold">
                  {t("Vank.Home.Balances.Header.Recharge")}
                </span>
                <div className="justify-center items-center gap-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                  >
                    <path
                      d="M5.67285 10.8272L11.3262 5.17383M5.67285 10.8272H9.06619M5.67285 10.8272V7.43383"
                      stroke="var(--content-main-black-light)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
          {/* mensaje onboarding 5 mobile*/}
          {counter === 4 && (
            <div
              ref={step5Ref}
              className="2xl:hidden xl:hidden lg:flex md:flex sm:flex mobile:flex absolute  mobile-375:w-[17rem] mobile:w-[13rem] rounded-[16px] mobile-375:-top-[5.5rem] mobile:-top-[6.5rem] left-0 right-0 mx-auto flex justify-start items-start bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] pt-[12px] px-[12px] pb-[18px] z-50"
            >
              <div className="absolute left-0 right-0 mx-auto mobile-375:top-[4rem] mobile:top-[5rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                <span className="text-[14px] text-[#000] font-normal">
                  {counter}
                </span>
              </div>
              <p className="text-[14px] text-center text-[var(--content-main-black-light)]  dark:text-[var(--content-main-black-dark)] font-medium leading-[16.94px]">
                {t("Vank.Home.Onboarding.Five")}
              </p>
            </div>
          )}
        </div>
        {counter === 4 && (
          <div
            ref={step5RefMobile}
            className="2xl:block xl:block lg:hidden md:hidden sm:hidden mobile:hidden  absolute min-w-[373px] min-h-[76px] rounded-[16px] top-0 left-[102%] flex justify-start items-start bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] py-4 pl-10 pr-4 z-50"
          >
            <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
              <span className="text-[14px] text-[#000] font-normal">
                {counter}
              </span>
            </div>
            <p className="text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] font-normal leading-[21.78px]">
              {t("Vank.Home.Onboarding.Five")}
            </p>
          </div>
        )}
      </div>

      {dataNeworks != null && (
        <CustomModal isOpen={isOpen}>
          <ReceiveQR dataCripto={dataNeworks} setIsOpen={setIsOpen} />
        </CustomModal>
      )}

      {/* {visibleModalCurrencie && ( */}
      <CustomModal isOpen={visibleModalCurrencie}>
        <ModalInfoCurrencie
          onclickHeader={() => setVisibleModalCurrencie(false)}
          data={dataCurrencie}
        />
      </CustomModal>
      {/* )} */}

      <CustomModal isOpen={assetVisibles}>
        <BalanceAdjustment
          updateStatuses={() => {
            setAssetVisibles(false);
            updateStatuses();

          }}
          onClickAction={() => actionAccount()}
          onClickClose={() => setAssetVisibles(false)}
          dataAssetCurrencies={objectBalance}
          dataAssetCriptos={objectBalanceCripto}
          stateData={stateData}

        />
      </CustomModal>
    </>
  );
};

export default Balance;
