import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";
import { HttpResponseRecipeintFiat } from "../../../Recipient/models/ModelRecipientFiat";

export default class ServiceFiat extends AxiosClientRepository {
  public async saveAddressee(data: any) {
    try {
      const url = `${environment.url_servers.url_fiat}/fiatServices/secure/v1/postRecepientsAcccount`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }
  public async getAddressee(query:string):Promise<HttpResponseRecipeintFiat> {
    try {
      const url = `${environment.url_servers.url_fiat}/fiatServices/secure/v1/getRecepientsAcccount`+query;
     
      
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }
  public async putAddressee(idUser: string, vankId: string, data: any) {
    try {
      const url = `${environment.url_servers.url_fiat}/fiatServices/secure/v1/putRecepientsAcccount/${idUser}/${vankId}`;
      let response = await this.put({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async sendDataFormFiat(data: any) {
    try {
      const url = `${environment.url_servers.url_fiat}/fiatServices/secure/v1/sendPaymentsUsers/`;
      let response = await this.post({ url, data });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public async getDataTransactionSendFormFiat(LIMIT: number){
    try {
      const url = `${environment.url_servers.url_fiat}/fiatServices/secure/v1/dataPaymentsUsers/Resource?`+`&$LIMIT=${LIMIT}`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
