import CustomModal from "@/apps/Shared/CustomModal/CustomModal"
import { HeaderModal } from "../../../Home/Modales/HeaderModal/HeaderModal"
import CustomInput from "@/apps/Shared/CustomInput/CustomInput"
import CustomSelect from "../../../Transactions/Fiat/layout/SendFiat/components/FormSendFiat/Components/ModalNewAddressee/CustomSelect/CustomSelect"
import { useEffect, useState } from "react"
import CustomButton from "@/apps/Shared/CustomButton/CustomButton"
import CustomLoading from "@/apps/Shared/CustomLoading/CustomLoading"
import { ArrowHide } from "@/assets/Icon/ArrowHide"
import { useTranslation } from "react-i18next"
import { IconArrowRecharge, IconConvertAccunt, IconUsd } from "@/assets/IconV2/IconsSvg"
import { useTheme } from "@/Context/UseContext/ThemeContext";



const SidebarAccount = ({ isOpenSend, onClickSidebarAccount }: { isOpenSend: boolean, onClickSidebarAccount?: () => void }) => {
    const [accountName, setAccountName] = useState("");
    const [typeMoney, setTypeMoney] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [t, i18n] = useTranslation("global");
    const [isContinueEnabled, setIsContinueEnabled] = useState(false); // Estado del botón "Continuar"
    const { theme, toggleDarkMode } = useTheme(); // Usa el contexto
    const maxLength = 20;
    const [selectedMoney, setSelectedMoney] = useState<any>(null);

    useEffect(() => {

        const document = [
            {
                label: "COP (Pesos)",
                img: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 11.8214 19.513 13.5291 18.6622 15L1.33782 15C0.486972 13.5291 0 11.8214 0 10H20Z" fill="#236EFF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.33789 15H18.6622C16.9332 17.989 13.7015 20 10.0001 20C6.29866 20 3.06694 17.989 1.33789 15Z" fill="#F76A6A" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M20 10H0C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#FFED00" />
                </svg>
            },
            {
                label: "USD (Dólares)",
                img: <IconUsd
                    className="h-[20px] w-[20px]" />
            }
        ]
        let dataDocument = []

        document.map((item: any, index: number) => {
            dataDocument.push({ value: item.label, label: <span className="flex gap-2">{item.img} {item.label}</span> })
        })

        setTypeMoney(dataDocument)
        // const option = ["COP", "USDE"];
        // setTypeMoney(option)
    }, [])

    const visiblePass = () => {
        setIsLoading(true)
        setTimeout(() => {
            setVisible(true)
            setIsLoading(false)
        }, 4000)
    }

    // Lógica para habilitar el botón "Continuar"
    useEffect(() => {
        setIsContinueEnabled(Boolean(accountName.trim())); // Habilitar solo si el campo tiene texto
    }, [accountName]);

    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); // Prevenir el comportamiento predeterminado del clic
        e.stopPropagation(); // Prevenir la propagación del evento
    
        // Limpiar los campos necesarios
        setAccountName("");
        setSelectedMoney(null);
    };

    // Manejar el flujo al hacer clic en "Continuar"
    const handleContinue = () => {
        if (isContinueEnabled) {
            visiblePass(); // Llamar a la función visiblePass
        }
    };


    return (

        // <CustomModal isOpen={isOpenSend}>
        <div
            className={`absolute top-0 right-0 2xl:w-[593px] lg:w-[593px] mobile:w-[293px] min-h-screen flex flex-col   items-center 2xl:pt-[48px] lg:pt-[48px] mobile:pt-[18px] pb-[40px] bg-[--color-lightest-white] dark:bg-[#2D2E2F] 2xl:flex xl:flex lg:flex md:flex tablet:flex mobile:block shadow z-40  ${isOpenSend ? "translate-x-0" : "translate-x-full"
                } transition-all duration-700 dark:bg-[#2D2E2F]`}
                style={{
                    overflowY: "auto", // Habilita el scroll general
                    maxHeight: "calc(100vh - 80px)", // Limita la altura máxima del componente
                    scrollBehavior: "smooth", // Agrega desplazamiento suave
                  }}
        >

            <div className="gap-2 w-full flex relative items-center 2xl:py-6 lg:py-6 mobile:py-1 px-4  border-b border-[#F1F1F1] dark:border-[--strokes-separator-disabled-dark] " >


                <span className="lg:pb-0 mobile:pb-2">
                    <svg className="dark:text-[--content-main-black-dark]" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="18" fill={theme === "dark" ? "#212222" : "#F5F5F5"} />
                        <path d="M23 15H20M24.1 11H11.9C10.2984 11 9 12.2984 9 13.9V22.1C9 23.7016 10.2984 25 11.9 25H24.1C25.7016 25 27 23.7016 27 22.1V13.9C27 12.2984 25.7016 11 24.1 11ZM12.75 15.75H9V20.25H12.75C13.3467 20.25 13.919 20.0129 14.341 19.591C14.7629 19.169 15 18.5967 15 18C15 17.4033 14.7629 16.831 14.341 16.409C13.919 15.9871 13.3467 15.75 12.75 15.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <g clipPath="url(#clip0_2490_111099)">
                            <rect x="18" y="7" width="13" height="13" rx="6.5" fill={theme === "dark" ? "#212222" : "#F5F5F5"} />
                            <path d="M21.7188 13.4996H27.2824M24.5006 10.7178V16.2814" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <rect x="18.7" y="7.7" width="11.6" height="11.6" rx="5.8" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" />
                        <defs>
                            <clipPath id="clip0_2490_111099">
                                <rect x="18" y="7" width="13" height="13" rx="6.5" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>


                </span>


                <HeaderModal classNameTexts="lg:pt-0 mobile:pt-1" headerHeight="" headerStyle="w-full " text={t("SidebarAccount.title")} onClick={onClickSidebarAccount} />

            </div>

            <div className="2xl:py-6 lg:py-6 mobile:py-2 flex mobile:flex justify-center">
                {visible ?
                    <svg width="47" height="32" viewBox="0 0 47 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="8.5" width="15" height="15" rx="7.5" stroke="#FFF133" />
                        <path className="fill-[#2D2E2F] dark:fill-[#FFF]" d="M9.09553 12.7273V20H7.99467V13.8281H7.95206L6.212 14.9645V13.9134L8.02663 12.7273H9.09553Z" fill="" />
                        <line x1="16" y1="15.5" x2="31" y2="15.5" stroke="#FFF133" />
                        <rect x="31" y="8" width="16" height="16" rx="8" fill="#FFF133" />
                        <path d="M36.6332 20V19.2045L39.0941 16.6548C39.3569 16.3778 39.5735 16.1352 39.744 15.9268C39.9168 15.7161 40.0458 15.5161 40.131 15.3267C40.2163 15.1373 40.2589 14.9361 40.2589 14.723C40.2589 14.4815 40.2021 14.2732 40.0884 14.098C39.9748 13.9205 39.8197 13.7843 39.6232 13.6896C39.4267 13.5926 39.2054 13.544 38.9592 13.544C38.6987 13.544 38.4715 13.5973 38.2773 13.7038C38.0832 13.8104 37.9341 13.9607 37.8299 14.1548C37.7257 14.349 37.6737 14.5762 37.6737 14.8366H36.6261C36.6261 14.3939 36.7279 14.0069 36.9315 13.6754C37.1351 13.344 37.4144 13.0871 37.7695 12.9048C38.1246 12.7202 38.5283 12.6278 38.9805 12.6278C39.4374 12.6278 39.8398 12.719 40.1879 12.9013C40.5382 13.0812 40.8117 13.3274 41.0082 13.6399C41.2047 13.95 41.3029 14.3004 41.3029 14.6911C41.3029 14.9609 41.252 15.2249 41.1502 15.483C41.0508 15.741 40.8768 16.0286 40.6282 16.3459C40.3796 16.6607 40.034 17.0431 39.5913 17.4929L38.146 19.0057V19.0589H41.4201V20H36.6332Z" fill="#2D2E2F" />
                    </svg>
                    :
                    <span>
                        <svg width="47" height="32" viewBox="0 0 47 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="8" width="16" height="16" rx="8" fill="#FFF133" />
                            <path d="M9.09553 12.7273V20H7.99467V13.8281H7.95206L6.212 14.9645V13.9134L8.02663 12.7273H9.09553Z" fill="#2D2E2F" />
                            <line x1="16" y1="15.5" x2="31" y2="15.5" stroke="#818282" />
                            <rect x="31.5" y="8.5" width="15" height="15" rx="7.5" stroke="#818282" />
                            <path d="M36.6332 20V19.2045L39.0941 16.6548C39.3569 16.3778 39.5735 16.1352 39.744 15.9268C39.9168 15.7161 40.0458 15.5161 40.131 15.3267C40.2163 15.1373 40.2589 14.9361 40.2589 14.723C40.2589 14.4815 40.2021 14.2732 40.0884 14.098C39.9748 13.9205 39.8197 13.7843 39.6232 13.6896C39.4267 13.5926 39.2054 13.544 38.9592 13.544C38.6987 13.544 38.4715 13.5973 38.2773 13.7038C38.0832 13.8104 37.9341 13.9607 37.8299 14.1548C37.7257 14.349 37.6737 14.5762 37.6737 14.8366H36.6261C36.6261 14.3939 36.7279 14.0069 36.9315 13.6754C37.1351 13.344 37.4144 13.0871 37.7695 12.9048C38.1246 12.7202 38.5283 12.6278 38.9805 12.6278C39.4374 12.6278 39.8398 12.719 40.1879 12.9013C40.5382 13.0812 40.8117 13.3274 41.0082 13.6399C41.2047 13.95 41.3029 14.3004 41.3029 14.6911C41.3029 14.9609 41.252 15.2249 41.1502 15.483C41.0508 15.741 40.8768 16.0286 40.6282 16.3459C40.3796 16.6607 40.034 17.0431 39.5913 17.4929L38.146 19.0057V19.0589H41.4201V20H36.6332Z" fill="#818282" />
                        </svg>

                    </span>
                }

            </div>

            {!visible ?
                <>
                    <div className="flex flex-col 2xl:gap-8 lg:gap-8 mobile:gap-4">
                        {/* <div className="border dark:border-[--strokes-separator-disabled-dark]"></div> */}
                        <div className="w-full px-4 ">
                            <div className=" flex gap-2 2xl:w-[561px] lg:w-[561px]  bg-[#FFF3DB] rounded-xl py-3 px-4 h-16 items-center text-sm text-[--content-main-black-light] ">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 7.5V13.8M12.001 16.5H12.0001M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#2D2E2F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                                <p>{t("SidebarAccount.info")}</p>
                            </div>
                        </div>

                        <div className="w-full px-4 ">

                            <form className="flex flex-col gap-[24px]"  >

                                <div className="flex flex-col 2xl:gap-6 lg:gap-6 mobile:gap-2 dark:text-[#FFF]">

                                    <div className="flex justify-between 2xl:gap-4 lg:gap-4 mobile:gap-2 ">

                                        <div className="w-[40%]">
                                            <div className="flex flex-col gap-2 text-base mobile:text-sm font-medium text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]">
                                                <label htmlFor="">{t("SidebarAccount.labelMoney")}</label>
                                                <div className="">
                                                    <CustomSelect 
                                                        placeholder={t("SidebarAccount.placeholder.typeMoney")} 
                                                        options={typeMoney}
                                                        value={selectedMoney} // Vincular el valor al estado
                                                        onChange={(value) => setSelectedMoney(value)} 
                                                         />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-[60%] ">    
                                            <div className="flex flex-col gap-2 text-base mobile:text-sm font-medium text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]">

                                                <label htmlFor="">{t("SidebarAccount.labelTitleAccount")}</label>
                                                <div className="w-full">
                                                    <CustomInput
                                                        value={accountName}
                                                        maxLength={maxLength}
                                                        className="mobile:w-full lg:w-[327px] 2xl:w-[327px] focus:outline-none bg-[--background-disabled-input] 2xl:h-[48px] lg:h-[48px] mobile:h-[2.5rem] rounded-xl px-4 placeholder:text-[#818282] dark:bg-[#212222]"
                                                        placeholder={t("SidebarAccount.placeholder.nameAccount")}
                                                        onChange={(e) => setAccountName(e.target.value)} // Maneja los cambios 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full  flex flex-col 2xl:gap-2 lg:gap-2 mobile:gap-1 font-medium text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]">
                                        <label htmlFor="">{t("SidebarAccount.accountDetails")}</label>
                                        <div>
                                            <textarea placeholder={t("SidebarAccount.placeholder.details")} className="focus:outline-none py-[14.5px] px-[16px] w-full 2xl:h-[88px] lg:h-[88px] mobile:h-[70px] rounded-xl bg-[#F8F9F9] dark:bg-[#212222] placeholder:text-[--content-main-gray-light] text-[--content-main-black-light] placeholder:2xl:text-base placeholder:lg:text-base placeholder:mobile:text-xs" name="" id=""></textarea>
                                        </div>

                                    </div>

                                </div>

                                <div className="border 2xl:text-sm lg:text-sm mobile:text-xs dark:border-[--strokes-separator-disabled-dark] border-[#EAEAEA] h-[243px] rounded-xl p-4 flex flex-col gap-[31px] mobile:gap-2">

                                    <div className="flex items-center gap-2 mobile:gap-1">
                                        <span><svg className="stroke-[#2D2E2F] dark:stroke-[#FFF]" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path d="M9 12.12L11.19 14.31L16 9.5" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M13 21.0204L12 21.5904L11 21.0204C8.8709 19.7912 7.10304 18.023 5.87423 15.8936C4.64543 13.7643 3.99901 11.3489 4 8.89042C4.00036 8.53969 4.09294 8.19523 4.26846 7.89158C4.44398 7.58793 4.69626 7.33578 5 7.16041L11.08 3.65041C11.3594 3.48794 11.6768 3.40234 12 3.40234C12.3232 3.40234 12.6406 3.48794 12.92 3.65041L19 7.16041C19.3037 7.33578 19.556 7.58793 19.7315 7.89158C19.9071 8.19523 19.9996 8.53969 20 8.89042C20.001 11.3489 19.3546 13.7643 18.1258 15.8936C16.897 18.023 15.1291 19.7912 13 21.0204Z" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg></span>
                                        <h4 className="2xl:text-sm lg:text-sm  text-[#2D2E2F] font-medium dark:text-[#FFF]">{t("SidebarAccount.list.title")}</h4>
                                    </div>

                                    <ul className="list-disc px-3 list-inside text-[--content-main-black-light] 2xl:text-sm lg:text-sm mobile:text-xs font-normal h-full flex flex-col justify-between dark:text-[#FFF]">
                                        <li>{t("SidebarAccount.list.item1")}</li>
                                        <li>{t("SidebarAccount.list.item2")}</li>
                                        <li>{t("SidebarAccount.list.item3")}</li>
                                        <li>{t("SidebarAccount.list.item4")}</li>
                                        <li>{t("SidebarAccount.list.item5")}</li>
                                    </ul>


                                </div>

                                {/* import CustomLoading from "@/apps/Shared/CustomLoading/CustomLoading"; */}

                                {!isLoading ?

                                    <div className="w-full flex gap-4 justify-center items-center text-center">
                                        <CustomButton onclick={handleClear} className="2xl:w-[273px] lg:w-[273px] mobile:w-[8rem]  2xl:text-lg lg:text-lg mobile:text-sm  2xl:h-[48px] lg:h-[48px] mobile:h-[28px] font-medium border border-[#2D2E2F] text-[--content-main-black-light] dark:border-[#FFF] dark:text-[#FFF] rounded-full  px-[36px] items-center text-center" label={t("SidebarAccount.Btns.clear")} />
                                        <CustomButton onclick={handleContinue} className={`2xl:w-[273px] lg:w-[273px] mobile:w-[8rem]  h-[48px] text-lg 2xl:text-lg lg:text-lg mobile:text-sm  rounded-full 2xl:h-[48px] lg:h-[48px] mobile:h-[28px] ${isContinueEnabled
                                                ? "bg-[--branding-brand-yellow-light] text-[#2D2E2F]"
                                                : "bg-[#EAEAEA] text-[--content-main-gray-light]"
                                            }`} label={t("SidebarAccount.Btns.continue")} />
                                    </div>
                                    :
                                    <div className="flex justify-center">
                                        <CustomLoading />
                                    </div>
                                }

                            </form>



                        </div>
                    </div>
                </>
                :
                <>
                    <div className="py-6 w-full flex flex-col gap-[48px] border-t  border-t-[#F1F1F1] dark:border-t-[var(--strokes-separator-disabled-dark)]">

                        <div className="flex flex-col items-center gap-4">
                            <span>
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="50" height="50" rx="25" fill="#6AD67B" />
                                    <path d="M15 26.871L17.909 31L35 19" stroke="#1F5928" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </span>
                            <div>
                                <h4 className="dark:text-[#FFF] text-[--content-main-black-light] lg:text-lg mobile:text-sm font-semibold">{t("SidebarAccount.createAccount.title")} COP!</h4>
                            </div>

                            <div className="bg-[#F8F9F9] lg:text-sm mobile:text-xs px-4 h-[48px] flex gap-2 rounded-xl items-center justify-center dark:bg-[#212222] dark:text-[#FFF] font-medium border dark:border-[#818282]" >
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 11.8214 19.513 13.5291 18.6622 15L1.33782 15C0.486972 13.5291 0 11.8214 0 10H20Z" fill="#236EFF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.33789 15H18.6622C16.9332 17.989 13.7015 20 10.0001 20C6.29866 20 3.06694 17.989 1.33789 15Z" fill="#F76A6A" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 10H0C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" fill="#FFED00" />
                                    </svg>
                                </span>
                                <p className=""> Ahorros colombia 2</p>
                            </div>
                        </div>

                        <div className="w-full flex flex-col items-center gap-6">

                            <div>
                                <h2 className="w-[300px] text-center lg:text-lg mobile:text-sm font-semibold dark:text-[var(--content-main-black-dark)]">{t("SidebarAccount.createAccount.message")}</h2>
                            </div>

                            <div className="flex flex-col gap-4">

                                <div
                                    className=" border border-[#EAEAEA]  2xl:w-full xl:w-full lg:w-[162px] tablet:w-[162px] mobile:w-[260px]  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[#212222] text-[var(--content-inner-inner-black-light)]  rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
                                >
                                    <div className="flex gap-3 group-hover:gap-2 ">
                                        <div className=" group-hover:h-[69px] group-hover:scale-[0.95]   transition-all ease-in-out duration-300">

                                           <IconArrowRecharge
                                           className="lg:h-[44px] lg:w-[44px] mobile:h-[34px] mobile:w-[34px]"/>

                                        </div>

                                        <div className="flex flex-col items-start">
                                            <div className="text-center 2xl:text-lg lg:text-lg mobile:text-sm font-semibold dark:text-[#000] ">
                                                {t("SidebarAccount.createAccount.Reload.title")}
                                            </div>
                                            <div className="text-sm text-[#818282] dark:text-[#C0C0C1] font-medium">
                                                {t("SidebarAccount.createAccount.Reload.message")}
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <ArrowHide className="-rotate-90 w-full h-full " />
                                    </div>

                                </div>

                                <div
                                    className=" border border-[#EAEAEA]  2xl:w-full xl:w-full lg:w-[162px] tablet:w-[162px] mobile:w-[260px]  w-full text-center h-[80px] py-4 pl-4 pr-3  bg-[#FBFCFC] dark:bg-[#212222] text-[var(--content-inner-inner-black-light)]  rounded-2xl flex items-center justify-between gap-3  cursor-pointer"
                                >
                                    <div className="flex gap-3 group-hover:gap-2 f">

                                        <div className=" group-hover:h-[69px] group-hover:scale-[0.95]   transition-all ease-in-out duration-300">

                                          <IconConvertAccunt
                                          className="lg:h-[44px] lg:w-[44px] mobile:h-[34px] mobile:w-[34px]"/>



                                        </div>

                                        <div className="flex flex-col items-start">

                                            <div className="text-center 2xl:text-lg lg:text-lg mobile:text-sm font-semibold dark:text-[#000]">
                                                {t("SidebarAccount.createAccount.Convert.title")}
                                            </div>

                                            <div className="text-sm text-[#818282] dark:text-[#C0C0C1] font-medium">
                                                {t("SidebarAccount.createAccount.Convert.message")}
                                            </div>
                                        </div>

                                    </div>

                                    <div>
                                        <ArrowHide className="-rotate-90 w-full h-full " />
                                    </div>

                                </div>

                            </div>




                        </div>


                    </div>

                </>
            }


        </div>
        // </CustomModal>

    )
}

export default SidebarAccount