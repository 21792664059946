import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../../Shared/layout/Sildebar/Sidebar";
import { Suspense, useEffect, useState } from "react";
import RootVankSkeleton from "./RootVankSkeleton";
import SendModal from "./SendModal/SendModal";
import ReceiveModal from "./ReceiveModal/ReceiveModal";
import SidebarNotifications from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarNotifications/SidebarNotifications";
import SidebarProfile from "@/apps/Shared/layout/Header/UserProfileAndNotifications/SidebarProfile/SidebarProfile";
import { CacheDropDowns } from "@/apps/Shared/infrastructura/Persistence/CacheDropDowns";
import CustomModalErrorHttp from "@/apps/Shared/CustomModalErrorHttp/CustomModalErrorHttp";
import "./RootVank.css";
import { useTranslation } from "react-i18next";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { jwtDecode } from "jwt-decode";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import ModalInactAndExpi from "./ModalInactAndExpi/ModalInactAndExpi";
import SidebarAccount from "@/apps/Vank/Page/Account/components/SidebarAccount/SidebarAccount";
import { SocketProvider } from "@/apps/Context/WebSocketContext";
import { environment } from "@/environments/environment.dev";

export const formatTime = (milliseconds) => {
  const totalSeconds = Math.max(Math.floor(milliseconds / 1000), 0);
  const hours = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes after hours
  const seconds = totalSeconds % 60; // Remaining seconds
  // Formatear el tiempo dependiendo de las horas, minutos y segundos disponibles
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};
export const RootVank = ({ setProfileModalOpen }: { setProfileModalOpen?: any }) => {
  const cacheDropdowns = new CacheDropDowns();
  const [isOpenSend, setIsOpenSend] = useState(false);
  const [isOpenReload, setIsOpenReload] = useState(false);
  const location = useLocation();
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto
  //Estado para la función de traducción
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    status: "",
  });
  useEffect(() => {
    setIsLoading(true);
    cacheDropdowns
      .getUser()
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsOpen(true);
        if (e?.data?.statusCode === 500) {
          setErrorMessage((prevState: any) => ({
            ...prevState,
            message: t("RootVank.RootVank.text3"),
            status: "500",
          }));
          return;
        }
        if (e?.message === "session-expired") {
          setErrorMessage((prevState: any) => ({
            ...prevState,
            message: t("RootVank.RootVank.text1"),
            status: "401",
          }));
          return;
        }
        if (e === "not-token") {
          setErrorMessage((prevState: any) => ({
            ...prevState,
            message: t("RootVank.RootVank.text1"),
            status: "401",
          }));
          return;
        }
        setErrorMessage((prevState: any) => ({
          ...prevState,
          message: t("RootVank.RootVank.text2"),
          status: "401",
        }));
      });
    // document.addEventListener("session-expired", () => setOpenDrop(true));
    document.addEventListener("SendModal", () => setIsOpenSend(true));
    document.addEventListener("ReceiveModal", () => setIsOpenReload(true));
  }, []);
  const socketConfigs = [
    { id: "orchestrator", url:`${environment.url_socket.url_vank_orchestractor}/RequestVankOrchestratorServices`, visible:true},
    { id: "operations",url:`${environment.url_socket.url_operations}/RequestOperationsServices`, visible: true },
  ];
  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen bg-[--color-lighter-white] dark:bg-[#1A1A1B] flex-col justify-center items-center inline-flex overflow-hidden">
          <svg
            className="loader"
            viewBox="0 0 384 384"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
        </div>
      ) : (
        <Suspense fallback={<RootVankSkeleton />}>
          <SocketProvider socketConfigs={socketConfigs}>
            <div className="relative w-full bg-[--color-lighter-white] dark:bg-[var(--background-layaout-dark)] justify-start items-center inline-flex overflow-hidden">
              <Sidebar
                setIsOpenSend={setIsOpenSend}
                setIsOpenReload={setIsOpenReload}
              />
              <div className="relative w-full h-auto flex justify-center items-center 2xl:overflow-auto xl:overflow-auto lg:overflow-auto tablet:overflow-auto mobile:overflow-hidden">
                <main className="relative w-full md:max-w-[1308px] mobile:w-full 2xl:h-screen xl:h-screen lg:h-screen tablet:h-screen mobile:h-auto 2xl:overflow-auto xl:overflow-auto lg:overflow-auto tablet:overflow-auto mobile:overflow-hidden">
                  <Outlet />
                </main>
              </div>
              <SidebarNotifications />
              <SidebarProfile setProfileModalOpen={setProfileModalOpen} />
              <SendModal
                isOpenSend={isOpenSend}
                setIsOpenSend={setIsOpenSend}
              />
              <ReceiveModal
                isOpenReload={isOpenReload}
                setIsOpenReload={setIsOpenReload}
              />
            </div>
            <CustomModalErrorHttp
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              message={errorMessage}
            />
          </SocketProvider>
        </Suspense>
      )}
    </>
  );
};